import React, { useRef, useState } from 'react';

interface IGrabberProps {
    minPercentage: number;
    maxPercentage: number;
    position: number;
    setPosition: (position: number) => void;
}



export const Grabber: React.FunctionComponent<IGrabberProps> = ({ minPercentage, maxPercentage, position, setPosition }) => {
    const draggerRef = useRef<HTMLDivElement>(null);

    const [isDragging, setIsDragging] = useState(false);

    const startDragging: React.PointerEventHandler<HTMLDivElement> = (e) => {
        setIsDragging(true);
        draggerRef.current?.setPointerCapture(e.pointerId);
    }

    const onMouseMove: React.PointerEventHandler<HTMLDivElement> = (e) => {
        if (isDragging) {
            setPosition(e.clientX);
        }
    }

    const endDragging: React.PointerEventHandler<HTMLDivElement> = (e) => {
        if (isDragging) {
            setIsDragging(false);
            draggerRef.current?.releasePointerCapture(e.pointerId);
        }
    }

    return <div ref={draggerRef} onPointerDown={startDragging} onPointerUp={endDragging} onPointerMove={onMouseMove} className="absolute cursor-col-resize z-10 pointer-events-auto w-[6px] top-[72px] bottom-[40px] bg-hvpd-malibu-800 hover:bg-hvpd-malibu-900" style={{ left: position }}></div>
}