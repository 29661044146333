import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import {
    addToast,
} from "../../../store/mainSlice";

import { ReportGroupContext } from '../../ReportGroup/ReportGroupContext';
import { Busy } from '../../Busy';
import { ImagePicker } from '../ImagePicker';
import { ImageUpload } from '../../Images/ImageUpload';
import { addObservation } from '../../../api';
import { TEntityType, IObservation, TSeverity, TResultObservations } from "../../../store/Interfaces";

interface ICreateModalProps {
    onCancel: () => void;
    onCreate: (value: IObservation) => void;
    parentUuid: string;
    assetType: keyof TResultObservations;
}

export const CreateModal: React.FunctionComponent<ICreateModalProps> = ({ onCancel, onCreate, parentUuid, assetType }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { imageListAsset, imageBlobs, refreshImageList } = useContext(ReportGroupContext);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const editorRef = useRef<Editor>(null);
    const [busy, setBusy] = useState(false);
    const [editorBlink, setEditorBlink] = useState(false);

    const { token, clipBoardObservation } = useSelector(state => state.main);

    useEffect(() => {
        editorRef.current?.getInstance().focus();
    }, []);

    const [observation, setObservation] = useState<
        { observationName: string; severity: TSeverity, stringValue: string }>({ observationName: '', severity: 'Low', stringValue: ' ' });

    const entityTypeToAssetType: Record<keyof TResultObservations, TEntityType> = {
        'Result': 'Asset',
        'Asset': 'Asset',
        'Company': 'Company',
        'Site': 'Site',
        'Subsite': 'SubSite',
    }

    const save = async () => {
        setBusy(true);
        try {
            const newObservation = await addObservation(token, { ...observation, parentUuid, isValid: true, booleanValue: false, doubleValue: 0, longValue: 0, observationDate: Math.floor(new Date().getTime() / 1000) } as unknown as IObservation, assetType);
            dispatch(addToast(`${t('Added observation')} ${newObservation.observationName}`));
            setBusy(false);
            onCreate(newObservation);
        } catch (e) {
            console.error(e);
            setBusy(false);
            onCancel();
        }
    }

    const onClickImage = (base64: string, metadata: string) => {
        const markdown = editorRef.current?.getInstance().getMarkdown();
        editorRef.current?.getInstance().setMarkdown(`${markdown} ![${metadata}](${base64})`);
    }

    const imageUploaded = () => {
        refreshImageList();
        dispatch(addToast(t('Image upload successful')));
    }

    const getCreateString = (assetType: keyof TResultObservations) => {
        switch (assetType) {
            case 'Asset': return t('Create asset observation');
            case 'Company': return t('Create company observation');
            case 'Site': return t('Create site observation');
            case 'Subsite': return t('Create subsite observation');
            default: return t('Create observation');
        }
    }
    const copyFromClipBoard = () => {
        if (clipBoardObservation?.observation) {
            setObservation({ observationName: clipBoardObservation.observation.observationName, severity: clipBoardObservation.observation.severity ?? 'Medium', stringValue: clipBoardObservation.observation.stringValue });
            setEditorBlink(true);
            setTimeout(() => setEditorBlink(false), 1);
        }
    }


    const observationIsIncomplete = observation.observationName === '' || observation.stringValue === '';
    const hasImages = imageListAsset.images.some(image => (imageBlobs[image.name] ?? '').length > 0);

    return (<><div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-5xl">
                    <div className="grid grid-cols-2 justify-items-stretch rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5 className="text-xl justify-self-start font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                            {getCreateString(assetType)}
                        </h5>
                        <div className='flex flex-row-reverse'>
                            <button type="button" onClick={onCancel} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label={t('Close')}>
                                <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                            </button>
                            {clipBoardObservation && clipBoardObservation.type === assetType ?
                                <button onClick={copyFromClipBoard} title={t('Use observation')} className='rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 p-2 mr-1 border-solid border-1 text-sm' >
                                    {t('Observation')} <FontAwesomeIcon className=' text-hvpd-malibu-300' icon={solid('clipboard-list')} />
                                </button>
                                : null}
                        </div>
                    </div>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">

                        {busy ? <div className='mb-20 mx-[12rem]'><Busy /></div> :
                            <div className={`grid ${hasImages ? 'grid-cols-[580px_380px]' : 'grid-cols-1'}`}>
                                <div>
                                    <div className='grid grid-cols-2 mb-3'>
                                        <div className='inline-flex'>
                                            <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="observationName">{t('Name')}</label>
                                            <input type="text" id='observationName' name='observationName' className="w-40 p-1 text-sm rounded-sm border-hvpd-grey-700 focus:hvpd-grey-500 active:hvpd-grey-500 border inline" value={observation.observationName} onChange={e => setObservation({ ...observation, observationName: e.target.value })} />
                                        </div>
                                        <div className='inline-flex'>
                                            <label className='font-semibold mr-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor="observationSeverity">{t('Severity')}</label>
                                            <select id='observationSeverity' name='observationSeverity' className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border' value={observation.severity} onChange={e => setObservation({ ...observation, severity: e.target.value as TSeverity })}>
                                                <option>{t('Low')}</option><option>{t('Medium')}</option><option>{t('High')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <label className='block font-bold mb-1 text-lg text-hvpd-pickled-bluewood-400' htmlFor="observationDetails">{t('Observation details')}</label>
                                    {editorBlink ? null : <Editor
                                        initialValue={observation.stringValue}
                                        placeholder={t('Enter observation details here...')}
                                        previewStyle="vertical"
                                        height="460px"
                                        onChange={(e) => {
                                            const markDown = editorRef.current?.getInstance().getMarkdown();
                                            setObservation(markDown ? { ...observation, stringValue: markDown } : { ...observation, stringValue: '' });
                                        }}
                                        ref={editorRef}
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                    />}
                                </div>
                                {hasImages ? <div className='h-full grid grid-rows-[1fr_auto]'>
                                    <ImagePicker onClickImage={onClickImage} />
                                    <div className='w-full flex-row-reverse'>
                                        <button className='mr-2 mb-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={() => setShowImageUpload(true)}>{t('Upload image')}</button>
                                    </div>
                                </div> : null}
                            </div>}
                    </div>

                    <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={save} disabled={observationIsIncomplete || busy} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-2">{t('Save')}</button>
                        <button type="button" onClick={onCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{t('Cancel')}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <ImageUpload showModal={showImageUpload} onClose={() => setShowImageUpload(false)} assetUuid={parentUuid} entityType={entityTypeToAssetType[assetType]} onError={(error) => dispatch(addToast(error))} onSuccessfulUpload={imageUploaded} />
    </>)

}

// export type TEntityType = "Company" | 'Asset' | 'Site' | 'SubSite' | 'User' | 'Project';
