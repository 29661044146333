import React, { useEffect, useState, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { Busy } from "../../Busy";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import { keyToTitle, sanitise } from '../../../utils';
import { setSites } from "../../../store/mainSlice";
import { ISite, ICompany } from "../../../store/Interfaces";
import { getSites } from "../../../api";

export interface ISiteProps {
    uuid: string;
    detailsOnly?: boolean;
}

const embargoedSiteKeys: Array<keyof ISite> = ['uuid'];

interface ISiteBlockProps {
    site: ISite;
    companies: ICompany[];
}

const SiteBlock: React.FunctionComponent<ISiteBlockProps> = ({ site, companies }) => {
    const { t } = useTranslation();
    const keysToTitle: Record<string, string> = {
        'address': t('Address'),
        'companyUuid': t('Company'),
        'ratedPower_kW': t('Rated Power kW'), 'voltage_kV': t('Voltage kV'), 'ratedSpeedRPM': t('Rated Speed RPM'), 'kva': t('kVA'),
        addressZIP: t('Zip Code'), logoURL: t('Logo'), 'taxReferenceID': t('Tax Ref')
    };
    const getCellValue = (site: ISite, key: keyof ISite): React.ReactElement | string => {
        if (key === 'companyUuid') {
            const company = companies.find(c => c.uuid === site[key]);
            if (company) {
                return company.name ?? '';
            }
            return <FontAwesomeIcon className='fa-spin text-hvpd-grey-400' icon={solid('spinner')} />
        } else if (key === 'logoURL') {
            return <img alt={site.name} src={site[key]} />
        } else if (key === 'website') {
            return <a href={site[key]} target='_blank' rel='noreferrer'>{site[key]}</a>
        }
        return sanitise(site[key]?.toString());
    }
    return <div className='grid grid-cols-[2fr_3fr] gap-x-1 text-sm m-2' > {
        Object.keys(site).filter((key) => !embargoedSiteKeys.includes(key as keyof ISite)).sort().map((key, ix) =>
            (<ElementRow key={`A_${key}_${ix}`} title={keyToTitle(key, keysToTitle)} value={getCellValue(site, key as keyof ISite)} index={ix} />))
    }
    </div >
}
interface IElementRowProps {
    title: string;
    value: string | ReactElement;
    index: number;
}


const ElementRow: React.FunctionComponent<IElementRowProps> = ({ title, value, index }) => (
    <><div className={`font-semibold text-right pr-2 border-r-2 ${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:hover:bg-slate-200`}>{title}</div><div className={`${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:bg-slate-200`}>{value}</div></>
)


export const SiteDetails: React.FunctionComponent<ISiteProps> = ({ uuid, detailsOnly }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, companies, sites } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token && sites.length === 0) {
            setLoading(true);
            // currently getting _all_ subsites, not just the one we want           
            getSites(token).then((res) => {
                if (res instanceof Array) {
                    dispatch(setSites(res));
                }
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });

        }
    }, [token, sites, dispatch]);

    const site = sites.find((s) => s.uuid === uuid);

    return (
        <div className={`overflow-y-auto  ${detailsOnly ? 'h-[92%]' : 'mt-16'}`}>
            {loading ? <Busy /> : null}
            {!loading ?
                <>{site ?
                    detailsOnly ? <SiteBlock site={site} companies={companies} /> :
                        <div className='bg-white border border-gray-200 py-4 px-5'>
                            <h2 className='mb-0'>
                                <span className='relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none focus:outline-none'>{t('Site')}</span>
                            </h2>
                            <div className='px-5 overflow-y-auto max-h-[80vh]'>
                                <SiteBlock site={site} companies={companies} />
                            </div>
                        </div>
                    :
                    <div>{t('No site found')}</div>
                }
                </> : null
            }
        </div>
    )
}