import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Busy } from "../../Busy";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import { keyToTitle, sanitise } from '../../../utils';
import { setCompanies } from "../../../store/mainSlice";
import { ICompany } from "../../../store/Interfaces";
import { getCompanies } from "../../../api";
import { ProjectName } from "../ProjectName";
import { UserName } from "../UserName";
export interface ICompanyProps {
    uuid: string;
    detailsOnly?: boolean;
}

const embargoedCompanyKeys: Array<keyof ICompany> = ['uuid'];

interface ICompanyBlockProps {
    company: ICompany;
}

const CompanyBlock: React.FunctionComponent<ICompanyBlockProps> = ({ company }) => {
    const { t } = useTranslation();

    const keysToTitle: Record<string, string> = {
        'address': t('Address'),
        'ratedPower_kW': t('Rated Power kW'), 'voltage_kV': t('Voltage kV'), 'ratedSpeedRPM': t('Rated Speed RPM'), 'kva': t('kVA'),
        addressZIP: t('Zip Code'), logoURL: t('Logo'), 'taxReferenceID': t('Tax Ref')
    };
    const getCellValue = (company: ICompany, key: keyof ICompany): React.ReactElement | string => {
        if (key === 'users') {
            return <UserName uuids={company[key] ?? []} />
        }
        else if (key === 'projects') {
            return <ProjectName uuids={company[key] ?? []} />
        } else if (key === 'logoURL') {
            return <img alt={company.name} src={company[key]} />
        } else if (key === 'website') {
            return <a href={company[key]} target='_blank' rel='noreferrer'>{company[key]}</a>
        }
        return sanitise(company[key]?.toString());
    }

    return <div className='grid grid-cols-[2fr_3fr] gap-x-1 text-sm m-2'>{
        Object.keys(company).filter((key) => !embargoedCompanyKeys.includes(key as keyof ICompany)).sort().map((key, ix) =>
            (<ElementRow key={`A_${key}_${ix}`} title={keyToTitle(key, keysToTitle)} value={getCellValue(company, key as keyof ICompany)} index={ix} />))
    }
    </div>
}
interface IElementRowProps {
    title: string;
    value: string | React.ReactElement;
    index: number;
}

const ElementRow: React.FunctionComponent<IElementRowProps> = ({ title, value, index }) => (
    <><div className={`font-semibold text-right pr-2 border-r-2 ${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:hover:bg-slate-200`}>{title}</div><div className={`${index & 1 ? 'bg-slate-100' : 'bg-slate-50'} hover:bg-slate-200 break-normal whitespace-normal`}>{value}</div></>
)

export const CompanyDetails: React.FunctionComponent<ICompanyProps> = ({ uuid, detailsOnly }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { token, companies } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token && companies.length === 0) {
            setLoading(true);
            // currently getting _all_ subCompanys, not just the one we want           
            getCompanies(token).then((res) => {
                if (res instanceof Array) {
                    dispatch(setCompanies(res));
                }
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });

        }
    }, [token, companies, dispatch]);

    const company = companies.find((s) => s.uuid === uuid);

    return (
        <>
            {loading ? <Busy /> : null}
            {!loading ?
                <>{company ?
                    detailsOnly ? <div className='px-5 overflow-y-auto h-[92%]'><CompanyBlock company={company} /></div> :
                        <div className='bg-white border border-gray-200 py-4 px-5'>
                            <h2 className='mb-0'>
                                <span className='relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none focus:outline-none'>{t('Company')}</span>
                            </h2>
                            <div className='px-5 overflow-y-auto max-h-[80vh]'>
                                <CompanyBlock company={company} />
                            </div>
                        </div>
                    :
                    <div>{t('No company found')}</div>
                }</>
                : null}
        </>
    )
}