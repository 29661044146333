import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { TOptions } from "../Admin/FormTemplate";

const HEIGHT_MIN = 4;
const HEIGHT_MAX = 24;
const HEIGHT_STEP = 4;
interface ISelectPairProps {
    name: string;
    existingValues: Array<string | number>;
    availablePairs: TOptions;
    onChange: (newValue: Array<string | number>) => void;
    labelSelected?: string;
    availableComponent?: React.ReactNode;
}

export const SelectPair: React.FunctionComponent<ISelectPairProps> = ({ name, existingValues, availablePairs, onChange, labelSelected = 'Existing', availableComponent }) => {
    const { t } = useTranslation();
    const selectExistingRef = useRef<HTMLSelectElement>(null);
    const selectAvailableRef = useRef<HTMLSelectElement>(null);

    const [selectedExisting, setSelectedExisting] = useState<string[]>([]);
    const [selectedAvailable, setSelectedAvailable] = useState<string[]>([]);
    const [height, setHeight] = useState(4);

    const handleSelectExisting = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = Array.from(e.target.selectedOptions, option => {
            return option.value;
        });
        setSelectedExisting(value);
    }

    const handleSelectAvailable = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedAvailable(value);
    }

    const getAvailableEntries = () => {
        if (!existingValues || !availablePairs) {
            return [];
        }
        return (availablePairs ?? []).filter(p => (existingValues ?? []).every(ex => ex !== p.value));
    }

    const getOptionFromValue = (value: string) => (availablePairs ?? []).find(p => p.value === value) ?? { name: '', value: '' };

    const removeFromExisting = () => {
        if (selectExistingRef.current) {
            selectExistingRef.current.value = '';
        }
        if (selectAvailableRef.current) {
            selectAvailableRef.current.value = '';
        }
        const newValues = (existingValues ?? []).filter(p => selectedExisting.every(ex => ex !== p.toString()));
        onChange(newValues);
        setSelectedAvailable([]);
        setSelectedExisting([]);
    }

    const addToExisting = () => {
        if (selectExistingRef.current) {
            selectExistingRef.current.value = '';
        }
        if (selectAvailableRef.current) {
            selectAvailableRef.current.value = '';
        }
        const newValues = (existingValues ?? []).concat(selectedAvailable);
        onChange(newValues);
        setSelectedAvailable([]);
        setSelectedExisting([]);
    }

    const bumpHeight = () => {
        if (height === HEIGHT_MAX) {
            setHeight(HEIGHT_MIN);
        } else {
            setHeight(height + HEIGHT_STEP);
        }
    }

    const nameSort = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);

    return (
        <div className="grid gap-2 grid-cols-2">
            <label htmlFor={`${name}-existing`} className='text-sm font-semibold'>{labelSelected}</label>
            <div className="w-full flex flex-row">
                <label htmlFor={`${name}-available`} className='text-sm font-semibold  flex-1'>{t('Available')}</label>
                <button type='button' className='text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-0 border-solid border-1 rounded-sm text-xs font-medium inline flex-none' onClick={bumpHeight}>+</button>
            </div>
            <select id={`${name}-existing`} size={height} ref={selectExistingRef} className="outline outline-1 outline-hvpd-grey-600" onChange={handleSelectExisting} multiple>
                {(existingValues ?? []).map(p => getOptionFromValue(p.toString())).sort(nameSort).map((p, idx) => <option className='text-sm font-semibold' key={`${p.value}-${idx}-existing`} value={p.value}>{p.name}</option>)}
            </select>
            {availableComponent ?
                <div className='flex flex-col'>{availableComponent}</div> :
                <select id={`${name}-available`} size={height} ref={selectAvailableRef} className="outline outline-1 outline-hvpd-grey-600" onChange={handleSelectAvailable} multiple>{
                    getAvailableEntries().map((p, idx) => <option className='text-sm font-semibold' key={`${p.value}-${idx}-available`} value={p.value}>{p.name}</option>)}
                </select>
            }

            <button type='button' onClick={removeFromExisting} disabled={selectedExisting.length === 0} className='text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md m-2'><FontAwesomeIcon icon={solid('arrow-right')} /></button>
            <button type='button' onClick={addToExisting} disabled={selectedAvailable.length === 0} className='text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md m-2'><FontAwesomeIcon icon={solid('arrow-left')} /></button>
        </div >
    )
}
