import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setDevices, setSalesOrders } from "../../../store/mainSlice";
import { FormTemplate, TEntity, IFormField, FormContext } from "../FormTemplate";
import { createDevice, deleteDevice, updateDevice, getSalesOrders } from "../../../api";

import { Busy } from "../../Busy";
import { deviceEditForm } from "../../../templates/formTemplates";

import { IDevice, ISalesOrder } from "../../../store/Interfaces";

interface IDeviceEditProps {
    device?: IDevice;
    endEdit: () => void;
    clearFocus: () => void;
}

export const DeviceEdit: React.FunctionComponent<IDeviceEditProps> = ({ device, endEdit, clearFocus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, devices, salesOrders } = useSelector(state => state.main);

    const [wipDevice, setWipDevice] = useState<IDevice>();
    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(deviceEditForm);

    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        setWipDevice({ ...device as IDevice });
    }, [device]);

    const salesOrderSort = (a: ISalesOrder, b: ISalesOrder) => {
        return (a?.salesOrderTitle ?? '').localeCompare(b?.salesOrderTitle ?? '');
    }

    useEffect(() => {
        const ac = new AbortController();
        const localGetSalesOrders = async (ac: AbortController) => {
            setBusy(true);
            getSalesOrders(token).then((res) => {
                if (Array.isArray(res)) {
                    dispatch(setSalesOrders(res));
                }
                setBusy(false);
            }).catch((err) => {
                setBusy(false);
                console.error(err);
            });
        };
        if (salesOrders.length) {
            const localFormWithOptions = deviceEditForm.map(field => {
                if (field.type === 'Select' && field.propValue === 'salesOrderUuid') {
                    // NB for some reson salesOrders uuids are forced to upper case even though they are stored as lower case in this entity
                    return { ...field, options: ([...salesOrders ?? []]).sort(salesOrderSort).map(salesOrder => ({ name: salesOrder?.salesOrderTitle ?? '', value: salesOrder.uuid.toLowerCase() })) }
                }
                else {
                    return field
                }
            })
            setFormWithOptions(localFormWithOptions);
        } else {
            localGetSalesOrders(ac);
        }
    }, [salesOrders])

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createDevice(token, entity as IDevice).then((device: IDevice) => {
                dispatch(addToast(t('Device created')));
                setBusy(false);
                setWipDevice(device);
                dispatch(setDevices([...devices, device]));
            }).catch((err) => {
                console.error(err);
                setBusy(false);
                dispatch(addError(t('Error creating device')));
            })
        } else {
            updateDevice(token, entity as IDevice).then((device: IDevice) => {
                dispatch(addToast(t('Device updated')));
                setBusy(false);
                setWipDevice(device);
                dispatch(setDevices(devices.map(so => so.uuid === entity.uuid ? device as IDevice : so)));
            }).catch((err) => {
                console.error(err);
                setBusy(false);
                dispatch(addError(t('Error updating device')));
            });
        }
    }
    const onDelete = () => {
        setBusy(true);
        deleteDevice(token, wipDevice?.uuid as string).then(() => {
            dispatch(addToast(t('Device deleted')));
            clearFocus();
            setBusy(false);
            setWipDevice(undefined);
            dispatch(setDevices(devices.filter(ug => ug.uuid !== wipDevice?.uuid)));
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }

    return (
        <div className='m-2 overflow-auto max-h-full pb-4'>
            <div className="relative z-30 w-full h-full">
                {busy ? <Busy /> :
                    <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        <FormTemplate entityName='Device' form={formWithOptions} setForm={setFormWithOptions} entity={wipDevice as IDevice} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                    </FormContext.Provider>}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}
        </div >)
}