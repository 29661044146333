import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../hooks";
import { addToast, setTableResult, addError } from "../../store/mainSlice";
import { changeReportApprovalStatus, changeResultApprovalStatus, getTableDefinition } from "../../api";
import { IResultHistory, TTestType } from "../../store/Interfaces";
import { Busy } from "../Busy";

export interface IReportApprovalProps {
    result: IResultHistory;
    setReportApproval?: (approval: boolean) => void;
    setResultApproval?: (approval: boolean) => void;
    uuid: string;
    resultEndpointName: TTestType
}

export const ReportApproval: React.FunctionComponent<IReportApprovalProps> = ({ result, setReportApproval, setResultApproval, resultEndpointName, uuid }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, tableEndPoints, extendedFilter } = useSelector(state => state.main);
    //const [resultApproved, setResultApproved] = useState<boolean>(true);
    //const [resultHistory, setResultHistory] = useState<IResultHistory[]>([]);
    const [confirmReport, setConfirmReport] = useState<boolean>(false);
    const [confirmResult, setConfirmResult] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [ac, setAc] = useState<AbortController>(new AbortController());

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        setConfirmReport(false);
        setConfirmResult(false);
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        const getLocalAssetResults = async () => {
            try {
                setLoading(true);
                //const results = await getAssetResults(token, uuid, resultEndpointName, 'Asset');
                //setResultHistory(results);
                /*if (results && results.length > 0 && !results[0].approved) {
                    setResultApproved(false);
                } */
                setLoading(false);
            } catch (e) {
                console.error(e);
                dispatch(addError('Error getting asset results'));
                setLoading(false);
            }
        }
        //setResultApproved(true);
        setConfirmReport(false);
        setConfirmResult(false);
        /*if (visible) {
            setResultHistory([]);
            getLocalAssetResults();
        }        console.log(`Report ${JSON.stringify(report)} report.approved ${report.reportApproved}`)
        return () => {
            ac.abort();
        }*/

    }, [uuid]);

    const refreshResultsTable = async () => {
        const tableEndPoint = tableEndPoints.find(t => t.name === resultEndpointName);
        if (tableEndPoint) {
            try {
                // we don't want to abort this api call if the component is destroyed
                const tableResult = await getTableDefinition(token, tableEndPoint, extendedFilter, true, new AbortController());
                dispatch(setTableResult([resultEndpointName, { rows: tableResult.rows }]));
            } catch (e) {
                dispatch(addError(t('Error refreshing results table')));
                console.error(e);
            }
        }
    }

    const onClickReportApprovalButton = async () => {
        if (confirmReport) {
            setLoading(true);
            setConfirmReport(false);
            try {
                const updateStatus = await changeReportApprovalStatus(token, result.reportUuid, result.reportApproved ? t('Disapprove') : t('Approve'));
                if (updateStatus) {
                    dispatch(addToast(result.reportApproved ? t('Report approved') : t('Report disapproved')));
                    setReportApproval && setReportApproval(!result.reportApproved);
                    refreshResultsTable();
                }
                setLoading(false);
            } catch (e) {
                dispatch(addError(result.reportApproved ? t('Error changing report approval status to Disapprove') : t('Error changing report approval status to Disapprove')));
                setLoading(false);
            }
        } else {
            setConfirmReport(true);
        }
    }

    const onClickResultApprovalButton = async () => {
        if (confirmResult) {
            setLoading(true);
            setConfirmResult(false);
            //const resultApproved = resultHistory && resultHistory.length > 0 && !resultHistory[0].approved;
            try {
                const updateStatus = await changeResultApprovalStatus(token, result.uuid, result.approved ? 'Disapprove' : 'Approve');
                if (updateStatus) {
                    dispatch(addToast(result.approved ? t('Result disapproved') : t('Result approved')));
                    //(!resultApproved);
                    setResultApproval && setResultApproval(!result.approved);

                    refreshResultsTable();
                    setLoading(false);
                }
            }
            catch (e) {
                dispatch(addError(result.approved ? t('Error changing result approval status to Disapprove') : t('Error changing result approval status to Approve')));
                setLoading(false);
            }
        } else {
            setConfirmResult(true);
        }
    }

    // const resultButtonDisabled = () => (resultHistory == null || resultHistory.length === 0);

    const getButtonText = (txt: 'result' | 'report') => {
        if (txt === 'result') {
            const resultApproved = result.approved;
            if (confirmResult) {
                return resultApproved ? t('Confirm result disapproval') : t('Confirm result approval');
            }
            return resultApproved ? t('Disapprove result') : t('Approve result');
        } else {
            if (confirmReport) {
                return result.reportApproved ? t('Confirm report disapproval') : t('Confirm report approval');
            }
            if (!result) {
                return t('No report to approve');
            }
            return result.reportApproved ? t('Disapprove report') : t('Approve report');
        }
    }


    return <div className="ml-1 mt-0 float-right align-self-top inline-grid grid-flow-row grid-rows-2 gap-y-1 text-xxs xl:text-xs justify-items-end">
        {loading ? <Busy size="xs" /> :
            <>
                <>
                    {!confirmResult ? <button onClick={onClickReportApprovalButton} className="mr-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-0 border-solid border-1 text-xxs xl:text-xs font-medium rounded-sm w-fit">{getButtonText('report')}</button> : null}
                    {!confirmReport ? <button onClick={onClickResultApprovalButton} className="mr-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-0 border-solid border-1 text-xxs xl:text-xs font-medium rounded-sm w-fit">{getButtonText('result')}</button> : null}
                </>
                {(confirmReport || confirmResult) ?
                    <button onClick={() => { setConfirmReport(false); setConfirmResult(false) }} className="mr-1 text-white bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-0 border-solid border-1 text-xxs xl:text-xs font-medium rounded-sm w-fit">{t('Cancel')}</button> : null}
            </>
        }
    </div >
}