import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from 'react-pdf/dist/esm';

interface IOneReportDocumentProps {
    reportPDF: string | undefined;
}

type TLoaded = {
    numPages: number;
}

export const OneReportDocument: React.FunctionComponent<IOneReportDocumentProps> = ({ reportPDF }) => {
    const { t } = useTranslation();
    const reportContainer = useRef<HTMLDivElement>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [reportError, setReportError] = useState('');
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = (loaded: TLoaded) => {
        setNumPages(loaded.numPages);
        setReportError('');
        setPageNumber(1);
    }

    const getContainerHeight = () => {
        if (reportContainer.current) {
            return reportContainer.current?.offsetHeight ?? 500;
        }
        return 500;
    }
    const changePage = (offset: number) =>
        setPageNumber(prevPageNumber => prevPageNumber + offset);

    const previousPage = () => changePage(-1);

    const nextPage = () => changePage(1);

    return (

        <div className='grid grid-rows_[1fr_60px]' >

            <div className="overflow-y-auto min-h-[70vh]" ref={reportContainer}>
                <Document onLoadSuccess={onDocumentLoadSuccess} file={`data:application/pdf;base64,${reportPDF}`}>
                    <Page pageNumber={pageNumber} height={getContainerHeight()} />
                </Document>

            </div>

            <div>
                <div className='pl-4 inline-block min-w-max'>
                    {numPages > 1 ?
                        <>
                            <span className="mr-2">{t('Page p of n', { pageNumber: pageNumber || (numPages ? 1 : '--'), numPages: numPages || '--' })}</span>
                            <button
                                type="button"
                                className='bg-gray-300 text-sm rounded-md px-2 py-1 text-black hover:bg-gray-400 border-gray-500 my-2'
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                            >
                                {t('Previous')}
                            </button>
                            <button
                                type="button"
                                className='bg-gray-300 text-sm rounded-md ml-2 mr-2 px-2 py-1 text-black hover:bg-gray-400 border-gray-500 my-2'
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                                {t('Next')}
                            </button>

                        </> : null
                    }
                    {/* <button onClick={downloadTrendLocal} className='bg-sky-300 text-sm rounded-md px-2 py-1 text-black hover:bg-sky-400 border-sky-400  my-2'>Download</button> */}
                </div>
            </div>
        </div>)
}
