import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'
import { IDevice } from '../store/Interfaces';

const { dispatch } = store;

export const getDevices = async (token: string): Promise<Array<IDevice> | string> => {
    const url = `${getApiEndpoint()}/Device`;
    const p = new Promise<IDevice[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the device list [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as Array<IDevice>);
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}


export const createDevice = async (token: string, device: IDevice): Promise<IDevice> => {
    const url = `${getApiEndpoint()}/Device`;
    const p = new Promise<IDevice>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...device, uuid: undefined })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating device [${resp.status}]`));
            }
        }).then(json => {
            const deviceReturned: IDevice = json as IDevice;
            resolve(sanitise(deviceReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error creating');
        });
    })
    return p;
}

export const updateDevice = async (token: string, device: IDevice): Promise<IDevice> => {
    const url = `${getApiEndpoint()}/Device`;
    const p = new Promise<IDevice>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(device)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating device [${resp.status}]`));
            }
        }).then(json => {
            const deviceReturned: IDevice = json as IDevice;
            resolve(sanitise(deviceReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error updating');
        });
    })
    return p;
}


export const deleteDevice = async (token: string, uuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Device/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Error deleting device [${resp.status}]`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error deleting.');
        });
    })
    return p;
}