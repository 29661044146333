import React, { useEffect, useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { Popover, Tooltip, initTE } from "tw-elements";

import { useTimeout } from '../../hooks';

const popOverTemplate = `<div class="opacity-0 transition-opacity duration-150 ease-in-out absolute top-0 left-0 z-[3000] block max-w-[267px] break-words bg-white bg-clip-padding border border-neutral-100 rounded-lg shadow-[0_0px_3px_0_rgba(0,0,0,0.07),0_2px_2px_0_rgba(0,0,0,0.04)] text-sm not-italic font-normal text-left no-underline underline-offset-auto normal-case leading-6 tracking-normal break-normal whitespace-normal dark:bg-neutral-700 dark:border-0 dark:text-white data-[popper-reference-hidden]:hidden" role="tooltip">`
    + ` <h4 class="popover-header py-2 px-4 mb-0 border-b-2 border-neutral-100 rounded-t-lg font-medium empty:hidden dark:border-neutral-500"></h4>` +
    `<div class="popover-body p-4 text-[#212529] dark:text-white"></div> </div>`;


const POPOVER_HIDE_TIMEOUT = 4000;

interface ITextFilterProps {
    quickFilterColours: string;
    filterText: string;
    setFilterText: (filterText: string) => void;
}

export const TextFilter: React.FunctionComponent<ITextFilterProps> = ({
    quickFilterColours,
    filterText,
    setFilterText,

}) => {
    const { t } = useTranslation();
    const filterRef = useRef<HTMLInputElement>(null);
    const popoverRef = useRef<typeof Popover>(null);
    const [popoverTimeout, setPopoverTimeout] = useState<number | null>(null);
    const [blockPopover, setBlockPopover] = useState(false);

    useEffect(() => {
        initTE({ Popover, Tooltip });
        return () => {
            if (popoverRef.current) {
                popoverRef.current.hide();
            }
            setPopoverTimeout(null);
        }
    }, []);

    useTimeout(() => {
        if (popoverRef.current) {
            popoverRef.current.hide();
        }
        setPopoverTimeout(null);

    }, popoverTimeout)

    const onFilterFocus = () => {
        if (blockPopover) {
            return;
        }
        const c = filterRef.current;
        const title = t("Filter table");
        // const content = `Enter text to filter the table rows below.  Use + to separate multiple filters.`;
        const div = document.createElement('div');
        const root = createRoot(div);
        flushSync(() => {
            root.render(<DismissablePopoverConent />);
        });
        const content = div.innerHTML;
        const p = new Popover(c, { trigger: 'manual', title, content, html: true, template: popOverTemplate, offset: [0, 40], sanitize: false });
        p.show();
        div.remove();
        popoverRef.current = p;
        setPopoverTimeout(POPOVER_HIDE_TIMEOUT);
        const tooltipButton = document.getElementById(`tooltip-dismiss-button`);
        if (tooltipButton) {
            tooltipButton.onclick = () => {
                popoverRef.current.hide();
                popoverRef.current = null;
                setBlockPopover(true);
            }
        }
    }

    const onFilterBlur = () => {
        if (popoverRef.current) {
            popoverRef.current.hide();
        }
    }

    return (
        <><input type='text' ref={filterRef} id='main-results-filter'
            className='w-24 p-1 text-sm rounded-sm border-hvpd-grey-200 focus:hvpd-grey-500 active:hvpd-grey-500 border-1' value={filterText} onFocus={onFilterFocus} onBlur={onFilterBlur} onChange={(e) => setFilterText(e.target.value)} placeholder={t('Filter results')} />
            <button type='button' title={t('Clear quick filter')} onClick={() => setFilterText('')}
                className={`text-white disabled:text-hvpd-grey-700 ${quickFilterColours} px-2 py-1 border-solid border-1 text-sm font-medium`}><FontAwesomeIcon icon={solid('filter')} /></button>
        </>
    );
};


const DismissablePopoverConent: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (<div className='grid grid-cols-1'>
        <div><div>{t('Enter text to filter the table rows below.  Use + to separate multiple filters.')}</div>
            <button id="tooltip-dismiss-button" className="rounded-md bg-hvpd-pickled-bluewood-50 disabled:text-hvpd-grey-400 text-white border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-100 px-2 py-1 border-solid border-1 text-sm font-medium float-right">{t('Dont show again')}</button>
        </div>
    </div>);
}
