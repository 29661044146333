import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from '../LogoutButton';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import {
    setAssets, setCompanies, setCurrentUser, addProjects, setSites, setSubSites,
    setToken, setTokenRejected, setTableEndPoints
} from '../../store/mainSlice';
import {
    ICompany, IBaseProject, ISite, ISubSite, IAsset, IUser,
} from '../../store/Interfaces';
import {
    getAssets, getCompanies, getCurrentUser, getProjects,
    getSites, getSubSites, getTableTestResultList,
} from '../../api';

interface IAuthUser {
    name?: string | undefined;
    email?: string | undefined;
    nickname?: string | undefined;
}

export const UserStatus: React.FunctionComponent = () => {
    const { user, getAccessTokenSilently } = useAuth0<IAuthUser>();
    const [localToken, setLocalToken] = useState('');
    const { database, currentUser, companies } = useSelector(store => store.main);

    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.name && localToken.length === 0) {
            getAccessTokenSilently().then((localTokenX: string) => {
                dispatch(setToken(localTokenX));
                dispatch(setTokenRejected(false))
                setLocalToken(localTokenX);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, getAccessTokenSilently])

    useEffect(() => {
        const ac = new AbortController();
        if (localToken.length > 0) {
            const userFetchPromise = getCurrentUser(localToken);
            // defer the main cluster of fetches until the user is fetched             
            userFetchPromise.then((currentUser) => {
                dispatch(setCurrentUser(currentUser as IUser));
                getTableTestResultList(localToken).then(tableTestResults => dispatch(setTableEndPoints(tableTestResults))).catch(e => console.error(e));
                getAssets(localToken).then(assets => dispatch(setAssets(assets as IAsset[]))).catch(e => console.error(e));
                getCompanies(localToken).then(companies => dispatch(setCompanies(companies as ICompany[]))).catch(e => console.error(e));
                getProjects(localToken, ac).then(projects => {
                    dispatch(addProjects(projects as IBaseProject[]))
                }).catch(e => console.error(e));

                getSites(localToken).then(sites => dispatch(setSites(sites as ISite[]))).catch(e => console.error(e));
                getSubSites(localToken).then(subSites => dispatch(setSubSites(subSites as ISubSite[]))).catch(e => console.error(e));
            });
        }
        return () => {
            ac.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localToken, database.version])

    const userLogo = (): { logo: string, companyName: string } => {
        if (currentUser && currentUser.companies && currentUser.companies.length > 0) {
            const companyList = currentUser.companies
                .filter(uuid => companies.some(company => company.uuid === uuid && undefined !== company.logoURL))
                .map(uuid => companies.find(company => company.uuid === uuid));
            if (companyList && companyList.length > 0) {
                return { logo: companyList[0]?.logoURL ?? '', companyName: companyList[0]?.name ?? '' };
            }
        }
        return { logo: '', companyName: '' };
    }
    const { logo, companyName } = userLogo();

    return (
        <div className='grid grid-cols-[210px,1fr]'>
            {logo ? <div className='mt-2'><img alt={companyName} src={logo} className='max-w-[200px] max-h-[46px] float-right pr-2' /></div> : null}
            <div className='text-white text-xs grid grid-rows-1'>
                <div className='align-middle text-right text-xs'>{user?.email ?? ''}</div>
                <div className='align-middle text-right mt-1'><LogoutButton unAuthorised={false} /></div>
            </div>
        </div>
    );
}