import React from "react";
//import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import styles from './LoginButton.module.css';

export const LoginButton: React.FunctionComponent = () => {
    //    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();
    return <button className={`${styles.loginButton} text-white bg-hvpd-red-500 hover:bg-hvpd-red-300 py-2 px-3 font-bold text-base rounded-md border-0`} onClick={loginWithRedirect}>Log In</button >;
};
