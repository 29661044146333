import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { TSearchTemplate } from "../../store/Interfaces";

import { BASE_CLASS, TFilterClass, TFilterDefinitions, TFilterEnabled, TFilterFragment } from "./FilterGroup";

export interface IFilterBadgesProps {
    filtersActive: () => Record<TFilterClass, TFilterEnabled>;
    searchTemplates: TSearchTemplate[];
    filterDefinitions: TFilterDefinitions;
    deleteFilter: (filterClass: TFilterClass, propName: string) => void;
}

export const FilterBadges: React.FunctionComponent<IFilterBadgesProps> = ({ filtersActive, searchTemplates, filterDefinitions, deleteFilter }) => {
    const { t } = useTranslation();
    const [enabledFilters, setEnabledFilters] = useState<TFilterClass[]>([]);

    useEffect(() => {
        const hasAFilterValue = (filterClass: TFilterClass): boolean => {
            return Object.keys(filtersActive()[filterClass]).some((propName) => {
                const fd = filterDefinitions[filterClass].find(f => f.propName === propName);
                if (!fd) {
                    return false;
                }
                return filterFragmentIsValid(fd);
            });
        }
        const localEnabledFilters = (Object.keys(filtersActive()) as TFilterClass[]).filter(filterClass => Object.keys(filtersActive()[filterClass]).length > 0).filter(hasAFilterValue)
        setEnabledFilters(localEnabledFilters);
    }, [filterDefinitions, filtersActive])

    const toUpper = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

    const keyToTitle = (filterClass: TFilterClass, key: string) => {
        const searchKey = filterClass === BASE_CLASS ? key : filterClass.toLocaleLowerCase() + "." + key;
        const searchParam = searchTemplates.find(sp => sp.propName === searchKey);
        if (searchParam) {
            return searchParam.title;
        }
        return toUpper(key);
    }

    const filterFragmentIsValid = (fd: TFilterFragment): boolean => {
        if (fd === undefined) {
            return false;
        }
        if (fd.matches === 'contains' && fd.value === '') {
            return false;
        }
        if (fd.matches === 'matches' && Array.isArray(fd.value) && fd.value.length === 0) {
            return false;
        }
        if (fd.matches === 'minmax' && Array.isArray(fd.value) && fd.value[0] === '' && fd.value[1] === '') {
            return false;
        }
        if ((fd.matches === 'min' || fd.matches === 'max' || fd.matches === 'value') && fd.value === '') {
            return false;
        }
        return true;
    }


    const getFilterString = (fd: TFilterFragment | undefined): string => {
        const sfrags: Array<string> = [];
        if (!fd || !filterFragmentIsValid(fd)) {
            return '';
        }
        if (fd.matches === 'matches') {
            if (Array.isArray(fd.value) && fd.value.length > 0) {
                let values: Array<string> = fd.value as Array<string>;
                if (fd.propName === 'assetSubType') {
                    values = values.map(v => v.split('_')[1])
                }
                if (values.length === 1) {
                    sfrags.push(t('is v', { value: values[0] }));
                }
                else {
                    sfrags.push(t('is one of v', { value: values.map(v => `"${v}"`).join(', ') }));
                }
            }
        }
        if (fd.matches === 'min') {
            sfrags.push(t('is greater than v', { value: fd.value }));
        } if (fd.matches === 'max') {
            sfrags.push(t('is less than v', { value: fd.value }));
        } if (fd.matches === 'minmax') {
            sfrags.push(t('is between v and v', { value1: (fd.value as [number, number])[0], value2: (fd.value as [number, number])[1] }));
        } if (fd.matches === 'contains' && fd.value.toString().length > 0) {
            sfrags.push(t('contains v', { value: fd.value }));
        }
        if (fd.matches === 'value') {
            sfrags.push(t('equals v', { value: fd.value }))
        }
        return sfrags.join(` ${t('and')} `);
    }

    return (
        <div>
            {enabledFilters.length > 0 ?
                (<div className="border-2 mr-1">
                    <h2 className="text-center text-sky-900 font-bold text-lg">Filters selected</h2>
                    {enabledFilters
                        .map((filterClass, idx) =>
                        (<div key={`${filterClass}_${idx}`} className="mt-3 bg-slate-100 border-slate-200">
                            <div className=' text-hvpd-pickled-bluewood-300 capitalize font-bold text-center mb-2 underline'>{filterClass}</div>
                            {
                                Object.keys(filtersActive()[filterClass]).map((propName, idx) => {
                                    const fd = filterDefinitions[filterClass].find(f => f.propName === propName);
                                    const filterString = getFilterString(fd);
                                    return (filterString.length > 0 ? <div key={`${filterClass}_${propName}_${idx}`} className='grid grid-cols-[4fr_4fr_1fr]'>
                                        <div className="font-semibold text-right pr-2 border-r-2 inline">{keyToTitle(filterClass, propName)}</div>
                                        <div className="ml-1 inline">{filterString}</div>
                                        <div className="ml-1 inline text-right"><button onClick={() => deleteFilter(filterClass, propName)} className='px-2 py-1 border-solid border-1 border-red-200 text-red-600 text-sm rounded-md hover:text-red-700' ><FontAwesomeIcon icon={solid('trash')} /></button></div>
                                    </div> : null)
                                })

                            }
                        </div>))}
                </div>
                ) : <div className='text-center font-semibold py-2 text-hvpd-pickled-bluewood-400'>{t('No extended filters selected')}</div>
            }
        </div>
    )

}



