import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TEntityType, TChunkedUploadId, TChunkedUploadResult, TChunkMergePayload } from '../../store/Interfaces';
//import { setError } from '../../store/mainSlice';
import { getChunkUploadID, TUploadType, chunkUpload, completeChunkUpload } from "../../api/fileApi";

import { ConfirmationModal } from "../ConfirmationModal";
export interface IChunkedFileUploadProps {
    token: string;
    entityType: TEntityType;
    uuid: string;
    type: TUploadType;
    file: File;
    chunkSize: number;
    onCancel: () => void;
    onError: (error: unknown) => void;
    onCompleted: (file: File) => void;
}

export const ProgressBar: React.FunctionComponent<{ progress: number }> = ({ progress }) => {
    const { t } = useTranslation();
    return (<div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
            <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-hvpd-pickled-bluewood-500">
                    {t('Upload in progress')}
                </span>
            </div>
            <div className="mr-2">
                <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-hvpd-picked-bluewood-800">
                        {progress}%
                    </span>
                </div>
            </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-hvpd-pickled-bluewood-700">
            <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-hvpd-pickled-bluewood-50"></div>
        </div>
    </div>);
}


export const ChunkedFileUpload: React.FunctionComponent<IChunkedFileUploadProps> = ({ token, entityType, uuid, type, file, chunkSize, onCancel, onError, onCompleted }) => {
    const { t } = useTranslation();
    const [chunkedFiledUploadID, setChunkedFiledUploadID] = useState<TChunkedUploadId>();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [beginChunkedUpload, setBeginChunkedUpload] = useState<boolean>(false);
    const [chunkProgressIndex, setChunkProgressIndex] = useState<number>(0);
    const [chunkProgressTotal, setChunkProgressTotal] = useState<number>(1);

    useEffect(() => {
        setBeginChunkedUpload(false);
    }, []);

    useEffect(() => {
        const localGetChunkUploadID = async (token: string, entityType: TEntityType, uuid: string, type: TUploadType) => {
            try {
                const uploadResult = await getChunkUploadID(token, entityType, uuid, type, file.name, file.size);
                setChunkedFiledUploadID(uploadResult);
                if (uploadResult.uploadDestinationFileExists) {
                    setShowConfirmation(true);
                    setBeginChunkedUpload(false);
                } else {
                    setBeginChunkedUpload(true);
                }
            } catch (error) {
                onError(error);
                console.error(error);
            }
        }
        localGetChunkUploadID(token, entityType, uuid, type);
        const chunkCount = Math.ceil(file.size / chunkSize);
        setChunkProgressTotal(chunkCount);
        setChunkProgressIndex(0);
    }, [file]);

    useEffect(() => {
        if (beginChunkedUpload && chunkedFiledUploadID) {
            const localChunkUpload = async (rangeStart: number, rangeEnd: number, chunkIndex: number): Promise<TChunkedUploadResult> => {
                const p = new Promise<TChunkedUploadResult>(async (resolve, reject) => {
                    try {
                        const uploadResult = await chunkUpload(token, chunkedFiledUploadID.uploadUuid, rangeStart, rangeEnd, file, chunkIndex);
                        resolve(uploadResult);
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
                return p;
            }

            const chunkResults: TChunkedUploadResult[] = [];

            const promiseList = Array.from({ length: chunkProgressTotal }).map(async (_, chunkIndex) => {
                const p = new Promise<boolean>(async (resolve, reject) => {
                    const start = chunkIndex * chunkSize;
                    const end = Math.min(file.size, start + chunkSize);
                    try {
                        const chunkResult = await localChunkUpload(start, end, chunkIndex);
                        chunkResults.push(chunkResult);
                        setChunkProgressIndex((chunkProgressIndex) => chunkProgressIndex + 1);
                        resolve(true);
                    } catch (error) {
                        onError(t('Error uploading chunk'));
                        reject(false);
                    }
                });
                return p;
            });
            Promise.all(promiseList).then(() => {
                const mergePayload = getChunkMergePayload(chunkResults);
                completeChunkUpload(token, chunkedFiledUploadID.uploadUuid, mergePayload).then(compltion => {
                    setChunkProgressIndex(chunkProgressTotal);
                    console.log(t('Upload complete'));
                    onCompleted(file);
                }).catch(error => {
                    onError(t('Error completing upload'));
                });
            })
            setBeginChunkedUpload(false);
            setChunkedFiledUploadID(undefined);
        }
    }, [beginChunkedUpload, chunkedFiledUploadID]);

    const getChunkMergePayload = (chunkResults: TChunkedUploadResult[]): TChunkMergePayload => {
        return {
            uuid: uuid,
            chunkMetadataMap: [...chunkResults].sort((a, b) =>
                a.chunkIndex < b.chunkIndex ? -1 : 1)
                .reduce((acc, chunkResult, idx): Record<string, TChunkedUploadResult> => {
                    acc[idx.toString()] = chunkResult;
                    return acc;
                }, {} as Record<string, TChunkedUploadResult>)
        }
    }

    const confirmUpload = () => {
        setShowConfirmation(false);
        setBeginChunkedUpload(true);
    }

    const cancelUpload = () => {
        setShowConfirmation(false);
        onCancel();
    }

    return <><ProgressBar progress={Math.round((chunkProgressIndex / chunkProgressTotal) * 100)} /><ConfirmationModal confirmationText='File exists, overwrite?' show={showConfirmation} onConfirm={confirmUpload} onCancel={cancelUpload} /></>
}
