import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector } from '../../../hooks';

import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";
import { getFilteredSortedResults } from "../utils";


import { IColumnHeader, TResultRow } from '../../../store/Interfaces';

import {
    IBaseProject, ISortColumn
} from '../../../store/Interfaces';


const columnHeaders: IColumnHeader[] = [{
    title: 'Name', type: 'String', propValue: 'name', propDisplay: 'name'
}, {
    title: 'Is active', type: 'String', propValue: 'isActive', propDisplay: 'isActive'
}, {
    title: 'Notifications', type: 'String', propValue: 'notifications', propDisplay: 'notifications'
}, {
    title: 'Notification time', type: 'String', propValue: 'notificationTime', propDisplay: 'notificationTime'
}, {
    title: 'Start date', type: 'Date', propValue: 'startDate', propDisplay: 'startDate'
}, {
    title: 'End date', type: 'Date', propValue: 'endDate', propDisplay: 'endDate'
}
];

interface IProjectTableProps {
    resultFilterText: string;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    onClickRow: (e: React.MouseEvent, project?: IBaseProject) => void;
}

export const ProjectTable: React.FunctionComponent<IProjectTableProps> = ({ onClickTableColumn, resultFilterText, onClickRow, sortColumn }) => {
    const { t } = useTranslation();
    const { projects } = useSelector(state => state.main);
    const tableHeader = useRef<HTMLTableRowElement>(null);
    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});

    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const projectToDisplay = (project: IBaseProject): TResultRow => {
        return {
            ...project,
            isActive: project.isActive ? t('Yes') : t('No'),
            notifications: project.notifications ? t('Yes') : t('No'),
            uuid: project.uuid,

            gpsLat: 0,
            gpsLong: 0,
            resultUuid: '', siteUuid: '', assetUuid: '', companyUuid: '', tests: 0
        }
    }

    const displayProjects = getFilteredSortedResults(projects.map(projectToDisplay), null, '', resultFilterText, [], sortColumn, columnHeaders);

    return (
        <div className='resultsTableClipper relative overflow-y-auto bg-hvpd-grey-50'>
            {displayProjects.length > 0 ? <table className='table-auto min-w-full border-slate-400 border'>
                <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                    <tr ref={tableHeader}>
                        {columnHeaders.map((colHeader, i) => (
                            <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {displayProjects.map((project, idx) => (
                        <ResultsTableRow key={idx} row={project} columnHeaders={columnHeaders}
                            handleContextMenu={(e: MouseEvent, row: TResultRow) => { }}
                            expandedHeaders={expandedHeaders}
                            onClickRow={(e) => onClickRow(e, projects.find(p => p.uuid === project.uuid) as IBaseProject)}
                            selected={false}
                        />
                    ))}
                </tbody>
            </table> :
                <div className='w-full flex mt-20'>
                    <div className='flex-grow text-center'><span>{t('No results to display')}</span>
                    </div>
                </div>}
        </div>
    );
}