import { IFormField } from "../Components/Admin/FormTemplate";
import { TSearchTemplate } from "../store/Interfaces";


export const assetEditForm: Array<IFormField> = [
    { type: 'String', propValue: 'name', propDisplay: 'Name', required: true },
    { type: 'Select', propValue: 'companyUuid', propDisplay: 'Company', required: true },
    { type: 'Select', propValue: 'siteUuid', propDisplay: 'Site', required: true },
    { type: 'Select', propValue: 'subSiteUuid', propDisplay: 'Sub Site', required: true },
    { type: 'Select', propValue: 'assetType', propDisplay: 'Asset Type', options: [], required: true },
    { type: 'Select', propValue: 'assetSubType', propDisplay: 'Asset Sub Type', options: [], required: true },
    { type: 'String', propValue: 'manufacturer', propDisplay: 'Manufacturer' },
    { type: 'Select', propValue: 'supplyFrequency', propDisplay: 'Supply Frequency (Hz)', required: true },
    { type: 'String', propValue: 'kva', propDisplay: 'KVA' },
    { type: 'String', propValue: 'cableLengthMeters', propDisplay: 'Cable Length (m)' },
    { type: 'String', propValue: 'cableLengthMeters', propDisplay: 'Cable Length (m)' },
    { type: 'String', propValue: 'crutchDiameter_mm', propDisplay: 'Crutch dia. (mm)' },
    { type: 'String', propValue: 'cableOD_mm', propDisplay: 'Cable OD (mm)' },
    { type: 'String', propValue: 'numberOfCablesOut', propDisplay: 'Num. Cables' },
    { type: 'String', propValue: 'yearOfManufacture', propDisplay: 'Year of Manufacture' },
    { type: 'String', propValue: 'model', propDisplay: 'Model' },
    { type: 'String', propValue: 'powerFactor', propDisplay: 'Power Factor' },
    { type: 'Select', propValue: 'assetLocation', propDisplay: 'Asset Location', required: true },
    { type: 'String', propValue: 'numberOfPoles', propDisplay: 'Number of Poles' },
    { type: 'String', propValue: 'frame', propDisplay: 'Frame' },
    { type: 'NumberPair', propValue: ['gpsLat', 'gpsLong'], propDisplay: 'GPS coordinates', propLabels: ['Lat', 'Lng'], required: true },
    { type: 'Select', propValue: 'insulationClass', propDisplay: 'Insulation Class' },
    { type: 'Select', propValue: 'windingType', propDisplay: 'Winding Type' },
    { type: 'Select', propValue: 'phaseType', propDisplay: 'Phase Type' },
    { type: 'Select', propValue: 'vsdType', propDisplay: 'VSD Type' },
    { type: 'Select', propValue: 'atlasPdTrendType', propDisplay: 'Atlas PD Trend Type' },
    { type: 'Select', propValue: 'datumProcessingMethod', propDisplay: 'Datum Processing Method' },
    { type: 'String', propValue: 'pointOfAttachmentLabelNumber', propDisplay: 'PoA' },
    { type: 'Select', propValue: 'processingMaterial', propDisplay: 'Processing Material' },
    { type: 'String', propValue: 'service_MAC_BAC_Steam', propDisplay: 'Service MAC BAC Steam' },
    { type: 'Select', propValue: 'typeSynchronousInduction', propDisplay: 'Type Synchronous Induction' },
    { type: 'Select', propValue: 'sensorStatus', propDisplay: 'Sensor Status' },
];

export const multiAssetEditForm: Array<IFormField> = [
    {
        type: 'Select', propValue: 'assetType', propDisplay: 'Asset Type', options: [], required: true
    },
    {
        type: 'Select', propValue: 'assetSubType', propDisplay: 'Asset Sub Type', options: [], required: true
    },
    { type: 'String', propValue: 'manufacturer', propDisplay: 'Manufacturer' },
    { type: 'Select', propValue: 'supplyFrequency', propDisplay: 'Supply Frequency', required: true },
    { type: 'String', propValue: 'kva', propDisplay: 'KVA' },
    { type: 'String', propValue: 'cableLengthMeters', propDisplay: 'Cable Length (m)' },
    { type: 'String', propValue: 'cableLengthMeters', propDisplay: 'Cable Length (m)' },
    { type: 'String', propValue: 'crutchDiameter_mm', propDisplay: 'Crutch dia. (mm)' },
    { type: 'String', propValue: 'cableOD_mm', propDisplay: 'Cable OD (mm)' },
    { type: 'String', propValue: 'numberOfCablesOut', propDisplay: 'Num. Cables' },
    { type: 'String', propValue: 'yearOfManufacture', propDisplay: 'Year of Manufacture' },
    { type: 'String', propValue: 'model', propDisplay: 'Model' },
    { type: 'String', propValue: 'powerFactor', propDisplay: 'Power Factor' },
    { type: 'Select', propValue: 'assetLocation', propDisplay: 'Asset Location', required: true },
    { type: 'String', propValue: 'numberOfPoles', propDisplay: 'Number of Poles' },
    { type: 'String', propValue: 'frame', propDisplay: 'Frame' },
    { type: 'NumberPair', propValue: ['gpsLat', 'gpsLong'], propDisplay: 'GPS coordinates', propLabels: ['Lat', 'Lng'], required: true },
    { type: 'Select', propValue: 'insulationClass', propDisplay: 'Insulation Class' },
    { type: 'Select', propValue: 'windingType', propDisplay: 'Winding Type' },
    { type: 'Select', propValue: 'phaseType', propDisplay: 'Phase Type' },
    { type: 'Select', propValue: 'vsdType', propDisplay: 'VSD Type' },
    { type: 'Select', propValue: 'atlasPdTrendType', propDisplay: 'Atlas PD Trend Type' },
    { type: 'Select', propValue: 'datumProcessingMethod', propDisplay: 'Datum Processing Method' },
    { type: 'String', propValue: 'pointOfAttachmentLabelNumber', propDisplay: 'PoA' },
    { type: 'Select', propValue: 'processingMaterial', propDisplay: 'Processing Material' },
    { type: 'String', propValue: 'service_MAC_BAC_Steam', propDisplay: 'Service MAC BAC Steam' },
    { type: 'Select', propValue: 'typeSynchronousInduction', propDisplay: 'Type Synchronous Induction' },
    { type: 'Select', propValue: 'sensorStatus', propDisplay: 'Sensor Status' },
];

export const companyEditForm: Array<IFormField> = [
    { type: 'String', propValue: 'name', propDisplay: 'Name', required: true },
    { type: 'String', propValue: 'address', propDisplay: 'Address' },
    { type: 'String', propValue: 'addressZIP', propDisplay: 'Address ZIP' },
    { type: 'String', propValue: 'companyIndex', propDisplay: 'Company Index' },
    { type: 'Select', propValue: 'companyRole', propDisplay: 'Company Role', required: true },
    { type: 'String', propValue: 'country', propDisplay: 'Country' },
    { type: 'NumberPair', propValue: ['gpsLat', 'gpsLong'], propDisplay: 'GPS coordinates', propLabels: ['Lat', 'Lng'], required: true },
    { type: 'String', propValue: 'logoURL', propDisplay: 'Logo URL' },
    { type: 'String', propValue: 'monetaryCurrency', propDisplay: 'Monetary Currency' },
    { type: 'String', propValue: 'phoneNumber', propDisplay: 'Phone Number' },
    { type: 'String', propValue: 'state', propDisplay: 'State' },
    { type: 'MultiSelect', propValue: 'projects', propDisplay: 'Projects', options: [] },
    { type: 'String', propValue: 'taxReferenceID', propDisplay: 'Tax Reference ID' },
    { type: 'String', propValue: 'website', propDisplay: 'Website' },
    { type: 'MultiSelect', propValue: 'users', propDisplay: 'Users', options: [] },
];

export const deviceEditForm: Array<IFormField> = [
    {
        type: 'Select', propValue: 'productCode', propDisplay: 'Product Code', required: true, options: [
            { name: 'Unknown', value: "UNKNOWN" },
            { name: '4 channel monitor atex', value: "KRONOS_4_CHANNEL_MONITOR_ATEX" },
            { name: '6 channel monitor atex', value: "KRONOS_6_CHANNEL_MONITOR_ATEX" },
            { name: '24 channel monitor', value: "KRONOS_24_CHANNEL_MONITOR" },
            { name: '24 channel monitor with linearplex', value: "KRONOS_24_CHANNEL_MONITOR_WITH_LINEARPLEX" },
            { name: '6 channel monitor', value: "KRONOS_6_CHANNEL_MONITOR" },
            { name: '6 channel monitor 9u', value: "KRONOS_6_CHANNEL_MONITOR_9U" },
            { name: 'Spot tester', value: "KRONOS_SPOT_TESTER" },
            { name: 'Spot tester without laptop', value: "KRONOS_SPOT_TESTER_WITHOUT_LAPTOP" },
            { name: 'Spot tester online and offline testing', value: "KRONOS_SPOT_TESTER_ONLINE_AND_OFFLINE_TESTING" },
            { name: 'Pr6104 kronos atom', value: "PR6104_KRONOS_ATOM" }
        ]
    },
    { type: 'String', propValue: 'serialNumber', propDisplay: 'Serial Number', required: true },
    {
        type: 'Select', propValue: 'productionStatus', propDisplay: 'Production Status', required: true, options: [
            {
                name: 'Comissioned', value: "EProductionStatus_COMMISSIONED"
            },
            { name: 'In test', value: "EProductionStatus_IN_TEST" },
            { name: 'Shipped', value: "EProductionStatus_SHIPPED" },
            { name: 'Installed', value: "EProductionStatus_INSTALLED" },
            { name: 'Complete', value: "EProductionStatus_COMPLETE" },
            { name: 'Unknown', value: "EProductionStatus_UNKNOWN" },
        ]
    },
    { type: 'NumberPair', propValue: ['latitude', 'longitude'], propDisplay: 'GPS coordinates', propLabels: ['Lat', 'Lng'], required: true },
    { type: 'Checkbox', propValue: 'testRig', propDisplay: 'Test rig' },
    { type: 'Checkbox', propValue: 'comissioned', propDisplay: 'Comissioned' },
    {
        type: 'Select', propValue: 'deploymentStatus', propDisplay: 'Deployment Status', required: true, options: [
            {
                name: 'Working', value: "WORKING"
            },
            { name: 'Undefined fault', value: "FAULT_UNDEFINED" },
            { name: 'Defined fault', value: "FAULT_DEFINED" },
            { name: 'Unknown', value: "DEPLOYMENTSTATUSUNKNOWN" },
        ]
    },
    { type: 'String', propValue: 'calibrationUsername', propDisplay: 'Calibration user name' },
    { type: 'Date', propValue: 'calibrationDate', propDisplay: 'Calibration date', required: true },
    { type: 'Date', propValue: 'calibrationDueDate', propDisplay: 'Calibration due date', required: true },
    { type: 'String', propValue: 'ipAddress', propDisplay: 'IP Address' },
    { type: 'String', propValue: 'teamViewerAddress', propDisplay: 'Team viewer address' },
    { type: 'String', propValue: 'teamViewerPassword', propDisplay: 'Team viewer password' },
    { type: 'String', propValue: 'modemDetails', propDisplay: 'Modem details' },
    { type: 'String', propValue: 'notes', propDisplay: 'Notes' },
    { type: 'Select', propValue: 'salesOrderUuid', propDisplay: 'Sales order', required: true, options: [] },
];

export const siteEditForm: Array<IFormField> = [
    { type: 'String', propValue: 'name', propDisplay: 'Name', required: true },
    { type: 'Select', propValue: 'companyUuid', propDisplay: 'Company', required: true },
    { type: 'String', propValue: 'country', propDisplay: 'Country' },
    { type: 'NumberPair', propValue: ['gpsLat', 'gpsLong'], propDisplay: 'GPS coordinates', propLabels: ['Lat', 'Lng'], required: true },
    { type: 'String', propValue: 'logoURL', propDisplay: 'Logo URL' },
    { type: 'String', propValue: 'website', propDisplay: 'Website' },
    { type: 'String', propValue: 'phoneNumber', propDisplay: 'Phone Number' },
    { type: 'String', propValue: 'state', propDisplay: 'State' },
];

export const subSiteEditForm: Array<IFormField> = [
    { type: 'String', propValue: 'name', propDisplay: 'Name', required: true },
    { type: 'Select', propValue: 'companyUuid', propDisplay: 'Company', required: true },
    { type: 'NumberPair', propValue: ['gpsLat', 'gpsLong'], propDisplay: 'GPS coordinates', propLabels: ['Lat', 'Lng'], required: true },
    { type: 'String', propValue: 'logoURL', propDisplay: 'Logo URL' },
    { type: 'Number', propValue: 'power_kW', propDisplay: 'Power (kW)' },
    { type: 'Select', propValue: 'siteUuid', propDisplay: 'Site', required: true },
    { type: 'Number', propValue: 'voltageIn_kV', propDisplay: 'Voltage In (kV)' },
    { type: 'Number', propValue: 'voltageOut_kV', propDisplay: 'Voltage Out (kV)' }
];

export const userEditForm: Array<IFormField> = [
    { type: 'String', propValue: 'firstName', propDisplay: 'Forename', required: true },
    { type: 'String', propValue: 'lastName', propDisplay: 'Surname', required: true },
    { type: 'String', propValue: 'emailAddress', propDisplay: 'Email', required: true },
    { type: 'String', propValue: 'phoneNumber', propDisplay: 'Phone', required: true },
    { type: 'MultiSelect', propValue: 'userPermissions', propDisplay: 'Permissions', required: true },
    { type: 'MultiSelect', propValue: 'userGroups', propDisplay: 'Groups', required: true },
    { type: 'MultiSelect', propValue: 'userRoles', propDisplay: 'Roles', required: true },
    { type: 'MultiSelect', propValue: 'companies', propDisplay: 'Companies', required: true },
    { type: 'MultiSelect', propValue: 'projects', propDisplay: 'Projects', required: true },
];


export const projectEditForm: Array<IFormField> = [
    {
        type: 'String',
        propValue: 'name',
        propDisplay: 'Name',
        required: true,
    }, {
        type: 'Checkbox',
        propValue: 'isActive',
        propDisplay: 'Is active',
    }, {
        type: 'Date',
        propValue: 'startDate',
        propDisplay: 'Start date',
    }, {
        type: 'Date',
        propValue: 'endDate',
        propDisplay: 'End date',
    }, {
        type: 'Checkbox',
        propValue: 'notifications',
        propDisplay: 'Notifications',
    }, {
        type: 'String',
        propValue: 'notificationTime',
        propDisplay: 'Notification time',
        required: true,
    }, {
        type: 'MultiSelectPair',
        propValue: 'companies',
        propDisplay: 'Companies',
        options: [],
    }, { type: 'MultiSelectPair', propValue: 'assets', propDisplay: 'Assets', options: [] },
    {
        type: 'MultiSelectPair', propValue: 'userGroups', propDisplay: 'User groups', options: [],
    }, {
        type: 'MultiSelectPair', propValue: 'users', propDisplay: 'Users', options: []
    }
]

export const userGroupEditForm: Array<IFormField> = [
    {
        type: 'String',
        propValue: 'name',
        propDisplay: 'Name',
        required: true,
    },
    { type: 'UserSelect', required: true, propValue: 'users', propDisplay: 'Users', options: [] },
    { type: 'MultiSelectPair', required: true, propValue: 'projects', propDisplay: 'Projects', options: [] }
];

export const salesOrderEditForm: Array<IFormField> = [
    { type: 'String', propValue: 'salesOrderNumber', propDisplay: 'Order number', required: true },
    { type: 'String', propValue: 'salesOrderText', propDisplay: 'Order text', required: true },
    { type: 'String', propValue: 'salesOrderTitle', propDisplay: 'Order title', required: true },
    { type: 'String', propValue: 'salesOrderType', propDisplay: 'Order type', required: true },
    { type: 'String', propValue: 'clientPONumber', propDisplay: 'Client PO number', required: true },
    { type: 'MultiSelect', propValue: 'projects', propDisplay: 'Projects', options: [] },
    { type: 'Date', propValue: 'acknowledgedDate', propDisplay: 'Acknowledge date', required: true },
    { type: 'Date', propValue: 'clientPODate', propDisplay: 'Client PO date', required: true },
    { type: 'Date', propValue: 'dueDate', propDisplay: 'Start date', required: true },
];

export const mapEnumsToForm = (form: Array<IFormField>, searchParams: TSearchTemplate[]): Array<IFormField> => {
    const formOut = [...form];
    // very bad not to create formOut by mapping form
    searchParams.filter(s => s.type === 'enum').forEach(sp => {
        const field = formOut.find(f => f.propValue === sp.propName);
        if (field) {
            if (field.type === 'Select' && undefined !== sp.enumConstants) {
                field.options = [...sp.enumConstants].sort().map(v => ({ name: v.replaceAll('_', ' '), value: v }));
            } else {
                console.log('Field is not a select', field.propValue)
            }
        } else {
            console.log('Cant find form field for enum', sp.propName)
        }
    });
    return formOut;
}