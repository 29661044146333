import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch as useDispatch } from '../../hooks';
import CopyToClipboard from "react-copy-to-clipboard";

import { TEntityType, TImageDescriptor } from "../../store/Interfaces";
import { getApiEndpoint } from "../../api";
import {
    addToast,
} from "../../store/mainSlice";

interface IImageInfoProps {
    show: boolean;
    onClose: () => void;
    info: TImageDescriptor | null;
    entityType: TEntityType;
    uuid: string;
}

export const ImageInfo: React.FunctionComponent<IImageInfoProps> = ({ show, onClose, info, uuid, entityType }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getImageResourceURL = () => `${getApiEndpoint()}/${entityType}/${uuid}/Image/${info?.requestRelativeName}`;

    const onCopyAndClose = () => {
        dispatch(addToast(t('Image name n copied to clipboard', { name: getImageResourceURL() })));
        onClose();
    }

    return (<>{show ? <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div >

        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="grid grid-cols-[2fr_3fr]" >
                            <div className='font-bold'>{t('Request relative name')}</div>
                            <div>{info?.requestRelativeName}</div>
                            <div className='font-bold'>{t('Root relative name')}</div>
                            <div>{info?.rootRelativeName}</div>
                            <div className='font-bold'>{t('Size')}</div>
                            <div>{t('n Kb', { size: ((info?.sizeBytes ?? 0) / 1024).toFixed(2) })}</div>
                        </div>
                    </div>
                    <div className='inline-block w-full my-3'>
                        <button type="button" onClick={onClose} className="rounded-md text-white mr-2 float-right disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium">{t('Close')}</button>
                        <CopyToClipboard text={getImageResourceURL()} ><button type="button" onClick={onCopyAndClose} className="rounded-md text-white mr-2 float-right disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium">{t('Copy url and close')}</button></CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    </div> : null}</>)
}