import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import { setResultFilter, setEnableResultFilter, setLatestResultFilter } from '../../../store/mainSlice';
import { TSearchTemplate } from '../../../store/Interfaces';
import { Entries } from '../../../utils';
import { Filter } from '../Filter';
import { FilterBadges } from '../FilterBadges';

import {
    BASE_CLASS, NonBaseFilterClasses, TFilterClass, TFilterEnabled,
    TFilterDefinitions, TMatchType, TValueType, typeToMatches
} from "../FilterGroup";

export interface IBasicFiltersProps {
    filtersActive: () => Record<TFilterClass, TFilterEnabled>;
    filterEnabled: (filterClass: TFilterClass, item: string) => boolean;
    filterDefinitions: TFilterDefinitions;
    setFilterDefinitions: (filterDefinitions: TFilterDefinitions) => void;
    setFilterActive: (filterClass: TFilterClass, key: string, enabled: boolean) => void;
    filterChange: (filterClass: TFilterClass, propName: string, value: TValueType, match?: TMatchType) => void;
    getSearchTemplates: () => Array<TSearchTemplate>;
}

export const BasicFilters: React.FunctionComponent<IBasicFiltersProps> = ({ filtersActive, filterEnabled, filterDefinitions, setFilterDefinitions, setFilterActive, filterChange, getSearchTemplates }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { resultFilter, enableResultFilter, filterTemplate, latestResultFilter, tableEndPoints } = useSelector(store => store.main);

    const selectReportType: React.ChangeEventHandler<HTMLSelectElement> = e => dispatch(setResultFilter(tableEndPoints.find(te => te.name === e.target.value)));


    const propReportFilter = (sp: TSearchTemplate) =>
        NonBaseFilterClasses.every(filterClass => !sp.propName.startsWith(`${filterClass}.`));


    const getFilterClassFields = (filterClass: TFilterClass): TSearchTemplate[] => {
        const sp = getSearchTemplates();
        if (filterClass === BASE_CLASS) {
            return sp.filter(propReportFilter)
        }
        else {
            return sp.filter(spx => spx.propName.startsWith(`${filterClass}.`)).map(spx => ({ ...spx, propName: spx.propName.split('.')[1] }));
        }
    }

    const accNameMap = { asset: t('Asset'), result: t('Result'), report: t('Report'), site: t('Site'), subSite: t('Sub Site'), company: t('Company') }


    const getFilters = () => {
        const goodFilters: Record<TFilterClass, string[]> = { company: [], asset: ["assetType", "assetSubType", "manufacturer", "ratedPower_kW", "volage_kV"], site: ["country"], subSite: [], report: [], result: ["approverName", "testEngName"] };

        return (Object.entries(filterDefinitions) as Entries<TFilterDefinitions>).filter(([filterClass, filterValues]) => goodFilters[filterClass].length)
            .map(([filterClass, filterValues]) => {
                const title = accNameMap[filterClass] ?? '';
                // second filter pass
                const filterClassFields = getFilterClassFields(filterClass)
                    .filter(f => goodFilters[filterClass].includes(f.propName))
                    .sort((a, b) => goodFilters[filterClass].indexOf(a.propName) - goodFilters[filterClass].indexOf(b.propName));

                const filter = <Filter title={filterClass}
                    searchTerms={filterDefinitions[filterClass]}
                    filterFields={filterClassFields}
                    setFilterActive={(key, enabled) =>
                        setFilterActive(filterClass, key, enabled)
                    }
                    filterActive={(item: string) => filtersActive()[filterClass][item]}
                    filterEnabled={(item: string) =>
                        filterEnabled(filterClass, item)
                    }
                    typeToMatches={typeToMatches}
                    filterChange={(key, value, match) => filterChange(filterClass, key, value, match)} />
                return { title, filter };
            });
    }

    return (<div className='inline-block w-full mx-2' >
        {
            tableEndPoints.length === 0 ? <div className='inline-block'><span className='mr-2 font-bold' >Loading...</span><FontAwesomeIcon className='fa-spin text-hvpd-red-400' icon={solid('spinner')} /></div> :
                <>
                    <input type='checkbox' id='lastest-result-filter' checked={latestResultFilter} onChange={() => dispatch(setLatestResultFilter(!latestResultFilter))}
                        className='w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'></input>
                    <label htmlFor="lastest-result-filter" className="mx-1">{t('Show only latest results')}</label>

                    <input type='checkbox' id='result-type-filter' checked={enableResultFilter} onChange={() => dispatch(setEnableResultFilter(!enableResultFilter))}
                        className='w-3 h-3 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'></input>
                    <label htmlFor="result-type-filter" className="mx-1">{t('Filter by result type')}</label>
                    <select id='selectReportTypeFilter' name='selectReportTypeFilter' disabled={!enableResultFilter} className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border-1' value={resultFilter?.name} onChange={selectReportType}>
                        {!resultFilter ? <option>{`${tableEndPoints.length === 0 ? t('Loading...') : t('Please select...')} `}</option> : null}
                        {tableEndPoints.map(endPoint => <option key={endPoint.name} value={endPoint.name}>{endPoint.name}</option>)}
                    </select>
                </>
        }
        <div className='overflow-y-auto h-[93vh] mt-2 mr-2' >
            {filterTemplate ?
                <div>
                    <FilterBadges filtersActive={filtersActive} searchTemplates={getSearchTemplates()} filterDefinitions={filterDefinitions} deleteFilter={(filterClass, propName) => setFilterActive(filterClass, propName, false)} />
                </div> :
                <p className='text-center text-s text-gray-600'>{t('Please select a report from the right click menu on the report list')}</p>}
            <div className='mt-2 border-2'>
                <h2 className='text-center font-semibold text-lg text-hvpd-pickled-bluewood-600 mb-2'>{t('Select filters from the following options')}</h2>
                {getFilters().map(({ title, filter }) => (
                    <div key={title}>
                        <div className='text-hvpd-malibu-800 capitalize font-bold text-center mb-2'>{title}</div>
                        <div>{filter}</div>
                    </div>
                ))}
            </div>
        </div>
    </div>)


}
