import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { setUserGroups } from "../../../store/mainSlice";

import { addToast, addError, setProjects } from "../../../store/mainSlice";

import { getUsers, getUserGroups, createProject, updateProject, deleteProject } from "../../../api";
import { FormTemplate, IFormField, TEntity, FormContext } from "../FormTemplate";
import { Busy } from "../../Busy";
import { projectEditForm, mapEnumsToForm } from "../../../templates/formTemplates";
import { IProject, IAsset, ICompany, IUser } from "../../../store/Interfaces";

const TABLENAME = 'TableProject';

interface IProjectEditProps {
    project?: IProject;
    endEdit: () => void;
    clearFocus: () => void;
}

export const ProjectEdit: React.FunctionComponent<IProjectEditProps> = ({ project, endEdit, clearFocus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projects, companies, assets, token, tableDefinitionsComplete, userGroups } = useSelector(state => state.main);
    const [users, setUsers] = useState<IUser[]>([]);
    const [wipProject, setWipProject] = useState<IProject>();
    // const [dependantFields, setDependantFields] = useState<Array<{ name: string, value: string | Array<string | number> }>>([]);
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(projectEditForm);
    const [showUserWarning, setShowUserWarning] = useState(false);
    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        setWipProject({ ...project as IProject });
    }, [project]);

    useEffect(() => {
        const getUsersLocal = async () => {
            setBusy(true);
            try {
                getUsers(token).then(localUserList => {
                    setBusy(false);
                    if (Array.isArray(localUserList)) {
                        setUsers(localUserList);
                    }
                })
            } catch (e) {
                setBusy(false);
                dispatch(addError(t('Failed to get users')));
                console.error(t('Error'));
            }
        }
        if (token) {
            getUsersLocal();
        }
    }, [token]);

    useEffect(() => {
        const localGetUserGroups = async () => {
            setBusy(true);
            getUserGroups(token).then((res) => {
                dispatch(setUserGroups(res));
                setBusy(false);
            }).catch((err) => {
                setBusy(false);
                console.error(err);
            });
        };
        if (userGroups.length === 0 && token) {
            localGetUserGroups();
        }
    }, [token, userGroups]);

    const companySort = (a: ICompany, b: ICompany) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }

    const assetSort = (a: IAsset, b: IAsset) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }

    useEffect(() => {
        // if (tableDefinitionsComplete[TABLENAME]?.searchParams) {
        if (assets.length && companies.length && userGroups.length && users.length && wipProject) {
            let localFormWithOptions = projectEditForm;
            if (tableDefinitionsComplete[TABLENAME]?.searchParams) {
                localFormWithOptions = mapEnumsToForm(localFormWithOptions, tableDefinitionsComplete[TABLENAME]?.searchParams);
            }
            let availableAssets = assets.filter(asset => wipProject.companies.includes(asset.companyUuid));
            setFormWithOptions(formWithOptions.map(field => {
                if (field.propValue === 'assets') {
                    return { ...field, options: ([...availableAssets] ?? []).map(asset => ({ name: asset?.name ?? '', value: asset.uuid })) }
                }
                return field;
            }));

            localFormWithOptions = localFormWithOptions.map(field => {
                switch (field.propValue) {
                    case 'assets':
                        return {
                            ...field, options: ([...availableAssets] ?? []).sort(assetSort).map(asset => ({ name: asset?.name ?? '', value: asset.uuid }))
                        };
                    case 'companies':
                        return {
                            ...field, options: ([...companies] ?? []).sort(companySort).map(company => ({ name: company?.name ?? '', value: company.uuid }))
                        };
                    case 'userGroups':
                        return { ...field, options: ([...userGroups] ?? []).map(userGroup => ({ name: userGroup?.name ?? '', value: userGroup.uuid })) }
                    case 'users':
                        return { ...field, options: ([...users] ?? []).map(u => ({ name: u.emailAddress, value: u.uuid, entityValue: u })) }
                    default:
                        return field;
                }
            });
            setFormWithOptions(localFormWithOptions);
        }

        // }
    }, [assets, companies, userGroups, users, wipProject, tableDefinitionsComplete])

    const onMultiFieldChange = (field: string, value: Array<string | number>): Array<{ name: string, value: string | number | Array<string> | Array<number> }> => {
        if (field === 'companies') {
            const availableAssets = assets.filter(asset => value.includes(asset.companyUuid));
            setFormWithOptions(formWithOptions.map(field => {
                if (field.propValue === 'assets') {
                    return { ...field, options: ([...availableAssets] ?? []).map(asset => ({ name: asset?.name ?? '', value: asset.uuid })) }
                }
                return field;
            }));
            return [{ name: 'assets', value: [] }];
        } else if (field === 'users') {
            setShowUserWarning(value.length > 0);
        }
        return [];
    }

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createProject(token, entity as IProject).then((project) => {
                dispatch(addToast(t('Project created')));
                setBusy(false);
                setWipProject(project as IProject);
                dispatch(setProjects([...projects, project as IProject]));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            })
        } else {
            updateProject(token, entity as IProject).then((project) => {
                dispatch(addToast(t('Project updated')));
                setBusy(false);
                setWipProject(project as IProject);
                dispatch(setProjects(projects.map(p => p.uuid === project.uuid ? project as IProject : p)));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            });
        }
    }
    const onDelete = () => {
        setBusy(true);
        deleteProject(token, wipProject?.uuid as string).then(() => {
            dispatch(setProjects(projects.filter(p => p.uuid !== wipProject?.uuid)));
            clearFocus();
            dispatch(addToast(t('Project deleted')));
            setWipProject(undefined);
            setBusy(false);
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }

    return (
        <div className='m-2 overflow-auto max-h-full pb-4'>
            <div className="relative z-30 w-full h-full">
                {busy ? <Busy /> :

                    <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        {showUserWarning ? <div className='bg-red-200 p-2 text-red-800'>{t('Warning: Please add users via user groups wherever possible.')}</div> : false}
                        <FormTemplate entityName='Project' form={formWithOptions}
                            entity={wipProject as IProject} setForm={setFormWithOptions}
                            babysitMultiSelectFieldChange={onMultiFieldChange} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                    </FormContext.Provider>}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}
        </div >
    )
}