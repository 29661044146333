import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from "../FileUpload";
import { TEntityType } from "../../store/Interfaces";

interface IImageUploadProps {
    showModal: boolean;
    onClose: () => void;
    assetUuid: string;
    entityType: TEntityType;
    onError: (error: string) => void;
    onSuccessfulUpload: () => void;
}

export const ImageUpload: React.FunctionComponent<IImageUploadProps> = ({ showModal, onClose, assetUuid, entityType, onError, onSuccessfulUpload }) => {
    const { t } = useTranslation();
    return showModal ? (<div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <h5
                        className="text-xl font-medium bg-hvpd-pickled-bluewood-100 p-4 leading-normal text-white  dark:text-neutral-200"
                    >
                        {t('Image upload')}
                    </h5>
                    <div className='mx-2 max-h-[440px] overflow-y-auto'>
                        <FileUpload uuid={assetUuid} entityType={entityType} type='image' onError={onError} onSuccessfulUpload={onSuccessfulUpload} />
                    </div>
                    <div className='inline-block w-full my-1'>
                        <button type="button" onClick={onClose} className="rounded-md text-white mr-2 float-right disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium">{t('Close')}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>) : null;
}