import React from "react";
import { useTranslation } from "react-i18next";

import { UserSelectTable } from "./UserSelectTable";

import { IUser } from "../../../store/Interfaces";

interface IUserSelectProps {
    name: string;
    users: IUser[];
    selectedUsers: string[];
    setSelectedUsers: (selectedUsers: string[]) => void;
}

export const UserSelect: React.FunctionComponent<IUserSelectProps> = ({ name, users, selectedUsers, setSelectedUsers }) => {
    const { t } = useTranslation();
    const labelSelected = t('Selected n', { name });

    const setSelectedUsers1 = (selectedUsers: string[]) => {
        setSelectedUsers(users.filter(u => !selectedUsers.includes(u.uuid)).map(u => u.uuid));
    }

    return (
        <div className="w-full">
            {selectedUsers.length > 0 ?
                <div className="w-full border-t-2 border-hvpd-grey-900">
                    <div className='border-b-2 border-hvpd-grey-800'>
                        <label htmlFor={`${name}-existing`} className='text-sm font-semibold'>{labelSelected}</label><span className='text-sm pl-2'>({t('click to remove')})</span>
                    </div>
                    <UserSelectTable users={users} selectedUsers={users.filter(u => !selectedUsers.includes(u.uuid)).map(u => u.uuid)} setSelectedUsers={(row) => setSelectedUsers1(row)} />
                </div> : null}

            <div className='w-full  border-t-2 border-hvpd-grey-900'>
                <div className="flex flex-row h-fit pt-2 border-t-0 border-b-2 border-hvpd-grey-800">
                    <div><label htmlFor={`${name}-available`} className='text-sm font-semibold flex-1'>{t('Available n', { name })}</label><span className='text-sm pl-2'>({t('click to add')})</span></div>
                </div>
                <UserSelectTable users={users} selectedUsers={selectedUsers} setSelectedUsers={(row) => setSelectedUsers(row)} />
            </div>
        </div >
    )
}