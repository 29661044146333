import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Viewer } from "@toast-ui/react-editor";

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import {
    addError,
    addToast,
    setObservations,
} from "../../../store/mainSlice";
import { addObservation } from "../../../api";

import { Busy } from '../../Busy';
import { ResultRowTable } from "../ResultRowTable";

import { IObservation, TResultRow } from "../../../store/Interfaces";



interface IConfirmApplyObservationModalProps {
    onCancel: () => void;
    onConfirm: () => void;
    rows: TResultRow[]
}

export const ConfirmApplyObservationModal: React.FunctionComponent<IConfirmApplyObservationModalProps> = ({ onConfirm, onCancel, rows }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, clipBoardObservation, observations } = useSelector(state => state.main);
    const [busy, setBusy] = useState(false);
    const [observationsCreated, setObservationsCreated] = useState(false);
    const [rowConfirmed, setRowConfirmed] = useState<Record<string, boolean>>({});
    const [areYouSure, setAreYouSure] = useState(false);

    useEffect(() => {
        setAreYouSure(false);
    }, []);

    useEffect(() => {
        if (Object.values(rowConfirmed).every(v => !v)) {
            setAreYouSure(false);
        }
    }, [rowConfirmed]);

    useEffect(() => {
        if (clipBoardObservation == null) {
            onCancel();
        }
    }, [clipBoardObservation]);

    useEffect(() => {
        if (rows.length > 0) {
            setRowConfirmed(rows.reduce((acc, row) => ({ ...acc, [row.resultUuid]: false }), {} as Record<string, boolean>));
        }
    }, [rows]);

    const toggleAllConfirmed = () => {
        const countConfirmed = Object.values(rowConfirmed).filter(v => v).length;
        if (countConfirmed > rows.length / 2) {
            setRowConfirmed(rows.reduce((acc, row) => ({ ...acc, [row.resultUuid]: false }), {} as Record<string, boolean>));
        } else {
            setRowConfirmed(rows.reduce((acc, row) => ({ ...acc, [row.resultUuid]: true }), {} as Record<string, boolean>));
        }
    }

    const updateObservations = (observationsAdded: Array<IObservation>) => {
        if (clipBoardObservation) {
            if (clipBoardObservation.type === 'Result') {
                dispatch(setObservations({
                    ...observations,
                    Result: [...observations.Result, ...observationsAdded]
                }));
            } else if (clipBoardObservation.type === 'Asset') {
                dispatch(
                    setObservations({
                        ...observations,
                        Asset: [...observations.Asset, ...observationsAdded]
                    }));
            }
        }
    }
    const confirmApply = () => {
        if (areYouSure) {
            save();
        } else {
            setAreYouSure(true);
        }
    }
    const onBeginCancel = () => {
        if (areYouSure) {
            setAreYouSure(false);
        } else {
            onCancel();
        }
    }

    const save = async () => {
        setBusy(true);
        const workingRows = rows.filter(r => rowConfirmed[r.resultUuid]);
        try {
            if (clipBoardObservation) {
                const uuids: Array<[string, string]> = (clipBoardObservation.type === 'Asset') ?
                    workingRows.reduce((acc, row) => acc.some(acc => acc[0] === row.assetUuid) ? acc : [...acc, [row.assetUuid, row.assetName ?? '']], [] as Array<[string, string]>) :
                    workingRows.map(r => [r.resultUuid, r.siteName ?? '']);
                const workingObservation = { ...clipBoardObservation.observation }
                delete workingObservation.uuid;
                const promises: Array<Promise<IObservation>> = uuids.map(([uuid, name]) => {
                    return addObservation(token, { ...workingObservation, parentUuid: uuid, isValid: true }, clipBoardObservation.type).then((observation) => {
                        dispatch(addToast(`Observation applied to ${name}`));
                        return observation;
                    });
                });

                Promise.all(promises).then((observations: Array<IObservation>) => {
                    setBusy(false);
                    updateObservations(observations);
                    setObservationsCreated(true);
                }).catch(e => {
                    setBusy(false);
                    dispatch(addError(t('Error applying observation')));
                });
            }

        } catch (e) {
            console.error(e);
            setBusy(false);
            onCancel();
        }
    }
    const getApplyText = () => {
        const countApply = Object.values(rowConfirmed).filter(v => v).length;
        return countApply === 0 ? t(`Select ${clipBoardObservation?.type.toLowerCase()}s to apply observation to`) : countApply === 1 ? t(`Apply to 1 ${clipBoardObservation?.type.toLowerCase()}`) : t(`Apply to n ${clipBoardObservation?.type.toLowerCase()}s`, { count: countApply });
    }

    return (<div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-[48rem] w-[48rem]">
                    <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 bg-hvpd-pickled-bluewood-300  border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                            {areYouSure ? t('Confirm apply observation') : t('Apply observation')}
                        </h5>
                        <button type="button" onClick={onCancel} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label={t('Close')}>
                            <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                        </button>
                    </div>

                    <div className="bg-white px-4 pb-4 pt-3 sm:p-3 sm:pb-4">
                        {observationsCreated ? <div className='text-hvpd-pickled-bluewood-500 text-lg font-medium'>{t('Observations created')}</div> :
                            <>{busy ? <div className='min-w-[16rem]'><Busy /></div> :
                                areYouSure ?
                                    (<div className='text-hvpd-pickled-bluewood-500 text-lg font-medium'>
                                        <h3 className='font-bold text-lg text-hvpd-pickled-bluewood-500'>{t('Apply this observation:')}</h3>
                                        {clipBoardObservation ? <div className='border-2'>
                                            <h3 className="font-semibold px-2 py-2 text-lg text-hvpd-pickled-bluewood-300">{clipBoardObservation.observation.observationName}</h3>
                                            <div className="px-2 pb-2"><Viewer initialValue={clipBoardObservation.observation.stringValue} /></div>
                                        </div> : null}
                                        <h3 className='font-bold text-lg text-hvpd-pickled-bluewood-500'>{t('To the following selected items?')}</h3>
                                        <ResultRowTable rows={rows.filter(r => rowConfirmed[r.resultUuid])} rowConfirmed={rowConfirmed} setRowConfirmed={setRowConfirmed} />
                                    </div>)

                                    : (<ResultRowTable rows={rows} rowConfirmed={rowConfirmed} setRowConfirmed={setRowConfirmed} />)}</>}
                    </div>
                    <div className="rounded-md bg-gray-50 py-3 sm:flex sm:flex-row-reverse sm:px-3">
                        {observationsCreated ? <button type="button" disabled={busy} onClick={onConfirm} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-0">{t('Ok')}</button> :
                            <><button type="button" onClick={confirmApply} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium" disabled={Object.values(rowConfirmed).every(v => !v) || busy}>{getApplyText()}</button>
                                <button type="button" disabled={busy} onClick={onBeginCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{areYouSure ? t('Back to selection') : t('Close')}</button></>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div >)
}