import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ILinkedEntity, TResultType, TDocumentDescriptor, TTestType } from '../../store/Interfaces';
import { ReportCount } from './ReportCount';
import { NestedReport } from './NestedReport';

export interface ILinkedReportProps {
    token: string;
    linkedEntities?: ILinkedEntity[];
    resultEndpointName: TTestType;
}

export const LinkedReports: React.FunctionComponent<ILinkedReportProps> = ({ token, linkedEntities, resultEndpointName }) => {
    const { t } = useTranslation();
    type TEntityType = 'Company' | 'Asset' | 'Site' | 'SubSite';

    const [linkedReportCount, setLinkedReportCount] = useState<number>(0);
    const [showNestedReport, setShowNestedReport] = useState<Record<TEntityType, boolean>>({ Company: false, Asset: false, Site: false, SubSite: false });
    const [reportHistory, setReportHistory] = useState<TDocumentDescriptor[]>([]);
    const setReportCount = (count: number) => setLinkedReportCount((c) => c + count);

    useEffect(() => {
        setShowNestedReport({ Company: false, Asset: false, Site: false, SubSite: false });
        setReportHistory([]);
    }, []);

    const etToRt: Record<TEntityType, TResultType> = { 'Company': 'companies', 'Asset': 'assets', 'Site': 'sites', 'SubSite': 'sub-sites' }

    const invertShowNestedReport = (entityType: TEntityType, documents: TDocumentDescriptor[]) => {
        setReportHistory(documents);
        setShowNestedReport({ Company: false, Asset: false, Site: false, SubSite: false, [entityType]: !showNestedReport[entityType] });
    }

    const visibleReports = Object.entries(showNestedReport).filter(([entityType, shown]) => shown);
    let visibleReport: TEntityType;
    let visibleEntity: ILinkedEntity | undefined;
    if (visibleReports.length > 0) {
        visibleReport = visibleReports[0][0] as TEntityType;
        visibleEntity = linkedEntities?.find(entity => entity.type === visibleReport) ?? undefined;
    }

    return (
        <div>
            <div className="rounded-lg m-4 pl-4 bg-gray-100 bg-opacity-30 mx-auto">{t('Related reports')}</div>
            <table className='table-auto w-9/12 mx-4 border-slate-400 border'>
                <tbody>
                    {(linkedEntities ?? []).map(entity => (
                        <React.Fragment key={entity.uuid}>
                            <ReportCount uuid={entity.uuid} token={token} assetType={entity.type} assetName={entity.name} setReportCount={setReportCount} active={showNestedReport[entity.type as TEntityType]} onClickShowReport={(documents: TDocumentDescriptor[]) => invertShowNestedReport(entity.type as TEntityType, documents)} />
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {visibleEntity ? <NestedReport token={token} uuid={visibleEntity.uuid} entityType={etToRt[visibleEntity.type as TEntityType]} resultEndpointName={resultEndpointName} reportHistory={reportHistory} /> : null}
        </div>);
}
