import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setSites, setTableDefinitionComplete } from "../../../store/mainSlice";
import { ICompany, ISite } from "../../../store/Interfaces";
import { FormTemplate, TEntity, IFormField, FormContext } from "../FormTemplate";
import { createSite, updateSite, deleteSite } from "../../../api";
import { Busy } from "../../Busy";
import { siteEditForm, mapEnumsToForm } from "../../../templates/formTemplates";
const TABLENAME = 'TableSite';

interface ISiteEditProps {
    siteUuid?: string;
    endEdit: () => void;
    clearFocus: () => void;
}

export const SiteEdit: React.FunctionComponent<ISiteEditProps> = ({ siteUuid, endEdit, clearFocus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companies, sites, token, tableDefinitionsComplete } = useSelector(store => store.main);
    const [wipSite, setWipSite] = useState<ISite>();
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(siteEditForm);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);


    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);


    useEffect(() => {
        if (siteUuid && sites) {
            const s1 = sites.find(s => s.uuid === siteUuid);
            if (s1) {
                setWipSite(s1);
            }
        }
    }, [siteUuid, sites])

    const companySort = (a: ICompany, b: ICompany) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }

    useEffect(() => {
        if (companies && tableDefinitionsComplete[TABLENAME]?.searchParams) {
            let localFormWithOptions = mapEnumsToForm(siteEditForm, tableDefinitionsComplete[TABLENAME]?.searchParams);
            localFormWithOptions = localFormWithOptions.map(field => {
                if (field.propValue === 'companyUuid') {
                    return { ...field, options: ([...companies] ?? []).sort(companySort).map(company => ({ name: company?.name ?? '', value: company.uuid })) }
                } else {
                    return field
                }
            })
            setFormWithOptions(localFormWithOptions);
        }
    }, [companies, tableDefinitionsComplete])

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createSite(token, entity as ISite).then((site) => {
                dispatch(addToast(t('Site created')));
                setBusy(false);
                setWipSite(site);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setSites([...sites, site as ISite]));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            })
        } else {
            setBusy(true);
            updateSite(token, entity as ISite).then((site) => {
                dispatch(addToast(t('Site updated')));
                setBusy(false);
                setWipSite(site);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setSites(sites.map(s => s.uuid === site.uuid ? site as ISite : s)));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            });
        }
    }

    const onDelete = () => {
        setBusy(true);
        deleteSite(token, wipSite?.uuid as string).then(() => {
            dispatch(setSites(sites.filter(s => s.uuid !== wipSite?.uuid)));
            dispatch(setTableDefinitionComplete([TABLENAME, null]))
            clearFocus();
            dispatch(addToast(t('Site deleted')));
            setWipSite(undefined);
            setBusy(false);
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }


    return (
        <div className='m-2 overflow-auto h-full pb-4'>
            <div className="relative z-30 w-full h-full">
                {busy ? <Busy /> :
                    <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        <FormTemplate entityName='Site' form={formWithOptions} entity={wipSite as TEntity} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                    </FormContext.Provider>}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}
        </div >)
}