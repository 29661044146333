import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import {
    addToast,
    setTableDefinitionComplete,
} from "../../../store/mainSlice";
import {
    IColumnHeader, TResultRow, ISortColumn, TReportEntityGroup,
    TTableResultEndpoint, ITableDefinitionResult, TSlideOver
} from "../../../store/Interfaces";
import { getTableDefinition, getApiEndpoint } from "../../../api";
import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";
import { ContextMenu } from "../../ContextMenu";
import { getFilteredSortedResults } from "../utils";
import { TBounds } from "../../../Grid/Grid";

export interface IUserTableProps {
    tableEndPoint: TTableResultEndpoint | undefined;
    bounds: TBounds | null;
    boundsFiltered: 'map' | 'sld' | '';
    allowedAssets: string[];
    onClickRow: (e: React.MouseEvent, row?: TResultRow) => void;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    resultFilterText: string;
    setSlideOver: (slideOver: TSlideOver) => void;
    selectedEntity: TReportEntityGroup | undefined;
    refresh: number;
}

const TABLENAME = 'TableUser';

export const UserTable: React.FunctionComponent<IUserTableProps> = ({ onClickTableColumn, bounds, boundsFiltered, onClickRow, resultFilterText, sortColumn, selectedEntity }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, tableDefinitionsComplete } = useSelector(state => state.main);
    const [loading, setLoading] = useState(false);
    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});
    const [ac, setAc] = useState<AbortController>();
    const [menuShown, setMenuShown] = useState(false);
    const [contextPos, setContextPos] = useState({ x: 0, y: 0 });
    const [contextRow, setContextRow] = useState<TResultRow | undefined>(undefined);
    const tableResults: ITableDefinitionResult = tableDefinitionsComplete[TABLENAME] ? tableDefinitionsComplete[TABLENAME] : { columnHeaders: [], rows: [], searchParams: [] };

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        const getTable = async (ac: AbortController) => {
            setLoading(true);
            getTableDefinition(token, { name: TABLENAME, endpoint: `${getApiEndpoint()}/${TABLENAME}` }, [], true, ac).then((res) => {
                dispatch(setTableDefinitionComplete([TABLENAME, res]));
                setLoading(false);
            }).catch((err) => {
                console.error(err);
                setLoading(false);
            });
        };
        if (token && !tableDefinitionsComplete[TABLENAME] && ac) {
            getTable(ac);
        }
    }, [token, tableDefinitionsComplete, ac, dispatch]);

    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const handleContextMenu = (e: MouseEvent, row: TResultRow) => {
        e.preventDefault();
        setContextPos({ x: e.pageX, y: e.pageY });
        setContextRow(row);
        setMenuShown(true);
    };

    const onCopyToClipboard = () => {
        setMenuShown(false);
        dispatch(addToast(t('User GUID guid copied to clipboard', { guid: contextRow?.uuid.toString() ?? 'Error' })));
    }

    const filteredSortedResults: TResultRow[] = (undefined === tableResults || 0 === tableResults.rows.length) ? [] :
        getFilteredSortedResults(tableResults.rows, bounds, boundsFiltered, resultFilterText, [], sortColumn, tableResults.columnHeaders);

    return <>
        {loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> :
            <div className='usersTableClipper relative overflow-y-auto bg-hvpd-grey-50'>
                {filteredSortedResults.length > 0 ? <table className='table-auto min-w-full border-slate-400 border' >
                    <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                        <tr>
                            {tableResults.columnHeaders.map((colHeader, idx) => (
                                <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                            ))}
                        </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                        {filteredSortedResults.map((resultRow, idx) => (
                            <ResultsTableRow key={`row-${idx}`} columnHeaders={tableResults.columnHeaders} row={resultRow} handleContextMenu={handleContextMenu} onClickRow={onClickRow} expandedHeaders={expandedHeaders} selected={selectedEntity?.type === 'users' && selectedEntity?.uuid === resultRow.uuid} />
                        ))}
                    </tbody>
                </table> :
                    <div className='w-full flex mt-20'>
                        <div className='flex-grow text-center'><span>No results to display</span>
                            <button onClick={onClickRow} className='ms-2 flex-grow h-7 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md'>{t('Show panel')}</button>
                        </div>
                    </div>}
            </div>
        }
        <ContextMenu pos={contextPos} menuShown={menuShown} closeMenu={() => setMenuShown(false)} >
            <li className='w-full'><CopyToClipboard text={(contextRow?.uuid ?? '').toString() ?? 'Error'} ><button onClick={() => onCopyToClipboard()} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>{t('Copy user GUID to clipboard<')}</button></CopyToClipboard></li>
        </ContextMenu >
    </>
}