import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { getEntityImageList } from '../../api'
import { TEntityType, TImageDescriptorList } from "../../store/Interfaces";
import { Busy } from "../Busy";
import { ImagesList } from "./Images";

interface IImageCountProps {
    token: string;
    uuid: string;
    assetType: TEntityType;
    assetName: string;
    setImageCount(count: number): void;
    imageBlobs: Record<string, string>
    addImageBlob: (payload: { name: string, blob: string }) => void;
}

export const ImageCount: React.FunctionComponent<IImageCountProps> = ({ token, uuid, assetType, assetName, setImageCount, imageBlobs, addImageBlob }) => {
    const { t } = useTranslation();
    const [busy, setBusy] = useState<boolean>(false);
    const [images, setImages] = useState<TImageDescriptorList>([]);
    const [error, setError] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        setBusy(true);
        setExpanded(false);
        const ac = new AbortController();
        setImages([]);
        getEntityImageList(token, assetType, uuid, ac).then(images => {
            if (mounted) {
                setImages(images);
                setImageCount(images.length);
                setBusy(false);
            }
        }).catch(e => {
            if (mounted) {
                setError(true);
                setBusy(false);
            }
        });
        return () => {
            ac?.abort();
        }
    }, [token, uuid, assetType, assetName]);

    useEffect(() => {
        setMounted(true);
    }, []);

    const getEntityLabel = (assetType: TEntityType) => assetType !== 'SubSite' ? assetType : 'Substation';

    return (
        images.length > 0 ? (<>
            <tr className="text-sm outline outline-1 outline-sky-300 bg-sky-200">
                <td>{images.length > 0 ?
                    <button className="inline mr-1 text-hvpd-pickled-bluewood-500" onClick={() => setExpanded(!expanded)}>
                        <FontAwesomeIcon icon={expanded ? solid('chevron-circle-down') : solid('chevron-circle-right')} />
                    </button> : null}
                </td>

                <td className='font-semibold'>{getEntityLabel(assetType)}</td>
                <td>{assetName}
                    {busy ? <Busy size='xs' /> :
                        error ? <span>{t('Error')}</span> :
                            <span>{images.length === 1 ? t('1 image') : t('n images', { count: images.length })}</span>}
                </td>
            </tr>
            {expanded && images.length ?
                <tr><td colSpan={4}><ImagesList visible={true} imageListAsset={{ asset: uuid, images }} loadingImages={false} entityType={assetType} loading={false} setLoading={() => { }} refreshImageList={() => { }} imageBlobs={imageBlobs} addImageBlob={addImageBlob} /></td></tr> : null
            }
        </>) : null);

}