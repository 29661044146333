import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportGroupContext } from '../../ReportGroup/ReportGroupContext';

interface IImagePickerProps {
    onClickImage: (base64: string, metadata: string) => void
}

type TImageSize = 'thumb' | 'low' | 'medium' | 'high' | 'original';

export const ImagePicker: React.FunctionComponent<IImagePickerProps> = ({ onClickImage }) => {
    const { t } = useTranslation();
    const canvasRef = useRef<Array<HTMLCanvasElement>>([]);
    const [imageDimensions, setImageDimensions] = useState<Array<{ width: number, height: number }>>([]);
    const [images, setImages] = useState<HTMLImageElement[]>([]);
    const [imageNameBlobs, setImageNameBlobs] = useState<Array<[string, string]>>([]);
    const [imageSize, setImageSize] = useState<TImageSize>('medium');
    const [imageSelected, setImageSelected] = useState<number>(-1);
    const { imageListAsset, imageBlobs } = useContext(ReportGroupContext);

    const { images: imageList } = imageListAsset;

    const maxWidth = 240;
    const maxHeight = 240;

    useEffect(() => {
        const getImages = (): Array<[string, string]> => {
            return imageList.reduce((acc: Array<[string, string]>, image) => {
                return [...acc, [image.name, imageBlobs[image.name] ?? '']] as Array<[string, string]>;
            }, []);
        }
        const imagesX = getImages();
        if ((imageNameBlobs.length !== imagesX.length) ||
            imageNameBlobs.some((x, idx) => (x[0] !== imagesX[idx][0] || x[1] !== imagesX[idx][1]))) {
            setImageNameBlobs(imagesX)
        }
        setImageSelected(-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageList, imageBlobs]);

    /*   useEffect(() => {
           console.log('ImagePicker: init');
   
           return () => {
               console.log('ImagePicker: cleanup');
           }
       }, []);
   */
    useEffect(() => {
        if (imageNameBlobs.length > 0) {
            //          console.log(`ImagePicker: imageBlobs ${imageNameBlobs.length}`)
            canvasRef.current = canvasRef.current.slice(0, imageNameBlobs.length);
        }
    }, [imageNameBlobs]);

    const getImageOfSize = (size: TImageSize) => {
        const img = new Image();
        img.src = imageNameBlobs[imageSelected][1];
        const scale = {
            thumb: 0.33,
            low: 0.75,
            medium: 1,
            high: 1.33,
            original: 0
        }

        img.onload = () => {
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            const localMaxWidth = scale[size] === 0 ? width : maxWidth * scale[size];
            const localMaxHeight = scale[size] === 0 ? height : maxHeight * scale[size];
            if (width > height) {
                if (width > localMaxWidth) {
                    height *= localMaxWidth / width;
                    width = localMaxWidth;
                }
            } else {
                if (height > localMaxHeight) {
                    width *= localMaxHeight / height;
                    height = localMaxHeight;
                }
            }
            canvasRef.current[imageSelected].getContext('2d')?.drawImage(img, 0, 0, width, height);
            setImageDimensions((imageDimensions) => imageDimensions.map((dim, idx) => idx === imageSelected ? { width, height } : dim));
            setImages((images) => images.map((image, idx) => idx === imageSelected ? img : image));
        }
    }

    useEffect(() => {
        if (imageSelected !== -1) {
            getImageOfSize(imageSize);
        }
    }, [imageSelected, imageSize]);


    useEffect(() => {
        if (canvasRef.current.length > 0 && imageNameBlobs.length > 0 && imageSelected === -1) {
            setImageDimensions([]);
            setImages([]);
            //const localMaxWidth = maxWidth * (imageSize === 'low' ? 0.75 : (imageSize === 'medium' ? 1 : 1.33));
            //const localMaxHeight = maxHeight * (imageSize === 'low' ? 0.75 : (imageSize === 'medium' ? 1 : 1.33));

            imageNameBlobs.forEach(([name, blob], idx) => {
                const img = new Image();
                img.src = blob;
                img.onload = () => {
                    let width = img.naturalWidth;
                    let height = img.naturalHeight;
                    if (canvasRef.current) {
                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }
                        setImageDimensions((imageDimensions) => ([...imageDimensions, { width, height }]));
                        setImages((images) => ([...images, img]));
                    }
                }
                //   });
            });
        }
    }, [canvasRef.current.length, imageNameBlobs, imageSelected]);

    useEffect(() => {
        if (imageDimensions.length > 0 && imageDimensions.length === images.length) {
            images.forEach((img, idx) => {
                canvasRef.current[idx].getContext('2d')?.drawImage(img, 0, 0, imageDimensions[idx].width, imageDimensions[idx].height);
            })
        }
    }, [imageDimensions, images]);

    const insertImage = () => {
        const base64 = canvasRef.current[imageSelected].toDataURL('image/jpeg');
        onClickImage(base64, `${imageNameBlobs[imageSelected][0]},${Math.floor(imageDimensions[imageSelected].width)},${Math.floor(imageDimensions[imageSelected].height)}`);
        setImageSelected(-1);
    }

    const onClick = (idx: number) => {
        setImageSelected(idx);
    }

    const revertCanvas = () => {
        setImageSelected(-1);
        setTimeout(() => {
            images.forEach((image, idx) => {
                canvasRef.current[idx].getContext('2d')?.drawImage(image, 0, 0, imageDimensions[idx].width, imageDimensions[idx].height);
            })
        }, 1);
    }

    return (
        <>
            <div className="overflow-y-hidden">
                {imageSelected === -1 ? <div className='font-bold mb-1 text-lg text-hvpd-pickled-bluewood-400"'>{t('Click on image to select')}</div> :
                    <div className='font-bold mb-1 text-lg text-hvpd-pickled-bluewood-400"'>{t('Select image size')}</div>
                }
                <div className='max-w-12'>
                    {imageSelected === -1 ?
                        (<div className='max-h-[32rem] overflow-y-auto mb-1'>
                            {imageListAsset.images.map((image, idx) =>
                                <div key={image.name}>
                                    {imageSelected === idx || imageSelected === -1 ? <div className='max-w-12 p-2'>
                                        <canvas ref={el => { if (el) { canvasRef.current[idx] = el; } }} onClick={() => onClick(idx)} width={imageDimensions[idx]?.width ?? maxWidth} height={imageDimensions[idx]?.height ?? maxHeight}></canvas>
                                    </div> : null}
                                </div>)
                            }
                        </div>) :
                        (
                            imageListAsset.images.filter((_, idx) => idx === imageSelected).map((image) => (
                                <div key={image.name} className='max-h-[32rem] min-h-[20rem] overflow-y-auto grid grid-rows-[auto_1fr_auto]'>
                                    <div><label className='font-semibold mx-2 text-lg text-hvpd-pickled-bluewood-400' htmlFor={`imageSize-${imageSelected}`}>{t('Size')}</label>
                                        <select id={`imageSize-${imageSelected}`} name='imageSize' className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border' value={imageSize} onChange={e => setImageSize(e.target.value as TImageSize)}>
                                            <option value='thumb'>{t('Thumbnail')}</option>
                                            <option value='low'>{t('Low')}</option>
                                            <option value='medium'>{t('Medium')}</option>
                                            <option value='high'>{t('High')}</option>
                                            <option value='original'>{t('Original')}</option>
                                        </select>
                                    </div>

                                    <div className='p-2 max-w-12 max-h-[28rem] overflow-auto'>
                                        <canvas ref={el => { if (el) { canvasRef.current[imageSelected] = el; } }} onClick={() => onClick(imageSelected)} width={imageDimensions[imageSelected]?.width ?? maxWidth} height={imageDimensions[imageSelected]?.height ?? maxHeight}></canvas>
                                    </div>
                                    <div className='px-1'>
                                        <button className='mr-2 mb-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={insertImage}>{t('Insert')}</button>
                                        <button className='mr-2 mb-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={revertCanvas}>{t('Cancel')}</button>
                                    </div>
                                </div>
                            ))
                        )}
                </div>
            </div>
        </>)
}
