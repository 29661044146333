
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, TTab } from '../../Tabs';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import { TSearchTemplate, TValueName, TExtendedFilter } from '../../../store/Interfaces';
import { setExtendedFilter } from '../../../store/mainSlice';

import { BasicFilters, ExtendedFilters } from '..';

const FILTER_DEBOUNCE_TIMEOUT = 1000;
export const BASE_CLASS = 'result';

export type TFilterClass = 'company' | 'site' | 'asset' | 'report' | 'subSite' | 'result';
export type TFilterEnabled = Record<string, boolean>;
export type TValueType = boolean | string | number | [number, number] | Array<number> | Array<string>;
export type TMatchType = 'contains' | 'matches' | 'value' | 'min' | 'max' | 'minmax';
export type TFilterFragment = { propName: string, value: TValueType, matches: TMatchType };
export type TFilterDefinitions = Record<TFilterClass, Array<TFilterFragment>>;

export const typeToMatches: Record<TValueName, Array<TMatchType>> = {
    string: ['contains', 'matches'],
    enum: ['matches'],
    int: ['value', 'min', 'max', 'minmax'],
    long: ['value', 'min', 'max', 'minmax'],
    float: ['value', 'min', 'max', 'minmax'],
    double: ['value', 'min', 'max', 'minmax'],
    boolean: ['value'],
}

export const NonBaseFilterClasses: TFilterClass[] = ['company', 'site', 'asset', 'report', 'subSite'];

export const FilterGroup: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { assets, sites, subSites, companies, resultFilter, filterTemplate, tableDefinitions } = useSelector(store => store.main);

    const [currentTab, setCurrentTab] = useState<TTab['id']>('basic');
    const [filtersActive, setFiltersActive] = useState<Record<TFilterClass, TFilterEnabled>>({ company: {}, asset: {}, site: {}, subSite: {}, report: {}, result: {} })
    const [filterDefinitions, setFilterDefinitions] = useState<TFilterDefinitions>({ company: [], asset: [], site: [], subSite: [], report: [], result: [] });
    const [firstRun, setFirstRun] = useState(true);


    const propReportFilter = (sp: TSearchTemplate) =>
        NonBaseFilterClasses.every(filterClass => !sp.propName.startsWith(`${filterClass}.`));


    const tabList: TTab[] = [{ id: 'basic', name: t('Basic'), loading: false, disabled: false }, { id: 'extended', name: t('Extended'), loading: false, disabled: false }];

    const getSearchTemplates = (): Array<TSearchTemplate> => tableDefinitions ? tableDefinitions[resultFilter?.name ?? '']?.searchParams ?? [] : [];

    useEffect(() => {
        if (filterTemplate && assets.length && sites.length && companies.length && tableDefinitions) {
            const sp = getSearchTemplates();
            const localFilterValues: TFilterDefinitions = { company: [], asset: [], site: [], subSite: [], report: [], result: [] }

            const localAsset = assets.find(asset => asset.uuid === filterTemplate.assetUuid);
            if (localAsset) {
                localFilterValues.asset = convertAssetToFilterValues({ ...localAsset }, sp, 'asset');
            }
            const localSite = sites.find(site => site.uuid === filterTemplate.siteUuid);
            if (localSite) {
                localFilterValues.site = convertAssetToFilterValues({ ...localSite }, sp, 'site');
            }
            const localSubSite = subSites.find(subSite => subSite.uuid === filterTemplate.subSiteUuid);
            if (localSubSite) {
                localFilterValues.subSite = convertAssetToFilterValues({ ...localSubSite }, sp, 'subSite');
            }
            const localCompany = companies.find(company => company.uuid === filterTemplate.companyUuid);
            if (localCompany) {
                localFilterValues.company = convertAssetToFilterValues({ ...localCompany }, sp, 'company');
            }
            localFilterValues.report = convertAssetToFilterValues({ ...filterTemplate }, sp, 'report');

            localFilterValues.result = convertAssetToFilterValues({ ...filterTemplate }, sp, BASE_CLASS);

            setFilterDefinitions(localFilterValues);

            setFiltersActive({ company: {}, asset: {}, site: {}, subSite: {}, report: {}, result: {} });
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [filterTemplate, tableDefinitions, assets, sites, companies]);


    useEffect(() => {
        const getExportFilterFragment = (f: TFilterFragment, fClass: TFilterClass): TExtendedFilter => {
            const propName = fClass === BASE_CLASS ? f.propName : `${fClass}.${f.propName}`;
            if (f.matches === 'minmax') {
                return { propName, min: parseFloat((f.value as [number, number])[0].toString()), max: parseFloat((f.value as [number, number])[1].toString()) };
            }
            if (f.matches === 'contains' || f.matches === 'matches') {
                return { propName, [f.matches]: f.value };
            }
            if (f.matches === 'value' && typeof (f.value) === 'boolean') {
                return { propName, [f.matches]: f.value };
            }
            return { propName, [f.matches]: parseFloat(f.value.toString()) }
        }
        const json = (Object.keys(filterDefinitions) as TFilterClass[]).map((filterClass) => {
            const fx: Array<TFilterFragment> = filterDefinitions[filterClass];
            return fx.filter(fd => filtersActive[filterClass][fd.propName])
                .filter(fd => {
                    if (fd.matches === 'contains' && fd.value === '') {
                        return false;
                    }
                    if (fd.matches === 'matches' && Array.isArray(fd.value) && fd.value.length === 0) {
                        return false;
                    }
                    if (fd.matches === 'minmax' && Array.isArray(fd.value) && fd.value[0] === '' && fd.value[1] === '') {
                        return false;
                    }
                    if ((fd.matches === 'min' || fd.matches === 'max' || fd.matches === 'value') && fd.value === '') {
                        return false;
                    }
                    return true;
                })
                .map(f => getExportFilterFragment(f, filterClass))
        }).flat();
        if (firstRun) {
            setFirstRun(false);
        }
        const localTimeout = setTimeout(() => {
            dispatch(setExtendedFilter(json));
        }, FILTER_DEBOUNCE_TIMEOUT);
        return () => {
            clearTimeout(localTimeout);
        }
        // return JSON.stringify(json);
    }, [filtersActive, filterDefinitions, dispatch, firstRun]);

    const filterChange = (filterClass: TFilterClass, propName: string, value: TValueType, match?: TMatchType) => {
        // need to change the property value of the filter definition correctly
        // matches , min , max, contains, value
        const sp = getSearchTemplates();
        const newFilter = filterDefinitions[filterClass].find(f => f.propName === propName);
        if (newFilter) {
            const searchTemplatePropName = filterClass === BASE_CLASS ? propName : `${filterClass}.${propName}`;
            const type = (sp.find(spx => spx.propName === searchTemplatePropName)?.type ?? '');
            if (type === 'string') {
                if (match === 'matches' && !Array.isArray(value)) {
                    value = [value.toString()];
                } else if (match !== 'matches' && Array.isArray(value)) {
                    value = value[0];
                }
            }
            setFilterDefinitions((prevDefs => ({ ...prevDefs, [filterClass]: prevDefs[filterClass].map(f => f.propName === propName ? { ...f, value, matches: match ? match : f.matches } : f) })));
        }
    }

    const setFilterActive = (filterClass: TFilterClass, key: string, enabled: boolean) => {
        if (enabled) {
            setFiltersActive({ ...filtersActive, [filterClass]: { ...filtersActive[filterClass], [key]: enabled } });
        } else {
            const newFiltersActive = { ...filtersActive };
            delete newFiltersActive[filterClass][key];
            setFiltersActive(newFiltersActive);
        }
    }

    const convertAssetToFilterValues = (asset: Record<string, number | string | Array<string>>, searchParams: TSearchTemplate[], assetClass: TFilterClass): Array<TFilterFragment> => {
        let valsInit: TSearchTemplate[];
        if (assetClass === BASE_CLASS) {
            valsInit = searchParams.filter(propReportFilter);
        } else {
            valsInit = searchParams.filter(sp => sp.propName.startsWith(`${assetClass}.`));
        }

        const vals = valsInit.map(sp => {
            const propName = assetClass === BASE_CLASS ? sp.propName : sp.propName.substring(assetClass.length + 1)
            const matches = typeToMatches[sp.type][0];
            let value: TValueType = (asset[propName] ?? '') as TValueType;
            if (sp.type === 'string') {
                if (matches === 'matches' && !Array.isArray(value)) {
                    value = [value.toString()];
                } else if (matches === 'contains' && Array.isArray(value)) {
                    value = value[0];
                }
            } else if (sp.type === 'enum' && !Array.isArray(value)) {
                if (value.toString().length > 0) {
                    value = [value.toString()];
                } else {
                    value = [];
                }
            }
            const searchTerm: TFilterFragment = { propName, value, matches };
            return searchTerm;
        });
        return vals
    };

    const getFilterEnabled = (filterClass: TFilterClass, item: string) => {
        if (filterClass === 'asset' && item === 'assetSubType') {
            if (!filtersActive['asset']['assetType']) {
                return false;
            }
            const value = filterDefinitions['asset'].find(f => f.propName === 'assetType')?.value;
            if (Array.isArray(value) && value.length === 0) {
                return false;
            }
        }
        return true;
    }

    const getFiltersActive = (): Record<TFilterClass, TFilterEnabled> => {
        const localActive = { ...filtersActive };
        if (localActive.asset.assetSubType && !localActive.asset.assetType) {
            localActive.asset = { ...filtersActive.asset };
            delete localActive.asset.assetSubType;
        }
        return localActive;
    }

    return (<><Tabs tabList={tabList} currentTab={currentTab} setCurrentTab={
        setCurrentTab
    } />
        {currentTab === 'basic' ?
            <BasicFilters filtersActive={getFiltersActive} filterEnabled={getFilterEnabled} filterDefinitions={filterDefinitions} setFilterDefinitions={setFilterDefinitions} setFilterActive={setFilterActive} filterChange={filterChange} getSearchTemplates={getSearchTemplates} /> :
            <ExtendedFilters filtersActive={getFiltersActive} filterEnabled={getFilterEnabled} filterDefinitions={filterDefinitions} setFilterDefinitions={setFilterDefinitions} setFilterActive={setFilterActive} filterChange={filterChange} getSearchTemplates={getSearchTemplates} />}
    </>
    );
};

export default FilterGroup;
