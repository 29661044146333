import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

//import Markdown from "react-markdown";
//mport { Viewer } from "@toast-ui/react-editor";

import { IResultRecommendation } from "../../../store/Interfaces";

interface IViewReportModalProps {
    onClose: () => void;
    reportType: 'Result' | 'Asset'
    report: string | undefined;
    removeReport?: () => void;
}

export const ViewReportModal: React.FunctionComponent<IViewReportModalProps> = ({ onClose, reportType, report, removeReport }) => {
    const { t } = useTranslation();
    return <div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-4xl">
                    <div className="flex flex-shrink-0 items-center justify-between rounded-t-md bg-hvpd-pickled-bluewood-300  border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                            {t(`Clipboard ${reportType.toLowerCase()} report`)}
                        </h5>
                        <button type="button" onClick={onClose} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label={t('Close')}>
                            <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                        </button>
                    </div>
                    <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 py-1">
                        <div className="grid grid-cols-1">
                            <div>
                                {report}
                            </div>
                        </div>
                    </div>
                    <div className="rounded-md bg-gray-50 px-2 py-3 sm:flex sm:flex-row-reverse">
                        {removeReport ? <button type="button" onClick={removeReport} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{t('Remove report')}</button> : null}
                        <button type="button" onClick={onClose} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{t('Close')}</button>
                    </div>
                </div>
            </div>
        </div>
    </div >
}   