import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import 'react-tooltip/dist/react-tooltip.css';
import { useAppSelector as useSelector, useAppDispatch as useDispatch, useWindowSize } from '../../hooks';
import { addSldViewUUIDs, resetSldViewUUIDs, setAssetDiagram, addError } from '../../store/mainSlice';
import { IAsset, TDetailedAssetDiagram } from "../../store/Interfaces";
import { getAssetDiagrams } from "../../api";
import { SVGUrl } from "./SvgUrl";


export interface ISldProps {
    asset: IAsset | undefined;
    toggleMap: () => void;
    selectAssets: (uuids: string[]) => void;
    mapPanelExpanded: boolean;
    sldOnly?: boolean;
}

export const Sld: React.FunctionComponent<ISldProps> = ({ asset, toggleMap, selectAssets, mapPanelExpanded, sldOnly }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sldRef = useRef<HTMLDivElement>(null);

    const { token, assetDiagrams } = useSelector(store => store.main);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string>('');
    const [svgList, setSvgList] = useState<Array<{ uuid: string, element: JSX.Element }>>([]);
    const [detailedAssetDiagrams, setDetailedAssetDiagrams] = useState<TDetailedAssetDiagram>();

    const size = useWindowSize();


    const findResultInAssetDiagrams = (assetDiagrams: Record<string, TDetailedAssetDiagram>, assetUuid: string): TDetailedAssetDiagram | undefined => {
        let result: TDetailedAssetDiagram | undefined;
        if (assetDiagrams[assetUuid]) {
            return assetDiagrams[assetUuid];
        }
        Object.values(assetDiagrams).forEach((assetDiagram: TDetailedAssetDiagram) => {
            if (assetDiagram.assetDiagrams.some(ad => ad.assets.includes(assetUuid))) {
                result = assetDiagram;
                return result;
            }
        });
        return result;
    }

    useEffect(() => {
        const getResults = async () => {
            if (asset && asset.uuid && token) {
                try {
                    //let results = assetDiagrams[asset.uuid]
                    let results: TDetailedAssetDiagram | undefined = findResultInAssetDiagrams(assetDiagrams, asset.uuid);
                    if (!results) {
                        try {
                            results = await getAssetDiagrams(token, asset.uuid);
                            /* const sx = await fetch('./CP6S-Main.svg');
                             await sx.text().then((text) => {
                                 results!.assetDiagrams[0].imageData = text;
                             }); */
                            dispatch(setAssetDiagram({ uuid: asset.uuid, assetDiagram: results }));
                        } catch (error) {
                            dispatch(addError(t('Error getting asset diagrams')));
                        }
                    }
                    if (results) {
                        setDetailedAssetDiagrams(results);
                        dispatch(resetSldViewUUIDs());
                        results.assetDiagrams.forEach(ad => {
                            dispatch(addSldViewUUIDs({ fileName: ad.uuid, uuids: ad.assets }));
                        });
                    }
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                    setDetailedAssetDiagrams(undefined);
                    setLoading(false);
                }
            }
        };
        if (asset) {
            setLoading(true);
            setSelectedFile('');
            getResults();
        }
    }, [asset]);

    useEffect(() => {
        if (detailedAssetDiagrams?.assetDiagrams) {
            let localSvgList: Array<{ uuid: string, element: JSX.Element }> = [];
            if (asset) {
                if (selectedFile.length > 0 || detailedAssetDiagrams.assetDiagrams.length === 1) {
                    const assetDiagram = detailedAssetDiagrams.assetDiagrams.find(ad => ad.uuid === selectedFile) ?? detailedAssetDiagrams.assetDiagrams[0];
                    localSvgList = [{ uuid: assetDiagram.uuid, element: <SVGUrl key={selectedFile} zoom={true} width={sldRef.current ? sldRef.current.getBoundingClientRect().width : 500} assetDiagram={assetDiagram} assetUuid={asset.uuid} selectAssets={selectAssets} assetResults={detailedAssetDiagrams.assetResults ?? []} /> }];
                } else {
                    detailedAssetDiagrams.assetDiagrams.forEach((assetDiagram, index) => {
                        localSvgList.push({ uuid: assetDiagram.uuid, element: <SVGUrl zoom={false} onClick={() => setSelectedFile(assetDiagram.uuid)} assetDiagram={assetDiagram} assetUuid={asset.uuid} selectAssets={selectAssets} assetResults={detailedAssetDiagrams.assetResults ?? []} /> });
                    });
                }
            }
            setSvgList(localSvgList);
        }
    }, [detailedAssetDiagrams, selectedFile, asset, selectAssets, mapPanelExpanded, size]);

    const noAvailableSvds = () => asset && detailedAssetDiagrams?.assetDiagrams.length === 0;
    /*
        const noAvailableSvds = () => asset && undefined === assetDiagramForAsset[asset.uuid];
        const svgList: Array<{ uuid: string, element: JSX.Element }> = [];
        if (asset && assetDiagramForAsset[asset.uuid]) {
            const assetDiagram = detailedAssetDiagrams?.assetDiagrams.find(ad => ad.uuid === selectedFile) ?? detailedAssetDiagrams?.assetDiagrams[0];
     
            svgList.push({ uuid: asset.uuid, element: <SVGUrl key={asset.uuid} zoom={true} width={sldRef.current ? sldRef.current.getBoundingClientRect().width : 500} assetDiagram={assetDiagram} assetUuid={asset.uuid} selectAssets={selectAssets} assetResults={[]} /> });
        }
            */
    const sldClass = svgList.length <= 3 ? 'grid-cols-2' : 'grid-cols-3';

    return <div ref={sldRef}>
        <div className='mt-4 ml-4 absolute inline z-10'>
            <button onClick={toggleMap} className='text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium' title={t('Click to switch to map view')}>{sldOnly ? t('Close') : t('Map view')}</button>
            {!loading && selectedFile.length > 0 ?
                <div className='ml-1 inline'><button onClick={() => setSelectedFile('')} className='text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium' title={t('Click to select a SLD')} >{t('Back')}</button></div>
                : null}
        </div>
        {loading ? <div className='w-full flex justify-center absolute mt-48'>
            <FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} />
        </div>
            : <div className={`${svgList.length === 1 ? 'overflow-y-hidden' : 'overflow-y-auto'}`} style={{ maxHeight: 'calc(100vh - 112px)' }}>
                {!asset ? <div className='text-center mt-10 text-xl'>{t('No result selected')}</div> : null}
                {noAvailableSvds() ? <div className='mt-20 text-center'>{t('No SLDs found for this asset')}</div> :
                    svgList.length > 0 ?
                        <> {svgList.length === 1 ?
                            <>{svgList[0].element}</>
                            :
                            <>
                                <div className="p-4 border-1 w-full fixed text-right border-hvpd-grey-700 bg-hvpd-malibu-900 h-[64px]">
                                </div>
                                <div className={`grid ${sldClass} grid-flow-row bg-hvpd-grey-300 mt-[64px] mb-[80px]`}>
                                    {svgList.map(s =>
                                        <div key={s.uuid}>
                                            {s.element}
                                        </div>)
                                    }
                                </div>
                            </>
                        }
                        </>
                        : null
                }
            </div>
        }
        <div id='tooltip' className='fixed inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700'>Hello mum</div>
        <Tooltip anchorId="my-anchor-element" data-tooltip-content="hello world" data-tooltip-place="top" />
    </div >
}

//export const Sld = React.memo(SldRaw);