import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector } from '../../hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { IAsset, IResultHistory, TDocumentDescriptor } from '../../store/Interfaces';
import { downloadTrendReport } from '../../api';
import { TrendGraph } from "./TrendGraph";

interface TrendsProps {
    asset: IAsset | undefined;
    resultEndpointName: string;
    visible: boolean;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    expanded: boolean;
    resultHistory: { uuid: string, history: Array<IResultHistory> | Array<TDocumentDescriptor> };
    setResultHistoryIndex: (index: number) => void;
}

export const Trends: React.FunctionComponent<TrendsProps> = ({ asset, resultEndpointName, visible, loading, setLoading, expanded, resultHistory, setResultHistoryIndex }) => {
    const { t } = useTranslation();
    const { token, sites, companies } = useSelector(store => store.main);
    const containerRef = useRef<HTMLDivElement>(null);
    const [reportError, setReportError] = useState('');

    const downloadTrendLocal = () => {
        if (asset) {
            downloadTrendReport(token, asset, sites, companies, resultEndpointName).then(res => {
                if (!res) {
                    setReportError(t('Failed to collect report for this item'));
                }
            });
        }
    }

    return (visible ? <div className="overflow-y-auto h-full">
        <div ref={containerRef}>
            <>
                {reportError ?
                    <div className='min-w-80 px-7 py-4 mx-2 block bg-red-200 border-red-300 rounded-md'>{reportError}
                        <button className='float-right text-red-700 hover:text-red-800 hover:border-red-900' onClick={() => setReportError('')}><FontAwesomeIcon className='' icon={solid('xmark')} /></button>
                    </div> : null}

                {loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> : null}
                {!loading ? <div className="overflow-hidden h-[calc(100vh-46px)] grid grid-rows-[1fr_50px]">
                    <div className='px-6 inline-block overflow-auto' >
                        <TrendGraph containerElement={containerRef.current} assetUuid={asset?.uuid ?? ''} expanded={expanded} resultHistory={resultHistory} setResultHistoryIndex={setResultHistoryIndex} /></div >
                    <div><button onClick={downloadTrendLocal} className='bg-sky-300 text-sm rounded-md px-2 py-1 mt-3 mx-2 text-black hover:bg-sky-400 border-sky-400'>{t('Download')}</button></div>
                </div > : null}
            </>
        </div>
    </div> : null
    );
}

