import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';
import {
    addError,
    addToast,
    setTableResult,
} from "../../../store/mainSlice";
import { changeResultApprovalStatus, getTableDefinition } from "../../../api";

import { Busy } from '../../Busy';

import { ResultRowTable } from "../ResultRowTable";
import { TResultRow, TTestType } from "../../../store/Interfaces";

interface IConfirmApproveResultsModalProps {
    onCancel: () => void;
    onConfirm: () => void;
    rows: TResultRow[];
    resultEndpointName: TTestType;

}

export const ConfirmApproveResultsModal: React.FunctionComponent<IConfirmApproveResultsModalProps> = ({ onConfirm, onCancel, rows, resultEndpointName }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, tableEndPoints, extendedFilter } = useSelector(state => state.main);
    const [busy, setBusy] = useState(false);
    const [resultsApproved, setResultsApproved] = useState(false);
    const [rowConfirmed, setRowConfirmed] = useState<Record<string, boolean>>({});
    const [areYouSure, setAreYouSure] = useState(false);

    useEffect(() => {
        setAreYouSure(false);
    }, []);

    useEffect(() => {
        if (Object.values(rowConfirmed).every(v => !v)) {
            setAreYouSure(false);
        }
    }, [rowConfirmed]);

    useEffect(() => {
        if (rows.length > 0) {
            setRowConfirmed(rows.reduce((acc, row) => ({ ...acc, [row.resultUuid]: false }), {} as Record<string, boolean>));
        }
    }, [rows]);

    const refreshResultsTable = async () => {
        const tableEndPoint = tableEndPoints.find(t => t.name === resultEndpointName);
        if (tableEndPoint) {
            setBusy(true);
            try {
                // we don't want to abort this api call if the component is destroyed
                const tableResult = await getTableDefinition(token, tableEndPoint, extendedFilter, true, new AbortController());
                dispatch(setTableResult([resultEndpointName, { rows: tableResult.rows }]));
            } catch (e) {
                dispatch(addError(t('Error refreshing results table')));
                console.error(e);
            } finally {
                setBusy(false);
            }
        }
    }


    const save = async () => {
        setBusy(true);
        const workingRows = rows.filter(r => rowConfirmed[r.resultUuid]);
        try {
            const promises: Array<Promise<boolean>> = workingRows.map(async r => {
                return changeResultApprovalStatus(token, r.resultUuid, 'Approve').then((result) => {
                    dispatch(addToast(t('Result n approved', { name: `${r.siteName} - ${r.assetName}` })));
                    return true;
                });
            });
            Promise.all(promises).then(() => {
                setBusy(false);
                refreshResultsTable()
                setResultsApproved(true);
            });
        } catch (e) {
            dispatch(addError(t('Error refreshing results table')));
            setBusy(false);
        }
    }


    const approveResults = () => {
        if (areYouSure) {
            save();
        } else {
            setAreYouSure(true);
        }
    }
    const confirmApply = () => {
        if (areYouSure) {
            save();
        } else {
            setAreYouSure(true);
        }
    }
    const onBeginCancel = () => {
        if (areYouSure) {
            setAreYouSure(false);
        } else {
            onCancel();
        }
    }



    return (<div className="relative z-[3000]" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ></div>

        <div className="fixed inset-0 z-[3000] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-w-[48rem] w-[48rem]">
                    <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 bg-hvpd-pickled-bluewood-300  border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                        <h5 className="text-xl font-medium leading-normal text-hvpd-grey-50 dark:text-neutral-200" id="exampleModalFirstLabel">
                            {areYouSure ? t('Confirm approve result') : t('Approve results')}
                        </h5>
                        <button type="button" onClick={onCancel} className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none text-hvpd-grey-50" data-te-modal-dismiss="" aria-label={t('Close')}>
                            <FontAwesomeIcon className="h-6 w-6" size='sm' icon={solid('xmark')} />
                        </button>
                    </div>

                    <div className="bg-white px-4 pb-4 pt-3 sm:p-3 sm:pb-4">
                        {resultsApproved ? <div className='text-hvpd-pickled-bluewood-500 text-lg font-medium'>{t('Results approved')}</div> :
                            <>{busy ? <div className='min-w-[16rem]'><Busy /></div> :
                                areYouSure ?
                                    (<div className='text-hvpd-pickled-bluewood-500 text-lg font-medium'>
                                        <h3 className='font-bold text-lg text-hvpd-pickled-bluewood-500'>{t('Approve results for the following selected items?')}</h3>
                                        <ResultRowTable rows={rows.filter(r => rowConfirmed[r.resultUuid])} rowConfirmed={rowConfirmed} setRowConfirmed={setRowConfirmed} />
                                    </div>)

                                    : (<ResultRowTable rows={rows} rowConfirmed={rowConfirmed} setRowConfirmed={setRowConfirmed} />)}</>}
                    </div>
                    <div className="rounded-md bg-gray-50 py-3 sm:flex sm:flex-row-reverse sm:px-3">
                        {resultsApproved ? <button type="button" disabled={busy} onClick={onConfirm} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-0">{t('Ok')}</button> :
                            <><button type="button" onClick={confirmApply} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium" disabled={Object.values(rowConfirmed).every(v => !v) || busy}>{t('Approve n results', { count: Object.values(rowConfirmed).filter(e => e).length })}</button>
                                <button type="button" disabled={busy} onClick={onBeginCancel} className="rounded-md text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium mr-1">{areYouSure ? t('Back to selection') : t('Close')}</button></>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div >)
}