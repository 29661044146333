import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setTableDefinitionComplete } from "../../../store/mainSlice";
import { IUser, userRoles, userPermissions, ICompany, IBaseProject } from "../../../store/Interfaces";
import { FormTemplate, TEntity, IFormField, FormContext } from "../FormTemplate";
import { ConfirmationModal } from "../../ConfirmationModal";
import { getUser, createUser, updateUser } from "../../../api";
import { Busy } from "../../Busy";
import { userEditForm, mapEnumsToForm } from "../../../templates/formTemplates";
import { hasChanged, createEntity } from "../FormTemplate/formUtils";
import { deepEqual } from '../../../utils';

const TABLENAME = 'TableUser';

interface IUserEditProps {
    userUuid?: string;
    endEdit: () => void;
    clearFocus: () => void;
}

export const UserEdit: React.FunctionComponent<IUserEditProps> = ({ userUuid, endEdit, clearFocus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, companies, projects, userGroups, tableDefinitionsComplete } = useSelector(state => state.main);
    const [entityChanged, setEntityChanged] = useState(false);
    const [busy, setBusy] = useState(false);
    const [wipUser, setWipUser] = useState<IUser>({} as IUser);
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(userEditForm);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmCreateFromExisting, setConfirmCreateFromExisting] = useState(false);

    const [entityStack, setEntityStack] = useState<Array<TEntity>>([]);

    useEffect(() => {
        if (entityStack.length === 0 || !deepEqual(entityStack[entityStack.length - 1], wipEntity)) {
            const newStack = [...entityStack, { ...wipEntity }];
            if (newStack.length > 20) {
                setEntityStack(newStack.slice(1));
            }
            else {
                setEntityStack(newStack);
            }
        }
    }, [wipEntity]);

    const onUndo = () => {
        setWipEntity(entityStack[entityStack.length - 2]);
        setEntityStack(entityStack.slice(0, entityStack.length - 1));
    }

    const companySort = (a: ICompany, b: ICompany) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }
    const projectSort = (a: IBaseProject, b: IBaseProject) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }

    useEffect(() => {
        const myAbortController = new AbortController();
        if (token && userUuid) {
            setBusy(true);
            setEntityStack([]);

            getUser(token, userUuid, myAbortController).then(localUser => {
                setBusy(false);
                if (typeof localUser === 'object') {
                    setWipUser(localUser);
                }
            }).catch(e => {
                setBusy(false);
                if (!myAbortController.signal.aborted) {
                    dispatch(addError('Failed to get user'));
                    console.error('Error');
                }
            });

            return () => {
                myAbortController.abort();
            }

        }
    }, [token, userUuid]);

    useEffect(() => {
        if (wipUser && companies.length && projects.length && tableDefinitionsComplete[TABLENAME]?.searchParams) {
            let localFormWithOptions = mapEnumsToForm(userEditForm, tableDefinitionsComplete[TABLENAME]?.searchParams);
            localFormWithOptions = localFormWithOptions.map(field => {
                if (field.propValue === 'companies') {
                    return { ...field, options: ([...companies] ?? []).sort(companySort).map(company => ({ name: company?.name ?? '', value: company.uuid })) }
                } else if (field.propValue === 'projects') {
                    return { ...field, options: ([...projects] ?? []).sort(projectSort).map(project => ({ name: project?.name ?? '', value: project.uuid })) }
                } else if (field.propValue === 'userRoles') {
                    return { ...field, options: ([...userRoles] ?? []).map(userRole => ({ name: userRole ?? '', value: userRole })) }
                } else if (field.propValue === 'userPermissions') {
                    return { ...field, options: ([...userPermissions] ?? []).map(userPermission => ({ name: userPermission ?? '', value: userPermission })) }
                } else if (field.propValue === 'userGroups') {
                    return { ...field, options: ([...userGroups] ?? []).map(userGroup => ({ name: userGroup?.name ?? '', value: userGroup.uuid })) }
                }

                else {
                    return field
                }
            })
            setFormWithOptions(localFormWithOptions);
        }
    }, [companies, projects, wipUser, tableDefinitionsComplete])

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createUser(token, entity as IUser).then((user) => {
                dispatch(addToast(t('User created')));
                setBusy(false);
                setWipUser(user);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            })
        } else {
            setBusy(true);
            updateUser(token, entity as IUser).then((user) => {
                dispatch(addToast(t('User updated')));
                setBusy(false);
                setWipUser(user);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            });
        }
    }

    const clearForm = () => {
        if (hasChanged(wipUser, formWithOptions, wipEntity)) {
            setConfirmClear(true);
        } else {
            const entity = createEntity(formWithOptions);
            setWipEntity(entity);
        }
    }

    const onEndEdit = () => {
        if (hasChanged(wipUser, formWithOptions, wipEntity)) {
            setConfirmEndEdit(true);
        }
        else {
            setWipEntity({ ...wipUser });
        }
    }

    const onCreateFromExisting = () => {
        if (hasChanged(wipUser, formWithOptions, wipEntity)) {
            setConfirmCreateFromExisting(true);
        } else {
            createFromExisting();
        }
    }

    const createFromExisting = () => {
        setWipEntity({ ...wipUser, uuid: undefined, emailAddress: '', phoneNumber: '', firstName: '', lastName: '' });
        setConfirmCreateFromExisting(false);
    }

    return (
        <div className='m-2 overflow-y-auto h-full pb-4'>
            <div className="relative z-30 w-full h-full">
                {confirmCreateFromExisting ? <ConfirmationModal show={confirmCreateFromExisting} confirmationText='You have unsaved changes. Are you sure you want to abandon this edit?' onCancel={() => setConfirmCreateFromExisting(false)} onConfirm={createFromExisting} /> : null}
                {busy ? <Busy /> :
                    wipUser ? <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                        <FormTemplate entityName='User' form={formWithOptions} setForm={setFormWithOptions} entity={wipUser as TEntity} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={undefined} onEndEdit={endEdit} >
                            <div className='flex mt-2 mx-2'>
                                <div className='flex-grow'>
                                    {wipEntity?.uuid ? <button type='button' onClick={clearForm} className='justify-self-start h-7 ml-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Clear and create new')}</button> : null}
                                    {wipEntity?.uuid ? <button type='button' onClick={onCreateFromExisting} className='justify-self-start h-7 ml-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Create from existing')}</button> : null}
                                </div>
                                {entityStack.length > 1 && <button type='button' onClick={onUndo} className='h-7 mr-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Undo')}</button>}
                                <button type='button' onClick={onEndEdit} className='h-7 mr-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Cancel')}</button>
                                <button type='submit' className='h-7 ml-1 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md '>{t('Submit')}</button>
                            </div>
                        </FormTemplate>
                    </FormContext.Provider> : null}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}

        </div>
    )
}