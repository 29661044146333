import React, { useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../hooks';
import {
    addError, setSelectedAssets, setAssetsForReporting, setOneReportResults
} from "../../../store/mainSlice";
import {
    IColumnHeader, TResultRow, IResultHistory, ISortColumn, TReportEntityGroup,
    TTableResultEndpoint, ITableDefinitionResult, ILatLng, TExtendedFilter, TSlideOver
} from "../../../store/Interfaces";
import { Busy } from "../../Busy";
import { ContextMenu } from '../../ContextMenu';
import { ConfirmationModal } from "../../ConfirmationModal";
import { base64FromArraybuffer } from '../../../utils';
import { downloadReportData, getAssetResults, getOneReport, getTableDefinition, downloadOneReport } from "../../../api";
import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";
import { getFilteredSortedResults, jsonToCsv, downloadCSV } from "../utils";
import { TBounds } from "../../../Grid/Grid";
import { OneReportDocument } from "./OneReportDocument";

export interface IOneReportTableProps {
    tableEndPoint: TTableResultEndpoint | undefined;
    bounds: TBounds | null;
    boundsFiltered: 'map' | 'sld' | '';
    getReport: (e: React.MouseEvent, row: TResultRow) => void;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    resultFilterText: string;
    setHighlightLatLng: (ll: ILatLng | undefined) => void;
    selectedEntity: TReportEntityGroup | undefined;
}

//const TABLENAME = 'TableOneReport';

interface IOneReportState {
    loadingItem: 'csv' | 'report' | '';
    loading: boolean;
    modalShown: boolean;
    reportPDF: string | undefined;
    assetsForReportingUsed: Array<{ assetUuid: string, resultUuid: string }>;
    expandedHeaders: Record<string, boolean>;
    contextPos: { x: number, y: number };
    contextRow: TResultRow | null;
    menuShown: boolean;
    ac: AbortController | undefined;
    lastSelectedAssetUuid: string;
    lastRangeAdded: string[];
}

interface IOneReportAction {
    type: 'setLoadingItem' | 'setLoading' | 'setModalShown' | 'setReportPDF' | 'setAssetsForReportingUsed' | 'setExpandedHeaders' | 'setContextPos' | 'setContextRow' | 'setMenuShown' |
    'setAc' | 'setLastSelectedAssetUuid' | 'setLastRangeAdded';
    payload: boolean | string | number | undefined | string[] | Array<{ assetUuid: string, resultUuid: string }> | Record<string, boolean> | { x: number, y: number } | TResultRow | ITableDefinitionResult | AbortController | null;
}

const initialState: IOneReportState = {
    loadingItem: '',
    loading: false,
    modalShown: false,
    reportPDF: undefined,
    assetsForReportingUsed: [],
    expandedHeaders: { 'onereport': true },
    contextPos: { x: 0, y: 0 },
    contextRow: null,
    menuShown: false,
    ac: undefined,
    lastSelectedAssetUuid: '',
    lastRangeAdded: []
}

export const OneReportTable: React.FunctionComponent<IOneReportTableProps> = ({ tableEndPoint, onClickTableColumn, resultFilterText, sortColumn, setHighlightLatLng, bounds, boundsFiltered, getReport }) => {
    const { t } = useTranslation();
    const goodHeaders = ['Site', 'Asset', 'Asset type', 'Sub-Site', 'Tests'];

    const { assets, token, tableDefinitionsComplete, selectedAssets, assetsForReporting, oneReportResults } = useSelector(state => state.main);

    const dispatch = useDispatch();

    const reducer = (state: IOneReportState, action: IOneReportAction): IOneReportState => {
        switch (action.type) {
            case 'setLoadingItem':
                return { ...state, loadingItem: action.payload as '' | 'csv' | 'report' };
            case 'setLoading':
                return { ...state, loading: action.payload as boolean };
            case 'setModalShown':
                return { ...state, modalShown: action.payload as boolean };
            case 'setReportPDF':
                return { ...state, reportPDF: action.payload as string | undefined };
            case 'setAssetsForReportingUsed':
                return { ...state, assetsForReportingUsed: action.payload as Array<{ assetUuid: string, resultUuid: string }> };
            case 'setExpandedHeaders':
                return { ...state, expandedHeaders: action.payload as Record<string, boolean> };
            case 'setContextPos':
                return { ...state, contextPos: action.payload as { x: number, y: number } };
            case 'setContextRow':
                return { ...state, contextRow: action.payload as TResultRow | null };
            case 'setMenuShown':
                return { ...state, menuShown: action.payload as boolean };
            case 'setAc':
                return { ...state, ac: action.payload as AbortController | undefined };
            case 'setLastSelectedAssetUuid':
                return { ...state, lastSelectedAssetUuid: action.payload as string };
            case 'setLastRangeAdded':
                return { ...state, lastRangeAdded: action.payload as string[] };
            default:
                throw new Error();
        }
    }
    const [{ loadingItem, loading, modalShown, reportPDF, assetsForReportingUsed, expandedHeaders, contextPos,
        contextRow, menuShown, ac, lastSelectedAssetUuid, lastRangeAdded }, localDispatch] = useReducer(reducer, initialState);

    const setLoadingItem = (loadingItem: '' | 'csv' | 'report') => localDispatch({ type: 'setLoadingItem', payload: loadingItem });
    const setLoading = (loading: boolean) => localDispatch({ type: 'setLoading', payload: loading });
    const setModalShown = (modalShown: boolean) => localDispatch({ type: 'setModalShown', payload: modalShown });
    const setReportPDF = (reportPDF: string | undefined) => localDispatch({ type: 'setReportPDF', payload: reportPDF });
    const setAssetsForReportingUsed = (assetsForReportingUsed: Array<{ assetUuid: string, resultUuid: string }>) => localDispatch({ type: 'setAssetsForReportingUsed', payload: assetsForReportingUsed });
    const setExpandedHeaders = (expandedHeaders: Record<string, boolean>) => localDispatch({ type: 'setExpandedHeaders', payload: expandedHeaders });
    const setContextPos = (contextPos: { x: number, y: number }) => localDispatch({ type: 'setContextPos', payload: contextPos });
    const setContextRow = (contextRow: TResultRow | null) => localDispatch({ type: 'setContextRow', payload: contextRow });
    const setMenuShown = (menuShown: boolean) => localDispatch({ type: 'setMenuShown', payload: menuShown });
    const setAc = (ac: AbortController | undefined) => localDispatch({ type: 'setAc', payload: ac });
    const setLastSelectedAssetUuid = (lastSelectedAssetUuid: string) => localDispatch({ type: 'setLastSelectedAssetUuid', payload: lastSelectedAssetUuid });
    const setLastRangeAdded = (lastRangeAdded: string[]) => localDispatch({ type: 'setLastRangeAdded', payload: lastRangeAdded });

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        setMenuShown(false)
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        const getTable = async (ac: AbortController) => {
            if (tableEndPoint) {
                const filter: TExtendedFilter[] = [];
                if (assetsForReporting.length > 0) {
                    filter.push({ propName: "asset.uuidStr", matches: assetsForReporting.map(ar => ar.assetUuid) });
                }
                setAssetsForReportingUsed([...assetsForReporting])
                setLoading(true);
                getTableDefinition(token, tableEndPoint, filter, true, ac).then((res) => {
                    res.columnHeaders = [...res.columnHeaders, { title: 'Asset type', type: 'String', propValue: 'assetType', propDisplay: 'assetType' }];
                    res.rows = res.rows.map(r => ({ ...r, assetType: assets.find(a => a.uuid === r.assetUuid)?.assetType ?? '' })) as unknown[] as TResultRow[];
                    dispatch(setOneReportResults(res));
                    setLoading(false);
                }).catch((err) => {
                    addError(err.message);
                    setLoading(false);
                });
            }
        }

        if (token && ac && tableEndPoint) {
            dispatch(setSelectedAssets([]));
            if ((assetsForReporting.length > assetsForReportingUsed.length) ||
                assetsForReporting.some(af => assetsForReportingUsed.every(aru => aru.assetUuid !== af.assetUuid))) {
                getTable(ac);
            }
        }
    }, [assetsForReporting, tableEndPoint, token, tableDefinitionsComplete, ac, dispatch]);

    const onExport = () => {
        if (ac && tableEndPoint) {
            setLoadingItem('csv');
            const filter: TExtendedFilter[] = [];
            if (assetsForReporting.length > 0) {
                filter.push({ propName: "asset.uuidStr", matches: assetsForReporting.map(ar => ar.assetUuid) });
            }

            getTableDefinition(token, tableEndPoint, filter, true, ac).then(reportForCsv => {
                let filteredSortedResults: TResultRow[] = (undefined === reportForCsv.rows || 0 === reportForCsv.rows.length) ? [] :
                    getFilteredSortedResults(reportForCsv.rows, bounds, boundsFiltered, resultFilterText, [], sortColumn, reportForCsv.columnHeaders);

                let columns: string[][] = [];
                columns.push(reportForCsv.columnHeaders.map(ch => ch.title));
                columns = columns.concat(filteredSortedResults.map(row =>
                    oneReportResults.columnHeaders.map((ch, idx) => {
                        if (row[ch.propDisplay] === undefined) {
                            console.log(`One Report table - Missing ${ch.propDisplay} in row ${idx}`)
                        }
                        return (row[ch.propDisplay] ?? '').toString();
                    })
                ));
                const csvString = jsonToCsv(columns);
                downloadCSV(csvString, `Report export - ${new Date().toLocaleString()}.csv`);
            }).catch(err => {
                addError(err.message);
            }).finally(() => {
                setLoadingItem('')
            });
        }
    }

    const onDownloadReport = async () => {
        setModalShown(false);
        if (ac) {
            assetsForReporting //.filter(a4r => a4r.resultUuid !== '')
                .forEach(async (assetForReporting) => {
                    let history: IResultHistory[] = [];
                    try {
                        history = await getAssetResults(token, assetForReporting.assetUuid, tableEndPoint?.name ?? 'OnlinePD', 'Asset', ac);
                    } catch (err) {
                        addError('Error getting report history');
                    }
                    if (history.length > 0) {
                        history.sort((a, b) => b.endTime - a.endTime);
                        await downloadReportData(token, history[0].uuid)
                    }
                })
        }
    }

    const generateReport = async () => {
        try {
            setLoadingItem('report');
            const reportRes = await getOneReport(token, tableEndPoint?.name ?? 'OnlinePD', assetsForReporting.map(ar => ar.assetUuid), false);
            const pdf = await base64FromArraybuffer(reportRes.report);
            setReportPDF(pdf);
            setLoadingItem('');
        } catch (err) {
            setLoadingItem('');
        }
    }

    const downloadReport = async () => {
        try {
            setLoadingItem('report');
            downloadOneReport(token, tableEndPoint?.name ?? 'OnlinePD', assetsForReporting.map(ar => ar.assetUuid), 'OneReport');
            setLoadingItem('');
        } catch (err) {
            setLoadingItem('');
        }
    }


    const removeSelected = () => {
        const localAssetsForReporting = assetsForReporting.filter(af => !selectedAssets.includes(af.assetUuid));
        dispatch(setAssetsForReporting(localAssetsForReporting));
        dispatch(setOneReportResults({ ...oneReportResults, rows: oneReportResults.rows.filter(r => !selectedAssets.includes(r.assetUuid)) }));
    }

    const removeRow = (e: React.MouseEvent) => {
        if (contextRow) {
            const localAssetsForReporting = assetsForReporting.filter(af => af.assetUuid !== contextRow.assetUuid);
            dispatch(setAssetsForReporting(localAssetsForReporting));
            dispatch(setOneReportResults({ ...oneReportResults, rows: oneReportResults.rows.filter(r => r.assetUuid !== contextRow.assetUuid) }));
        }
    }

    const deselectAllAssets = () => {
        dispatch(setSelectedAssets([]));
    }

    const handleContextMenu = (e: MouseEvent, row: TResultRow) => {
        e.preventDefault();
        setContextPos({ x: e.pageX, y: e.pageY });
        setMenuShown(true);
        setContextRow(row);
    };

    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const filteredSortedResults: TResultRow[] = useMemo(() => (undefined === oneReportResults || 0 === oneReportResults.rows.length) ? [] :
        getFilteredSortedResults(oneReportResults.rows, bounds, boundsFiltered, resultFilterText, [], sortColumn, oneReportResults.columnHeaders), [oneReportResults, resultFilterText, sortColumn, bounds, boundsFiltered]);

    const availableReports = assetsForReporting.filter(a4r => a4r.resultUuid !== '');


    const onClickRow = (e: React.MouseEvent, row: TResultRow) => {
        //const chosenAsset = assets.find(a => a.uuid === row.assetUuid);
        if (e.shiftKey) {
            //    if (chosenAsset) { // is this necessary?
            if (lastSelectedAssetUuid === '') {
                dispatch(setSelectedAssets([row.assetUuid]));
                setLastSelectedAssetUuid(row.assetUuid);
                setLastRangeAdded([row.assetUuid]);
            }
            else {
                const r = getFilteredSortedResults(oneReportResults.rows, bounds, boundsFiltered, resultFilterText, [], sortColumn, oneReportResults.columnHeaders);
                const idx1 = r.findIndex(r => r.assetUuid === lastSelectedAssetUuid);
                const idx2 = r.findIndex(r => r.assetUuid === row.assetUuid);
                if (idx1 !== -1 && idx2 !== -1) {
                    const newSelectedAssets = r.slice(Math.min(idx1, idx2), Math.max(idx1, idx2) + 1).map(r => r.assetUuid);
                    const prevSelectedAssets = selectedAssets.filter(sa => !lastRangeAdded.includes(sa));
                    dispatch(setSelectedAssets([...new Set([...newSelectedAssets, ...prevSelectedAssets])]));
                    setLastRangeAdded(newSelectedAssets);
                }
            }
            //   }
        } else if ((e.ctrlKey && navigator.platform.indexOf('Mac') === -1) ||
            (e.metaKey && navigator.platform.indexOf('Mac') !== -1)) {
            if (selectedAssets.includes(row.assetUuid)) {
                dispatch(setSelectedAssets(selectedAssets.filter(sa => sa !== row.assetUuid)));
                setLastRangeAdded([]);
            } else {
                dispatch(setSelectedAssets([...selectedAssets, row.assetUuid]));
                setLastSelectedAssetUuid(row.assetUuid);
                setLastRangeAdded([]);
            }
        } else {
            setLastSelectedAssetUuid(row.assetUuid);
            dispatch(setSelectedAssets([row.assetUuid]));
            setLastRangeAdded([]);
            getReport(e, row);
        }
        const s = document.getSelection();
        if (s) {
            s.removeAllRanges();
        }
    }

    return (loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div> :
        <div className="relative grid grid-rows_[1fr_30px] overflow-hidden">
            {reportPDF ?
                <OneReportDocument reportPDF={reportPDF} />
                :
                <><div className='oneReportTableClipper relative overflow-y-auto bg-hvpd-grey-50'>
                    <table className='table-fixed min-w-full border-slate-400 border' >
                        <thead className='bg-colour-c01dc2f border-b sticky-top'>
                            <tr>
                                {oneReportResults.columnHeaders.filter(ch => goodHeaders.includes(ch.title)).map(colHeader => (
                                    <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                                ))}
                            </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                            {filteredSortedResults.map((resultRow, idx) => (
                                <ResultsTableRow key={`row-${idx}`} columnHeaders={oneReportResults.columnHeaders.filter(ch => goodHeaders.includes(ch.title))} row={resultRow} handleContextMenu={handleContextMenu} onClickRow={onClickRow} expandedHeaders={expandedHeaders} setHighlightLatLng={setHighlightLatLng} selected={selectedAssets.some(sa => sa === resultRow.assetUuid)} />
                            ))}
                        </tbody>
                    </table>
                </div>
                    <ContextMenu pos={contextPos} menuShown={menuShown} closeMenu={() => setMenuShown(false)} >
                        <li className='w-full'><button onClick={removeRow} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>{t('Remove row')}</button></li>
                        {selectedAssets.length > 0 ? <li className='w-full'><button onClick={deselectAllAssets} className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left'>{t('Deselect all')}</button></li> : null}
                    </ContextMenu >
                </>
            }
            <div className='w-full bg-hvpd-grey-100'>
                <div className='p-1 grid grid-flow-col grid-cols-[140px_140px_140px_140px_180px_1fr]' >
                    {reportPDF ?
                        <>
                            <button
                                type="button"
                                disabled={loading}
                                className='bg-gray-300 text-sm rounded-md ml-2 mr-2 px-2 py-1 text-black hover:bg-gray-400 border-gray-500  my-2'

                                onClick={downloadReport}
                            >
                                {t('Download report')}
                            </button>
                            <button
                                type="button"
                                className='bg-gray-300 text-sm rounded-md ml-2 mr-2 px-2 py-1 text-black hover:bg-gray-400 border-gray-500  my-2'

                                onClick={() => setReportPDF(undefined)}
                            >
                                {t('Close')}
                            </button>
                        </>
                        : <>
                            <button disabled={selectedAssets.length === 0} onClick={removeSelected} className='mr-2 text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-1 border-solid border-1 text-sm whitespace-nowrap'>{t('Remove selected')}</button>
                            <button disabled={filteredSortedResults.length === 0 || loadingItem === 'csv'} onClick={onExport} className='mx-2 text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-1 border-solid border-1 text-sm whitespace-nowrap'>{loadingItem === 'csv' ? <span className="mr-1"><Busy size='xs' /></span> : null}{t('Export to CSV')}</button>
                            <button disabled className='mx-2 text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-1 border-solid border-1 text-sm whitespace-nowrap'>Export to Excel</button>
                            <button disabled={!tableEndPoint?.name || loadingItem === 'report'} onClick={generateReport} className="mx-2 text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-1 border-solid border-1 text-sm whitespace-nowrap">{loadingItem === 'report' ? <span className="mr-1"><Busy size='xs' /></span> : null}{t('Generate report')}</button>
                            <button disabled={availableReports.length === 0 || availableReports.length > 10} onClick={() => setModalShown(true)} className="mx-2 text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-1 py-1 border-solid border-1 text-sm whitespace-nowrap">{availableReports.length !== 1 ? t('Download n data items', { count: availableReports.length }) : t('Download 1 data item')}</button>
                            <span className="text-right text-sm text-hvpd-grey-700  whitespace-nowrap">{filteredSortedResults.length !== 1 ? t('Showing n results', { count: filteredSortedResults.length }) : t('Showing 1 result')}</span>
                        </>}
                </div>
            </div >
            <ConfirmationModal show={modalShown} confirmationText={availableReports.length !== 1 ? t('Download data for n reports?', { count: availableReports.length }) : t('Download data for 1 report?')} onCancel={() => setModalShown(false)} onConfirm={onDownloadReport} />
        </div >);
}