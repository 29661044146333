import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useAppSelector as useSelector, useAppDispatch as useDispatch,
} from "../../hooks";
import { TEntityType, TUploadParameters } from '../../store/Interfaces';
//import { setError } from '../../store/mainSlice';
import { TFileType, TUploadType, getUploadParameters, uploadFile } from "../../api";

import { addToast } from "../../store/mainSlice";
import { Busy } from "../Busy";
import { ChunkedFileUpload } from "./ChunkedFileUpload";

export const CHUNK_SIZE_DEFAULT = 1024 * 64; // 64k

export interface IFileUploadProps {
    uuid: string;
    entityType: TEntityType;
    type: TUploadType;
    onError?: (error: string) => void;
    onSuccessfulUpload?: (file: File) => void;
}

export const FileUpload: React.FunctionComponent<IFileUploadProps> = ({ type, uuid, entityType, onError, onSuccessfulUpload }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token } = useSelector(store => store.main);
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [preview, setPreview] = useState<string | null>(null);

    const [uploadParameters, setUploadParameters] = useState<null | TUploadParameters>(null);
    const [showChunkedUpload, setShowChunkedUpload] = useState<boolean>();

    const [ac, setAc] = useState<AbortController>(new AbortController());

    const typeToFileType: Record<TUploadType, TFileType> = { 'image': 'Image', 'doc': 'Doc', 'zip': 'Zip' };


    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const fileList = event.target.files;
        if (fileList && fileList?.length > 0) {
            const selectedFile = fileList[0];
            if (type === 'zip') {
                if (selectedFile && (selectedFile.type === 'application/zip' || selectedFile.name.toLowerCase().endsWith('.zip'))) {
                    setFile(selectedFile);
                } else {
                    setError(t('Please select a zip file'));
                }
            } else if (type === 'doc') {
                if (selectedFile && (selectedFile.name.toLowerCase().endsWith('.doc') || selectedFile.name.toLowerCase().endsWith('docx') || selectedFile.name.toLowerCase().endsWith('pdf'))) {
                    setFile(selectedFile);
                } else {
                    setError(t('Please select a doc or pdf file'));
                }
            }
            else if (selectedFile.type.startsWith('image/')) {
                const objectUrl = URL.createObjectURL(selectedFile);
                setPreview(objectUrl);
            } else {
                setError(t('Please select an image file'));
            }
            setFile(fileList[0]);
        } else {
            setFile(null);
            setPreview(null);
        }
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event): Promise<true | false | 'exists'> => {
        event.preventDefault();

        if (file) {
            let localUploadParameters = null;

            try {
                setUploading(true);
                localUploadParameters = await getUploadParameters(token);
                setUploadParameters(uploadParameters);
            } catch (e) {
                setUploading(false);
            }
            if (!localUploadParameters) {
                setError(t('Error getting upload parameters'));
                setUploading(false);
                return Promise.resolve(false);
            }
            if (file.size > localUploadParameters.maximumFileUploadSize_bytes) {
                setError(t('File size is too large'));
                setUploading(false);
                return Promise.resolve(false);
            }

            setUploading(true);

            let result: boolean;
            if (file.size >= localUploadParameters.chunkedUploadThresholdSize_bytes) {
                setError('');
                setShowChunkedUpload(true);
                result = false;
            }
            else {
                setError('');
                result = await uploadFile(token, uuid, file, entityType, typeToFileType[type], ac);
                setUploading(false);
                setPreview(null);

                if (result === false) {
                    if (onError) {
                        onError(t('Error uploading file n', { name: file.name }));
                    } else {
                        setError(t('Error uploading file'));
                    }
                    setPreview(null);
                    return Promise.resolve(false);
                } else {
                    if (onSuccessfulUpload) {
                        onSuccessfulUpload(file);
                    }
                    return Promise.resolve(true);
                }
            }
        }
        return Promise.resolve(false);
    }

    const chunkedUploadError = (error: unknown) => {
        setError(t('Error uploading file - chunked upload'));
        setUploading(false);
        setShowChunkedUpload(false);
        setPreview(null);
    }
    const cancelChunkedUpload = () => {
        setUploading(false);
        setShowChunkedUpload(false);
        setPreview(null);
        dispatch(addToast(t('Upload cancelled')));
    }
    const chunkedUploadSuccess = (file: File) => {
        setUploading(false);
        setShowChunkedUpload(false);
        setPreview(null);
        onSuccessfulUpload && onSuccessfulUpload(file);
    }

    const getAcceptString = (): string => {
        if (type === 'zip') {
            return '.zip';
        } else if (type === 'image') {
            return "image/*";
        }
        return ".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }

    return (
        <form onSubmit={handleSubmit} className='my-3 max-h-[560px] grid auto-rows-auto'>
            <h4 className="text-lg mb-2">{type === 'image' ? t('Upload an image file') : type === 'doc' ? t('Upload a document') : t('Upload a zip archive')}</h4>
            {(uploading && !showChunkedUpload) ? <div className="mb-20"><Busy /></div> : null}
            {error ? <p className="text-lg bg-hvpd-red-200 border-hvpd-red-600 p-2">{error}</p> : null}
            <input type="file" disabled={uploading} id="file_input" accept={getAcceptString()} onChange={handleChange} className='relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-hvpd-pickled-bluewood-200 bg-clip-padding py-[0.32rem] px-3 text-base font-normal text-hvpd-pickled-bluewood-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-hvpd-pickled-bluewood-100 file:px-3 file:py-[0.32rem] file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-hvpd-pickled-bluewood-200 focus:border-primary focus:text-hvpd-pickled-bluewood-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:border-hvpd-pickled-bluewood-600 dark:text-hvpd-pickled-bluewood-200 dark:file:bg-hvpd-pickled-bluewood-700 dark:file:text-hvpd-pickled-bluewood-100' />

            {(showChunkedUpload && file) ? <ChunkedFileUpload token={token} entityType={entityType} uuid={uuid} file={file} type={type} onCancel={cancelChunkedUpload} onError={chunkedUploadError} onCompleted={chunkedUploadSuccess} chunkSize={uploadParameters?.chunkSize_bytes ?? CHUNK_SIZE_DEFAULT} /> : null}

            {preview && <div className='mt-2 max-h-[520px] overflow-y-hidden'><img src={preview} alt="Preview" className='max-w-md max-h-md' /></div>}
            {(file !== null && !uploading) ? <div className='mt-2'><button className="rounded text-white disabled:text-hvpd-pickled-bluewood-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium">Upload</button></div> : null}
        </form>
    );
}