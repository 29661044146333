import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const initI18n = () => {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            // the translations
            // (tip move them in a JSON file and import them,
            // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
            resources: {
                en: {
                    translation: {
                        '1 image': '1 image',
                        'Aborted getting recommendation templates': 'Aborted getting recommendation templates',
                        'Access Error': 'Access Error',
                        'Acknowledged date': 'Acknowledged date',
                        'Add all to One Report': 'Add all to One Report',
                        'Add asset observation': 'Add asset observation',
                        'Add asset recommendation': 'Add asset recommendation',
                        'Add company observation': 'Add company observation',
                        'Add count new assets': 'Add {{count}} new assets',
                        'Add one new asset': 'Add one new asset',
                        'Added observation': 'Added observation',
                        'Add result observation': 'Add result observation',
                        'Add result recommendation': 'Add result recommendation',
                        'Add selected to One Report': 'Add selected to One Report',
                        'Add site observation': 'Add site observation',
                        'Add sub site observation': 'Add sub site observation',
                        'Added recommendation n': 'Added recommendation {{name}}',
                        'Address': 'Address',
                        'and': 'and',
                        'Api mode beta': 'Api mode beta',
                        'Api mode dev': 'Api mode dev',
                        'Api mode live': 'Api mode live',
                        'Api mode demo': 'Api mode demo',
                        'Api mode staging': 'Api mode staging',
                        'Apply clipboard asset observation to selected entries': 'Apply clipboard asset observation to selected entries',
                        'Apply clipboard result observation to selected entries': 'Apply clipboard result observation to selected entries',
                        'Apply clipboard asset recommendation to selected entries': 'Apply clipboard asset recommendation to selected entries',
                        'Apply clipboard result recommendation to selected entries': 'Apply clipboard result recommendation to selected entries',
                        'Apply observation': 'Apply observation',
                        'Apply recommendation': 'Apply recommendation',
                        'Apply selection': 'Apply selection',
                        'Apply this observation:': 'Apply this observation:',
                        'Apply this recommendation:': 'Apply this recommendation:',
                        'Apply to 1 asset': 'Apply to 1 asset',
                        'Apply to 1 result': 'Apply to 1 result',
                        'Apply to n assets': 'Apply to {{count}} assets',
                        'Apply to n results': 'Apply to {{count}} results',
                        'Approve': 'Approve',
                        'Approve n results_one': 'Approve {{count}} result',
                        'Approve n results_other': 'Approve {{count}} results',
                        'Approve report': 'Approve report',
                        'Approve result': 'Approve result',
                        'Approve results': 'Approve results',
                        'Approve results for selected rows': 'Approve results for selected rows',
                        'Approve results for the following selected items?': 'Approve results for the following selected items?',
                        'Are you sure you want to delete this thing': 'Are you sure you want to delete this {{thing}}?',
                        'Asset': 'Asset',
                        'asset': 'asset',
                        'Assets': 'Assets',
                        'Asset created': 'Asset created',
                        'Asset deleted': 'Asset deleted',
                        'Asset GUID u copied to clipboard': 'Asset GUID {{uuid}} copied to clipboard',
                        'Asset name': 'Asset name',
                        'Asset observation copied to clipboard': 'Asset observation copied to clipboard',
                        'Asset observation': 'Asset observation',
                        'Asset observations': 'Asset observations',
                        "Asset Severity Count": "Asset Severity Count",
                        'Asset template operations': 'Asset template operations',
                        'Asset updated': 'Asset updated',
                        'Authentication Error': 'Authentication Error',
                        'Available': 'Available',
                        'Available n': 'Available {{name}}',
                        'Back': 'Back',
                        'Back to selection': 'Back to selection',
                        'Bar charts': 'Bar charts',
                        'Basic': 'Basic',
                        'Cancel': 'Cancel',
                        'Clear and create new': 'Clear and create new',
                        'Clear quick filter': 'Clear quick filter',
                        'Clear template': 'Clear template',
                        'Click on image to select': 'Click on image to select',
                        'click to add': 'click to add',
                        'Click to download n': 'Click to download {{name}}',
                        'click to remove': 'click to remove',
                        'Click to change to SLD view': 'Click to change to SLD view',
                        'Click to copy to clipboard': 'Click to copy to clipboard',
                        'Click to select a SLD': 'Click to select a SLD',
                        'Click to switch to map view': 'Click to switch to map view',
                        'Client PO date': 'Client PO date',
                        'Client PO number': 'Client PO number',
                        'Clipboard asset observation': 'Clipboard asset observation',
                        'Clipboard result observation': 'Clipboard result observation',
                        'Clipboard asset recommendation': 'Clipboard asset recommendation',
                        'Clipboard result recommendation': 'Clipboard result recommendation',
                        'Clipboard asset report': 'Clipboard asset report',
                        'Clipboard result report': 'Clipboard result report',
                        'Close': 'Close',
                        'Company': 'Company',
                        'Companies': 'Companies',
                        'Company created': 'Company created',
                        'Company deleted': 'Company deleted',
                        'Company GUID u copied to clipboard': 'Company GUID {{uuid}} copied to clipboard',
                        'Company name': 'Company name',
                        'Company observation': 'Company observation',
                        'Company observations': 'Company observations',
                        'Company updated': 'Company updated',
                        'Compress left': 'Compress left',
                        'Compress right': 'Compress right',
                        'Confirm': 'Confirm',
                        'Confirm apply observation': 'Confirm apply observation',
                        'Confirm apply recommendation': 'Confirm apply recommendation',
                        'Confirm edits to the following assets': 'Confirm edits to the following assets',
                        'Confirm report approval': 'Confirm report approval',
                        'Confirm report disapproval': 'Confirm report disapproval',
                        'Confirm result approval': 'Confirm result approval',
                        'Confirm result disapproval': 'Confirm result disapproval',
                        'Constrain by company': 'Constrain by company',
                        'Contract column': 'Contract column',
                        'contains v': 'contains "{{value}}"',
                        'Copy as template': 'Copy as template',
                        'Copy all result GUIDs to clipboard': 'Copy all result GUIDs to clipboard',
                        'Copy asset GUID to clipboard': 'Copy asset GUID to clipboard',
                        'Copy company GUID to clipboard': 'Copy company GUID to clipboard',
                        'Copy latest result GUID to clipboard': 'Copy latest result GUID to clipboard',
                        'Copy site GUID to clipboard': 'Copy site GUID to clipboard',
                        'Copy sub site GUID to clipboard': 'Copy sub site GUID to clipboard',
                        'Copy user GUID to clipboard': 'Copy user GUID to clipboard',
                        'Copy to clipboard': 'Copy to clipboard',
                        'Copy url and close': 'Copy url and close',
                        'create': 'create',
                        'Create at recommendation': 'Create {{assetType}} recommendation',
                        'Create asset observation': 'Create asset observation',
                        'Create assets': 'Create assets',
                        'Create company observation': 'Create company observation',
                        'Create from existing': 'Create from existing',
                        'Create observation': 'Create observation',
                        'Create site observation': 'Create site observation',
                        'Create subsite observation': 'Create subsite observation',
                        'Dashboard mode off': 'Dashboard mode off',
                        'Dashboard mode on': 'Dashboard mode on',
                        'Delete': 'Delete',
                        'Deleted': 'Deleted',
                        'Deselect all': 'Deselect all',
                        'Devices': 'Devices',
                        'Disable': 'Disable',
                        'Disapprove': 'Disapprove',
                        'Disapprove report': 'Disapprove report',
                        'Disapprove result': 'Disapprove result',
                        'Dismiss error': 'Dismiss error',
                        'Dont show again': 'Dont show again',
                        'Download': 'Download',
                        'Download 1 data item': 'Download 1 data item',
                        'Download n data items': 'Download {{count}} data items',
                        'Download data': 'Download data',
                        'Download data for 1 report?': 'Download data for 1 report?',
                        'Download data for n reports?': 'Download data for {{count}} reports?',
                        'Download report': 'Download report',
                        'Due date': 'Due date',
                        'edit': 'edit',
                        'Edit mode off': 'Edit mode off',
                        'Edit mode on': 'Edit mode on',
                        'Edit observation': 'Edit observation',
                        'Edit asset recommendation': 'Edit asset recommendation',
                        'Edit result recommendation': 'Edit result recommendation',
                        'Edit user': 'Edit user',
                        'Email': 'Email',
                        'Email address': 'Email address',
                        'Enable': 'Enable',
                        'End date': 'End date',
                        'Enter observation details here...': 'Enter observation details here...',
                        'Enter recommendation details here...': 'Enter recommendation details here...',
                        'Enter text to filter the table rows below.  Use + to separate multiple filters.': 'Enter text to filter the table rows below.  Use + to separate multiple filters.',
                        'equals v': 'equals {{value}}',
                        'Error': 'Error',
                        'Error applying observation': 'Error applying observation',
                        'Error applying recommendation': 'Error applying recommendation',
                        'Error completing upload': 'Error completing upload',
                        'Error changing report approval status to Approve': 'Error changing report approval status to Approve',
                        'Error changing report approval status to Disapprove': 'Error changing report approval status to Disapprove',
                        'Error changing result approval status to Approve': 'Error changing result approval status to Approve',
                        'Error changing result approval status to Disapprove': 'Error changing result approval status to Disapprove',
                        'Error creating sales order': 'Error creating sales order',
                        'Error creating user': 'Error creating user',
                        'Error getting asset diagrams': 'Error getting asset diagrams',
                        'Error getting upload parameters': 'Error getting upload parameters',
                        'Error updating sales order': 'Error updating sales order',
                        'Error creating user group': 'Error creating user group',
                        'Error loading report': 'Error loading report',
                        'Error refreshing results table': 'Error refreshing results table',
                        'Error updating user group': 'Error updating user group',
                        'Error uploading chunk': 'Error uploading chunk',
                        'Error uploading file': 'Error uploading file',
                        'Error uploading file - chunked upload': 'Error uploading file - chunked upload',
                        'Error uploading file n': 'Error uploading file {{name}}',
                        'Expand / contract': 'Expand / contract map panel',
                        'Expand column': 'Expand column',
                        'Export': 'Export',
                        'Export all': 'Export all',
                        'Export selected': 'Export selected',
                        'Export table to CSV': 'Export table to CSV',
                        'Export to CSV': 'Export to CSV',
                        'Extended': 'Extended',
                        'Extended Filters': 'Extended Filters',
                        'Failed to collect report for this item': 'Failed to collect report for this item',
                        'Failed to delete observation/recommendation': 'Failed to delete observation/recommendation',
                        'Failed to get recommendation templates': 'Failed to get recommendation templates',
                        'Failed to get users': 'Failed to get users',
                        'False': 'False',
                        'Fetch c items?_one': 'Fetch {{count}} item?',
                        'Fetch c items?_other': 'Fetch {{count}} items?',
                        'File n downloaded successfully': 'File {{name}} downloaded successfully',
                        'File n download failed e': 'File {{name}} download failed {{errorCode}}',
                        'File n ploaded successfully': 'File {{name}} uploaded successfully',
                        'file name': 'file name',
                        'File size is too large': 'File size is too large',
                        'Files stored': 'Files stored',
                        'Filters': 'Filters',
                        'Filter by result type': 'Filter by result type',
                        'Filter results': 'Filter results',
                        'Filter table': 'Filter table',
                        'First': 'First',
                        'Generate report': 'Generate report',
                        'Hide related reports': 'Hide related reports',
                        'Hiding results with no tests': 'Hiding results with no tests',
                        'High': 'High',
                        'Image information': 'Image information',
                        'Image name n copied to clipboard': 'Image name {{name}} copied to clipboard',
                        'Image upload': 'Image upload',
                        'Image upload successful': 'Image upload successful',
                        'Insert': 'Insert',
                        'Is active': 'Is active',
                        'is v': 'is {{value}}',
                        'is between v and v': 'is between {{value1}} and {{value2}}',
                        'is greater than v': 'is greater than {{value}}',
                        'is less than v': 'is less than {{value}}',
                        'is one of v': 'is one of {{value}}',
                        'It looks like your session has expired, you may need to log out and log back in again, or if you think an error may have occurred just try again.': 'It looks like your session has expired, you may need to log out and log back in again, or if you think an error may have occurred just try again.',
                        'kVA': 'kVA',
                        'Last': 'Last',
                        'Lat, Lng copied to clipboard': 'Lat, Lng copied to clipboard',
                        'Loading...': 'Loading...',
                        'Loading templates': 'Loading templates',
                        'Log In': 'Log In',
                        'Log Out': 'Log Out',
                        'Logo': 'Logo',
                        'Low': 'Low',
                        'Manage users': 'Manage users',
                        'Map view': 'Map view',
                        'Medium': 'Medium',
                        'Missing fields': 'Missing fields',
                        'Multi asset': 'Multi asset',
                        'n assets could not be added': '{{count}} assets could not be added',
                        'n assets have been added': '{{count}} assets have been added',
                        'n images': '{{count}} images',
                        'n Kb': '{{size}} Kb',
                        'n related observations': '{{count}} related observations',
                        'Name': 'Name',
                        'Names of assets': 'Names of assets',
                        'Next': 'Next',
                        'No': 'No',
                        'No asset found': 'No asset found',
                        'No downloadable data for this item': 'No downloadable data for this item',
                        'No files stored': 'No files stored',
                        'No GPS data found in image': 'No GPS data found in image',
                        'No company found': 'No company found',
                        'No extended filters selected': 'No extended filters selected',
                        'No images available for this asset': 'No images available for this asset',
                        'No linked entities': 'No linked entities',
                        'No recommendations, observations or related observations': 'No recommendations, observations or related observations',
                        'No related images': 'No related images',
                        'No report to approve': 'No report to approve',
                        'No reports are available for this asset': 'No reports are available for this asset',
                        'No result selected': 'No result selected',
                        'No results to display': 'No results to display',
                        'No start time': 'No start time',
                        'No site found': 'No site found',
                        'No SLDs found for this asset': 'No SLDs found for this asset',
                        'No sub site found': 'No sub site found',
                        'No user found': 'No user found',
                        'Notification time': 'Notification time',
                        'Notifications': 'Notifications',
                        'Number': 'Number',
                        'Number of new assets': 'Number of new assets',
                        'Observation applied to n': 'Observation applied to {{name}}',
                        'Observations': 'Observations',
                        'Observations created': 'Observations created',
                        'Observation deleted successfully': 'Observation deleted successfully',
                        'Observation details': 'Observation details',
                        'Ok': 'Ok',
                        'One asset could not be added': 'One asset could not be added',
                        'One asset has been added': 'One asset has been added',
                        'One report': 'One report',
                        'Original': 'Original',
                        'Page p of n': 'Page {{pageNumber}} of {{numPages}}',
                        'Permissions': 'Permissions',
                        'Phone': 'Phone',
                        'Pie charts': 'Pie charts',
                        'Please select...': 'Please select...',
                        'Please select a doc or pdf file': 'Please select a doc or pdf file',
                        'Please select a report from the right click menu on the report list': 'Please select a report from the right click menu on the report list',
                        'Please select a zip file': 'Please select a zip file',
                        'Please select an image file': 'Please select an image file',
                        'Prev': 'Prev',
                        'Previous': 'Previous',
                        'Project created': 'Project created',
                        'Project updated': 'Project updated',
                        'Project deleted': 'Project deleted',
                        'Projects': 'Projects',
                        'Rated Power kW': 'Rated Power kW',
                        'Rated Speed RPM': 'Rated Speed RPM',
                        'Recommendation': 'Recommendation',
                        'Recommendation applied to n': 'Recommendation applied to {{name}}',
                        'Recommendation copied to clipboard': 'Recommendation copied to clipboard',
                        'Recommendation deleted successfully': 'Recommendation deleted successfully',
                        'Recommendation details': 'Recommendation details',
                        'Recommendations': 'Recommendations',
                        'Recommendations created': 'Recommendations created',
                        'Refresh': 'Refresh',
                        'Related images': 'Related images',
                        'Remove observation from clipboard': 'Remove observation from clipboard',
                        'Related observations': 'Related observations',
                        'Related reports': 'Related reports',
                        'Remove recommendation from clipboard': 'Remove recommendation from clipboard',
                        'Remove report': 'Remove report',
                        'Remove row': 'Remove row',
                        'Remove selected': 'Remove selected',
                        'Remove selected from One Report': 'Remove selected from One Report',
                        'Report': 'Report',
                        'Report approved': 'Report approved',
                        'Report disapproved': 'Report disapproved',
                        'Report state': 'Report state',
                        'report pane': 'report pane',
                        'Report text copied to clipboard': 'Report text copied to clipboard',
                        'Report copied to clipboard': 'Report copied to clipboard',
                        'Report unavailable for this result': 'Report unavailable for this result',
                        'Request relative name': 'Request relative name',
                        'Reset': 'Reset',
                        'Reset One Report selection': 'Reset One Report selection',
                        'Result approved': 'Result approved',
                        'Result n approved': 'Result {{name}} approved',
                        'Result disapproved': 'Result disapproved',
                        'Result GUID guid copied to clipboard': 'Result GUID {{guid}} copied to clipboard',
                        'Result information copied to clipboard': 'Result information copied to clipboard',
                        'Result observation copied to clipboard': 'Result observation copied to clipboard',
                        'Result observation': 'Result observation',
                        'Result observations': 'Result observations',
                        "Results filter": "Results filter",
                        'Result': 'Result',
                        'result': 'result',
                        'Result state': 'Result state',
                        'Results': 'Results',
                        'Results approved': 'Results approved',
                        'Running conditions': 'Running conditions',
                        'Root relative name': 'Root relative name',
                        'Sales order created': 'Sales order created',
                        'Sales order deleted': 'Sales order deleted',
                        'Sales order updated': 'Sales order updated',
                        'Save': 'Save',
                        'Select': 'Select',
                        'Select a different template': 'Select a different template',
                        'Select a template': 'Select a template',
                        'Select all assets in view': 'Select all assets in view',
                        'Select assets for edit': 'Select assets for edit',
                        'Select data points': 'Select data points',
                        'Select filters from the following options': 'Select filters from the following options',
                        'Select image size': 'Select image size',
                        'Select multiple assets': 'Select multiple assets',
                        'Select assets to apply observation to': 'Select assets to apply observation to',
                        'Select results to apply observation to': 'Select results to apply observation to',
                        'Select assets to apply recommendation to': 'Select assets to apply recommendation to',
                        'Select results to apply recommendation to': 'Select results to apply recommendation to',
                        'Selected n': 'Selected {{name}}',
                        'Severity': 'Severity',
                        'Show only latest results': 'Show only latest results',
                        'Show panel': 'Show panel',
                        'Show related reports': 'Show related reports',
                        'Showing all filtered by connection': 'Showing all filtered by connection',
                        'Showing all filtered by map bounds': 'Showing all filtered by map bounds',
                        'Showing all unfiltered by connection': 'Showing all unfiltered by connection',
                        'Showing all unfiltered by map bounds': 'Showing all unfiltered by map bounds',
                        'Showing results with no tests': 'Showing results with no tests',
                        'Showing 1 result': 'Showing 1 result',
                        'Showing n results': 'Showing {{count}} results',
                        'Site': 'Site',
                        'Site created': 'Site created',
                        'Site deleted': 'Site deleted',
                        'Site GUID guid copied to clipboard': 'Site GUID {{guid}} copied to clipboard',
                        'Site name': 'Site name',
                        'Site observation': 'Site observation',
                        'Site observations': 'Site observations',
                        'Site updated': 'Site updated',
                        'Sites': 'Sites',
                        'Size': 'Size',
                        'Sorry, you are not authorised': 'Sorry, you are not authorised',
                        'Start date': 'Start date',
                        'Sub Site': 'Sub Site',
                        'Sub site': 'Sub site',
                        'Sub site GUID guid copied to clipboard': 'Sub site GUID {{guid}} copied to clipboard',
                        'SubSite created': 'SubSite created',
                        'SubSite updated': 'SubSite updated',
                        'SubSite deleted': 'SubSite deleted',
                        'SubSite observation': 'SubSite observation',
                        'SubSite observations': 'SubSite observations',
                        'Sub sites': 'Sub sites',
                        'Submit': 'Submit',
                        "Substation": "Substation",
                        'Tax Ref': 'Tax Ref',
                        'Test result type': 'Test result type',
                        'Telephone': 'Telephone',
                        'Text': 'Text',
                        'The following names are already in use:': 'The following names are already in use:',
                        'The number of names does not match the number of assets': 'The number of names does not match the number of assets',
                        'Thumbnail': 'Thumbnail',
                        'Title': 'Title',
                        'To the following selected items?': 'To the following selected items?',
                        'True': 'True',
                        'Try again': 'Try again',
                        'Type': 'Type',
                        'Upload in progress': 'Upload in progress',
                        'Url copied to clibpoard': 'Url copied to clibpoard',
                        'User created': 'User created',
                        'User updated': 'User updated',
                        'User group created': 'User group created',
                        'User group deleted': 'User group deleted',
                        'User group updated': 'User group updated',
                        'Undo': 'Undo',
                        'Update user': 'Update user',
                        'Updated observation n': 'Updated observation {{name}}',
                        'Updated recommendation n': 'Updated recommendation {{name}}',
                        'Upload a document': 'Upload a document',
                        'Upload an image file': 'Upload an image file',
                        'Upload a zip archive': 'Upload a zip archive',
                        'Upload cancelled': 'Upload cancelled',
                        'Upload complete': 'Upload complete',
                        'Upload document': 'Upload document',
                        'Upload image': 'Upload image',
                        'Upload zip': 'Upload zip',
                        'User groups': 'User groups',
                        'User GUID guid copied to clipboard': 'User GUID {{guid}} copied to clipboard',
                        'Users': 'Users',
                        'UUID': 'UUID',
                        'View recommendation': 'View recommendation',
                        'Voltage kV': 'Voltage kV',
                        'Warning: Please add users via user groups wherever possible.': 'Warning: Please add users via user groups wherever possible.',
                        'Warning: This observation will be permenantly removed': 'Warning: This observation will be permenantly removed',
                        'Warning: This recommendation will be permenantly removed': 'Warning: This recommendation will be permenantly removed',
                        'y Monitra Ltd. All rights reserved': '{{year}} Monitra Ltd. All rights reserved',
                        'Yes': 'Yes',
                        'You have unsaved changes. Are you sure you want to discard them?': 'You have unsaved changes. Are you sure you want to discard them?',
                        'Zip Code': 'Zip Code',
                        '(dev build)': '(dev build)',
                        '(production build)': '(production build)',
                    }
                }
            },
            lng: "en", // if you're using a language detector, do not define the lng option
            fallbackLng: "en",

            interpolation: {
                escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            }
        });
}

