import React from "react";
import { APIProvider } from '@vis.gl/react-google-maps';

import { useAppSelector as useSelector } from '../../hooks';
import { IGeoPoint, ILatLng, IAsset, TTableOptions } from '../../store/Interfaces';
import { TBoundsX } from '../../Grid/Grid';
import { Map, Sld } from "../";

export interface IMapNavProps {
    onClickMarker: (pin: IGeoPoint) => void;
    highlightLatLng?: ILatLng;
    getPinInfo: (pin: IGeoPoint) => { html: string, text: string };
    bounds: TBoundsX;
    onBoundsChanged: (bounds: TBoundsX) => void;
    assetUUIDs: string[];
    selectAssets: (uuids: string[]) => void;
    mapView: 'map' | 'sld';
    toggleMap: () => void;
    mapPanelExpanded: boolean;
    sldOnly: boolean;
    resultFilterText: string;
    selectedResultTable: TTableOptions;
}

export const MapNav: React.FunctionComponent<IMapNavProps> = (props) => {
    const { assets, selectedAssets } = useSelector(store => store.main);
    const mapProps = { ...props, toggleMap: props.toggleMap };
    let asset: IAsset | undefined = undefined;
    if (selectedAssets.length > 0) {
        asset = assets.find(a => a.uuid === selectedAssets[0])
    }

    return <>{props.mapView === 'map' ? <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ''}><Map {...mapProps} /></APIProvider> :
        <Sld asset={asset} toggleMap={props.toggleMap} selectAssets={props.selectAssets} mapPanelExpanded={props.mapPanelExpanded} sldOnly={props.sldOnly} />
    }
    </>;

}