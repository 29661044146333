import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Chart as ChartJS, ChartData, ChartOptions, RadialLinearScale, TimeScale
} from 'chart.js';
import 'chartjs-adapter-luxon';

import { LineChart } from "../../LineChart";

import { darken } from '../../Trends/TrendGraph';
import { IRunningConditions, IResultHistory } from "../../../store/Interfaces";
import { CheckboxDropdown } from "../CheckboxDropdown";
import { TDataType } from "../RunningConditions";

ChartJS.register(
    RadialLinearScale,
    TimeScale
);

const hexColours = ['#a289fd',
    '#1810d2',
    '#c1406f',
    '#31921d',
    '#aecb76',
    '#87befd',
    '#742980',
    '#edae75',
    '#fd616e',
    '#58b0a6',
    '#f00000',
    '#f9a5f9',
    '#4ada19'
];

type TDataSet = {
    label: string;
    type: "line";
    data: (number | null | { x: number | string, y: number })[];
    backgroundColor: string;
    borderColor: string;
};

interface IRunningConditionsGraphProps {
    runningConditionsUuids: string[];
    resultHistory: IResultHistory[];
    runningConditions: Record<string, IRunningConditions>;
    additionalRunningConditionUuid: string;
    fetchRunningConditions: () => void;
    loading: boolean;
    keyTranslate: Record<string, [string, TDataType]>;
}

export const RunningConditionsGraph: React.FunctionComponent<IRunningConditionsGraphProps> = ({ runningConditionsUuids, resultHistory, runningConditions, fetchRunningConditions, additionalRunningConditionUuid, loading, keyTranslate }) => {
    const { t } = useTranslation();
    const [dataset, setDataset] = useState<ChartData<"line"> | null>(null);
    const [options, setOptions] = useState<ChartOptions<"line"> | null>(null);
    //const [neededForGraph, setNeededForGraph] = useState(0);

    const [graphKeys, setGraphKeys] = useState<string[]>([]);

    const resultsNeeded = runningConditionsUuids.length - Object.keys(runningConditions).length;

    useEffect(() => {
        if (Object.keys(keyTranslate).length > 0) {
            setGraphKeys([Object.keys(keyTranslate)[0]]);
        } else {
            setGraphKeys([]);
        }
    }, [keyTranslate]);

    useEffect(() => {
        if (resultsNeeded === 0 && graphKeys.length > 0 && runningConditionsUuids.length > 0) {
            const createChartOptions = (): ChartOptions<"line"> => {
                return {
                    animation: false,
                    responsive: true,
                    layout: {
                        padding: 10
                    },
                    elements: {
                        point: {
                            radius: 1
                        },
                        line: {
                            borderWidth: 2,
                        }
                    }, scales: {
                        y: {

                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function (value, index, ticks) {
                                    if (typeof value === 'string') {
                                        return value;
                                    } else if (Math.abs(value) < 1e-4 || Math.abs(value) >= 1e5) {
                                        return value.toPrecision(2);
                                    }
                                    return Math.floor(value * 10000) / 10000;
                                }
                            }
                        },
                        x: {
                            type: 'time',
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 15,

                            }
                        },
                    },
                    plugins: {
                        legend: {
                            position: 'bottom' as const,
                            labels: { color: 'rgb(255, 43, 132)' },
                        },
                        title: {
                            display: false,
                            position: 'top',
                            align: 'start',
                            font: {
                                size: 18
                            },
                            text: graphKeys.join(', '),
                        },
                    },
                }
            }
            const createDataset = (yPoints: number[], allTimePoints: number[], graphKey: string, index: number): TDataSet => ({
                label: keyTranslate[graphKey][0],
                type: 'line' as const,
                data: (allTimePoints ?? []).map((timePoint, idx) => {
                    return { x: new Date(timePoint * 1000).toISOString(), y: yPoints[idx] }
                }),
                backgroundColor: hexColours[index % hexColours.length],
                borderColor: darken(hexColours[index % hexColours.length], 0.8)
            });
            const allTimePoints = resultHistory.map(rh => rh.endTime);

            const getDataPoints = (graphKey: string) => resultHistory.map(rh => {
                if (runningConditions[rh.runningConditionUuid] === undefined) {
                    return 0;
                }
                const v = runningConditions[rh.runningConditionUuid][graphKey as keyof IRunningConditions];
                if (typeof v === 'string') {
                    return Number(v);
                }
                return v;
            });
            const dataSets = graphKeys.map((graphKey, index) => createDataset(getDataPoints(graphKey), allTimePoints, graphKey, index));
            const options: ChartOptions<"line"> = createChartOptions();
            setOptions(options);
            setDataset({ datasets: dataSets } as ChartData<"line">);
        }
    }, [resultsNeeded, graphKeys]);

    return (
        <div>
            {resultsNeeded ?
                <button className='text-white disabled:text-hvpd-grey-200 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 text-sm py-1 px-2 border-solid border-1 font-medium' disabled={loading} onClick={fetchRunningConditions}>{t('Fetch c items?', { count: resultsNeeded })}</button>
                :
                <div>
                    <CheckboxDropdown name={t('Select data points')} items={Object.keys(keyTranslate).map(key => [keyTranslate[key][0], graphKeys.includes(key)])} onChange={items => setGraphKeys(items.filter(([_, active]) => active).map(([key, _]) => Object.entries(keyTranslate).find(([k, v]) => v[0] === key)?.[0] ?? ''))} />
                    {/*<select value={graphKey} className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border-1 w-[60%] mr-1' onChange={e => setGraphKey(e.target.value)}>
                        {Object.keys(keyTranslate).map(key => <option key={key} value={key}>{keyTranslate[key][0]}</option>)}
            </select> */}
                    {(dataset && options) ? <LineChart options={options} chart={dataset} /> : null}
                </div>}
        </div>
    );
}