import React, { useCallback, useEffect, useReducer, useRef } from "react";

import { useCookies } from "react-cookie";
import { useJsApiLoader } from "@react-google-maps/api";

import { useAppSelector as useSelector, useAppDispatch as useDispatch, useTimeout } from "../hooks";

import {
    setResultFilter,
    setTableFilterText,
    setMapFilterText,
    setSelectedResultRow,
    setSelectedResultTable,
    setReportTab,
    setSelectedAssets,
    setSlideOver,
    setMapView,
} from "../store/mainSlice";

import {
    IAsset,
    IColumnHeader,
    ICompany,
    IDevice,
    IGeoPoint,
    ISalesOrder,
    ISite,
    ISortColumn,
    IResult,
    TReportEntityGroup,
    TTableOptions,
    TResultRow,
    ILatLng,
    TTestType,
    TSlideOver,
    IBaseProject,
    IProject,
    IUserGroup,
} from "../store/Interfaces";

import { store } from "../store/store";
import { getURLParamCookie, deepEqual } from "../utils";
import { boundsXContains, boundsNSEW2BoundsLatLong } from "../Components/ResultsTable/utils";

import "./Grid.css";

import {
    AssetTable,
    CompanyTable,
    Dashboard,
    DeviceEdit,
    DeviceTable,
    ErrorList,
    FilterGroup,
    Footer,
    Grabber,
    MapNav,
    MainNav,
    Modal,
    OneReportTable,
    ProjectEdit,
    ProjectTable,
    ResultsTable,
    ReportGroup,
    SalesOrderEdit,
    SalesOrderTable,
    SiteTable,
    SlideOver,
    SubSiteTable,
    ToastList,
    UserGroupEdit,
    UserGroupTable,
    UserStatus,
    UserTable,
} from "../Components";

import { ControlPanel } from "./ControlPanel";

//const google = window.google;

const COOKIE_DELAY = 10 * 1000; // 10 secs
const MAP_POS_COOKIE_EXPIRY = 12 * 3600 * 1000; // 12 hours to remember the previous map position
const HIGHLIGHT_LAT_LNG_TIMER = 2000;
const HVPD_LAT_LNG = { lat: 53.474538051161474, lng: -2.3012999152525144 };
const INITIAL_MAP_ZOOM = 12;
const FALLBACK_MAP_ZOOM = 14;
const MAP_FILTER_TIMEOUT = 800;
const TABLE_FILTER_TIMEOUT = 300;

const ZOOM_ON_CLICK = 16;

export type TResultGroup = { company: ICompany; asset: IAsset; site: ISite; results: Array<IResult> };

export type TLatLng = { lat: number; lng: number };
export type TBounds = { ne: TLatLng; sw: TLatLng };

export type TBoundsX = {
    centre: google.maps.LatLngLiteral;
    bounds: google.maps.LatLngBoundsLiteral;
    zoom: number;
};

interface IGridState {
    selectedDevice: IDevice | undefined;
    selectedReportEntity: TReportEntityGroup | undefined;
    selectedSites: Array<ISite>;
    selectedProject: IBaseProject | undefined;
    selectedUserGroup: IUserGroup | undefined;
    selectedSalesOrder: ISalesOrder | undefined;
    splitPosition: number;
    mapFilterTimeout: number;
    tableFilterTimeout: number;
    bounds: TBoundsX;
    sortColumn: ISortColumn | null;
    launchTime: number;
    refresh: number;
    url: string;
    highlightLatLng: ILatLng | undefined;
    highlightLatLngTimer: number | null;
    mapFailure: boolean;
}

interface IGridAction {
    type:
    | "setSelectedDevice"
    | "setSelectedReportEntity"
    | "setSelectedSites"
    | "setSelectedProject"
    | "setSelectedSalesOrder"
    | "setSelectedUserGroup"
    | "setMapFilterTimeout"
    | "setSplitPosition"
    | "setTableFilterTimeout"
    | "setBounds"
    | "setSortColumn"
    | "setLaunchTime"
    | "setRefresh"
    | "setUrl"
    | "setHighlightLatLng"
    | "setHighlightLatLngTimer"
    | "setMapFailure";
    payload: any;
}

const initialState: IGridState = {
    selectedReportEntity: undefined,
    selectedSites: [],
    selectedDevice: undefined,
    selectedProject: undefined,
    selectedSalesOrder: undefined,
    selectedUserGroup: undefined,
    mapFilterTimeout: 0,
    tableFilterTimeout: 0,
    bounds: {
        bounds: { west: 0, east: 0, north: 0, south: 0 },
        centre: { lat: 0, lng: 0 } /*new google.maps.LatLng(HVPD_LAT_LNG.lat, HVPD_LAT_LNG.lng)*/,
        zoom: INITIAL_MAP_ZOOM,
    },
    //targetCentre: { lat: 0, lng: 0 },
    sortColumn: null,
    splitPosition: 0,
    launchTime: 0,
    refresh: 0,
    url: "",
    highlightLatLng: undefined,
    highlightLatLngTimer: null,
    mapFailure: false,
};

export const Grid: React.FunctionComponent = () => {
    const paneRef = useRef<HTMLDivElement>(null);
    const [cookies, setCookie] = useCookies(["mapPos", "urlParams"]);
    const dispatch = useDispatch();

    const { isLoaded: mapIsLoaded, loadError: mapError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    });


    // companies and currentUser are only used to extract the current user's company logo
    const {
        assets,
        sites,
        dashboardMode,
        editMode,
        extendedFilter,
        resultFilter,
        reportTab,
        sldViewUUIDs,
        tableEndPoints,
        selectedAssets,
        viewBundle: {
            selectedResultTable,
            selectedResultRow,
            mapView,
            mapPanelExpanded,
            resultFilterText,
            tableFilterText,
            mapFilterText,
            reportsDisabled,
            boundsFiltered,
            slideOver,
        },
    } = useSelector((store) => store.main);

    const reducer = (state: IGridState, action: IGridAction): IGridState => {
        if (action.type === "setSelectedReportEntity") {
            return { ...state, selectedReportEntity: action.payload };
        } else if (action.type === "setSelectedDevice") {
            return { ...state, selectedDevice: action.payload };
        } else if (action.type === "setSelectedSites") {
            return { ...state, selectedSites: action.payload };
        } else if (action.type === "setSelectedProject") {
            return { ...state, selectedProject: action.payload };
        } else if (action.type === "setSelectedUserGroup") {
            return { ...state, selectedUserGroup: action.payload };
        } else if (action.type === "setSelectedSalesOrder") {
            return { ...state, selectedSalesOrder: action.payload };
        } else if (action.type === "setMapFilterTimeout") {
            return { ...state, mapFilterTimeout: action.payload };
        } else if (action.type === "setTableFilterTimeout") {
            return { ...state, tableFilterTimeout: action.payload };
        } else if (action.type === "setBounds") {
            return { ...state, bounds: action.payload };
        } else if (action.type === "setSortColumn") {
            return { ...state, sortColumn: action.payload };
        } else if (action.type === "setLaunchTime") {
            return { ...state, launchTime: action.payload };
        } else if (action.type === "setRefresh") {
            return { ...state, refresh: action.payload };
        } else if (action.type === "setUrl") {
            return { ...state, url: action.payload };
        } else if (action.type === "setHighlightLatLng") {
            return { ...state, highlightLatLng: action.payload };
        } else if (action.type === "setHighlightLatLngTimer") {
            return { ...state, highlightLatLngTimer: action.payload };
        } else if (action.type === "setMapFailure") {
            return { ...state, mapFailure: action.payload };
        } else if (action.type === "setSplitPosition") {
            return { ...state, splitPosition: action.payload };
        } else {
            console.error("unknown action type", action.type);
        }

        return state;
        // ...
    };
    const [
        {
            selectedReportEntity,
            mapFilterTimeout,
            tableFilterTimeout,
            bounds,
            selectedDevice,
            selectedProject,
            selectedUserGroup,
            selectedSalesOrder,
            //  targetCentre,
            splitPosition,
            sortColumn,
            launchTime,
            refresh,
            url,
            highlightLatLng,
            highlightLatLngTimer,
            mapFailure,
        },
        localDispatch,
    ] = useReducer(reducer, initialState);

    const localSetSelectedResultRow = (row: TResultRow | undefined) => dispatch(setSelectedResultRow(row));
    const localSetTableFilterText = (text: string) => dispatch(setTableFilterText(text));
    const setMapFilterTimeout = (timeout: number) => localDispatch({ type: "setMapFilterTimeout", payload: timeout });
    const setTableFilterTimeout = (timeout: number) => localDispatch({ type: "setTableFilterTimeout", payload: timeout });
    const setSelectedDevice = (device: IDevice | undefined) => localDispatch({ type: "setSelectedDevice", payload: device });
    const setSelectedProject = (project: IBaseProject | undefined) => localDispatch({ type: "setSelectedProject", payload: project });
    const setSelectedUserGroup = (userGroup: IUserGroup | undefined) => localDispatch({ type: "setSelectedUserGroup", payload: userGroup });
    const setSelectedSalesOrder = (salesOrder: ISalesOrder | undefined) => localDispatch({ type: "setSelectedSalesOrder", payload: salesOrder });
    const setBounds = (bounds: TBoundsX) => localDispatch({ type: "setBounds", payload: bounds });
    const setSelectedReportEntity = (entity: TReportEntityGroup | undefined) => {
        if (!deepEqual(entity ?? {}, selectedReportEntity ?? {})) {
            localDispatch({ type: "setSelectedReportEntity", payload: entity });
        }
    }
    const setSelectedSites = (sites: Array<ISite>) => localDispatch({ type: "setSelectedSites", payload: sites });
    const setSortColumn = (column: ISortColumn | undefined) => localDispatch({ type: "setSortColumn", payload: column });
    const setLaunchTime = (time: number) => localDispatch({ type: "setLaunchTime", payload: time });
    const setRefresh = (refresh: number) => localDispatch({ type: "setRefresh", payload: refresh });
    const setUrl = (url: string) => localDispatch({ type: "setUrl", payload: url });
    const setHighlightLatLng = (latLng: TLatLng | undefined) => localDispatch({ type: "setHighlightLatLng", payload: latLng });
    const setHighlightLatLngTimer = (timer: number | null) => localDispatch({ type: "setHighlightLatLngTimer", payload: timer });
    const localSetSelectedResultTable = (table: TTableOptions) => dispatch(setSelectedResultTable(table));
    const setSlideOverLocal = (slideOver: TSlideOver) => dispatch(setSlideOver(slideOver));
    const localSetMapView = (view: "map" | "sld") => dispatch(setMapView(view));
    const setMapFailure = (failure: boolean) => localDispatch({ type: "setMapFailure", payload: failure });
    const setSplitPosition = (position: number) => localDispatch({ type: "setSplitPosition", payload: position });
    // const [backButtonClicked, setBackButtonClicked] = useState(false);

    useEffect(() => {
        const onBackButtonEvent = (e: PopStateEvent) => {
            e.preventDefault();
            window.history.pushState(null, "", window.location.pathname);
        };

        const n = Date.now();
        setLaunchTime(n);
        setSlideOverLocal("");
        setBounds({
            bounds: { east: 0, west: 0, north: 0, south: 0 },
            centre: { lat: HVPD_LAT_LNG.lat, lng: HVPD_LAT_LNG.lng },
            zoom: INITIAL_MAP_ZOOM,
        });
        if (paneRef.current) {
            setSplitPosition(paneRef.current?.getBoundingClientRect().width * .58);
        }
        // setTargetCentre({ lat: HVPD_LAT_LNG.lat, lng: HVPD_LAT_LNG.lng });

        window.history.pushState({ history: "initial" }, "", window.location.pathname);
        window.addEventListener("popstate", onBackButtonEvent);

        return () => {
            window.removeEventListener("popstate", onBackButtonEvent);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tableEndPoints.length > 0) {
            setTimeout(() => {
                // look for onlinePD
                let ep = tableEndPoints.find((e) => e.name === "OnlinePD");
                if (!ep) {
                    ep = tableEndPoints[0];
                }
                dispatch(setResultFilter(ep));
            });
        }
        const slideOverLocal: string = getURLParamCookie("slideOver", cookies, setCookie);
        if (slideOverLocal === "report" && !reportsDisabled) {
            setSlideOverLocal("report");
        }
        const tabLocal = getURLParamCookie("currentTab", cookies, setCookie);
        if (tabLocal) {
            dispatch(setReportTab(tabLocal));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableEndPoints]);

    useEffect(() => {
        if (assets.length && selectedAssets.length === 0) {
            const selectedAssetsLocal: string = getURLParamCookie("selectedAssets", cookies, setCookie);
            if (selectedAssetsLocal) {
                const localAssets = assets.filter((a) => selectedAssetsLocal.includes(a.uuid)).map((a) => a.uuid);
                dispatch(setSelectedAssets(localAssets));
            }
        }
    }, [assets, selectedAssets, cookies, setCookie, dispatch]);

    useEffect(() => {
        if (!dashboardMode && selectedResultTable === "one-report") {
            setSlideOverLocal("");
        } else if (selectedResultTable === "one-report") {
            dispatch(setSelectedAssets([]));
            setSlideOverLocal("report");
        }
    }, [dashboardMode]);

    useEffect(() => {
        const d = new Date();
        if (Date.now() - launchTime > COOKIE_DELAY) {
            d.setTime(d.getTime() + MAP_POS_COOKIE_EXPIRY);
            setCookie("mapPos", { bounds }, { expires: d });
        }
        const u = new URLSearchParams();
        u.append("zoom", bounds.zoom.toString());
        u.append("centre", `${bounds.centre.lat.toPrecision(5)},${bounds.centre.lng.toPrecision(5)}`);
        if (selectedAssets.length > 0) {
            u.append("selectedAssets", selectedAssets.join(","));
        }
        if (tableFilterText !== "") {
            u.append("filter", tableFilterText);
        }
        if (extendedFilter.length > 0) {
            u.append("extendedFilter", JSON.stringify(extendedFilter));
        }
        /*if (slideOver) {
            u.append('slideOver', slideOver);
            if (reportTab) {
                u.append('currentTab', reportTab);
            }
        } */
        setUrl(`${window.location.origin}/?${u.toString()}`);
        window.history.replaceState(null, "", `/?${u.toString()}`);
    }, [bounds, tableFilterText, slideOver, selectedAssets, reportTab, launchTime, extendedFilter, setCookie]);

    useEffect(() => {
        if (selectedResultTable === "one-report") {
            localSetTableFilterText("");
            setSlideOverLocal("report");
        } else if (selectedResultTable === "projects") {
            setSlideOverLocal("projectAdmin");
            setSelectedProject(undefined);
        } else if (selectedResultTable === "userGroups") {
            setSlideOverLocal("userGroupsAdmin");
            setSelectedUserGroup(undefined);
        }
        else if (selectedResultTable === "devices") {
            setSlideOverLocal("deviceAdmin");
            setSelectedUserGroup(undefined);

        } else if (selectedResultTable === "salesOrders") {
            setSlideOverLocal("salesOrderAdmin");
            setSelectedUserGroup(undefined);
        }
    }, [selectedResultTable]);

    useEffect(() => {
        setMapFilterTimeout(MAP_FILTER_TIMEOUT);
        setTableFilterTimeout(TABLE_FILTER_TIMEOUT);
    }, [resultFilterText]);

    useTimeout(() => {
        setMapFilterTimeout(0);
        setMapFilterText(resultFilterText);
    }, mapFilterTimeout);

    useTimeout(() => {
        setTableFilterTimeout(0);
        localSetTableFilterText(resultFilterText);
    }, tableFilterTimeout);

    const toggleMapView = () => {
        if (mapView === "map") {
            localSetMapView("sld");
        } else {
            localSetMapView("map");
        }
    };

    const removeLLHighlight = () => {
        if (highlightLatLng !== undefined) {
            setHighlightLatLng(undefined);
        }
        setHighlightLatLngTimer(null);
    };

    useTimeout(removeLLHighlight, highlightLatLngTimer);

    const setHighlightLatLngWithTimer = (ll: ILatLng | undefined) => {
        removeLLHighlight();
        if (ll !== undefined) {
            setHighlightLatLng(ll);
            setHighlightLatLngTimer(HIGHLIGHT_LAT_LNG_TIMER);
        }
    };

    /* const setResultAndCentre = (result: TResultGroup) => {
         setBounds({ ...bounds, centre: { lat: result.asset.gpsLat, lng: result.asset.gpsLong } })
         dispatch(setSelectedAssets([result.asset.uuid]));
         setSelectedSites(sites.filter(site => site.uuid === result.asset.siteUuid));
     } */

    const onBoundsChanged = (boundsIn: TBoundsX) => {
        if (boundsIn) {
            setBounds(boundsIn);
        }
    };
    // should be made inline
    const getPinInfo = (pin: IGeoPoint): { html: string; text: string } => {
        const { main } = store.getState();
        const { tableDefinitions, tableResults, resultFilter } = main;
        if (pin.type === "Site") {
            const site = sites.find((s) => s.uuid === pin.uuid);
            if (site) {
                return {
                    html: `<div class='hoverRow'><div class='hoverTitle'>${pin.type}</div><div>${site.name}</div></div>`,
                    text: site?.name ?? "",
                };
            } else {
                return { html: `<div class='hoverRow'><div class='hoverTitle'>${pin.type}</div><div>${pin.name}</div></div>`, text: pin.name ?? "" };
            }
        } else if (pin.type === "Asset") {
            const results = resultFilter ? tableResults[resultFilter.name as TTestType] : { rows: [] };
            const columnHeaders = resultFilter ? tableDefinitions[resultFilter.name].columnHeaders : [];
            const row = results.rows.find((row) => row.assetUuid === pin.uuid);
            let rowData: string = '';
            if (row) {
                rowData = `<div><div class='hoverTitle'>${pin.type}</div>${columnHeaders
                    .map(
                        (ch) =>
                            `<div class='hoverRow' key=${ch.title}><div class='hoverTitle'>${ch.title}</div><div class='hoverValue'>${row[ch.propDisplay]}</div></div>`
                    ).join('')}</div>`;


                const text = columnHeaders.map((ch) => `${row[ch.propDisplay]}`).join("\n");
                return { html: `<div class='hover'>${rowData}</div>`, text };
            } else {
                return { html: `<div class='hoverRow'><div class='hoverTitle'>${pin.type}</div><div>${pin.name}</div></div>`, text: "" };
            }
        }
        return { html: `<div class='hoverRow'><div class='hoverTitle'>${pin.type}</div><div>${pin.name}</div></div>`, text: "" };
    };

    const onClickMarker = (pin: IGeoPoint) => {
        if (pin.type === "Site") {
            const site = sites.find((s) => s.uuid === pin.uuid);
            if (site) {
                setSelectedSites([site]);
                setBounds({ ...bounds, centre: { lat: site.gpsLat, lng: site.gpsLong }, zoom: bounds.zoom > ZOOM_ON_CLICK ? bounds.zoom : ZOOM_ON_CLICK });
            }
        } else {
            const asset = assets.find((a) => a.uuid === pin.uuid);
            if (asset) {
                dispatch(setSelectedAssets([asset.uuid]));
                setBounds({ ...bounds, centre: { lat: asset.gpsLat, lng: asset.gpsLong, }, zoom: bounds.zoom > ZOOM_ON_CLICK ? bounds.zoom : ZOOM_ON_CLICK });
                setSelectedSites(sites.filter((site) => site.uuid === asset.siteUuid));
            }
        }
    };

    const setSelectedAssetsByUUID = (uuids: string[]) => {
        if (selectedAssets.length !== uuids.length || selectedAssets.some((selectedAsset) => !uuids.includes(selectedAsset))) {
            const filteredAssets = assets.filter((a) => uuids.includes(a.uuid));
            dispatch(setSelectedAssets(filteredAssets.map((a) => a.uuid)));
        }
    };
    const memoisedSetSelectedAssetsByUUI = useCallback(setSelectedAssetsByUUID, [selectedAssets, assets, dispatch]);

    const getReportRow = (e: React.MouseEvent, row: TResultRow) => {
        const hasSLD = row?.statusTooltip?.includes("SLD available");
        const mapAvailable = !mapFailure && !mapError;
        const chosenAsset = assets.find((a) => a.uuid === row.assetUuid);
        // we have clicked the same row again OR map loading failed
        if (chosenAsset && (selectedAssets.some((sa) => sa === chosenAsset.uuid) || (!mapAvailable && !hasSLD) || (slideOver.length > 1 && slideOver !== 'report'))) {
            if (!reportsDisabled && slideOver !== "report") {
                setSlideOverLocal("report");
            } else if (slideOver !== "report") {
                setSlideOverLocal("");
            }
        } else {
            // its a new row
            if (chosenAsset && bounds.bounds) {
                if (!boundsXContains(bounds, chosenAsset.gpsLat, chosenAsset.gpsLong)) {
                    setBounds({
                        ...bounds,
                        centre: { lat: row.gpsLat, lng: row.gpsLong },
                        zoom: FALLBACK_MAP_ZOOM,
                    });
                }
                //setTargetCentre({lat: row.gpsLat, lng: row.gpsLong });
            }
            // if the map is disabled then show a SLD if it's available

            if (!mapAvailable && hasSLD) {
                localSetMapView("sld");
            } else if (!hasSLD) {
                localSetMapView("map");
            }

        }

        setSelectedReportEntity({ type: "results", uuid: row.assetUuid, resultUuid: row.resultUuid });
        localSetSelectedResultRow(row);
        const slds = undefined !== row["slds"] ? parseInt(row["slds"].toString(), 10) : 0;
        if (slds === 0) {
            //              setMapView('map');
        }
    };

    const focusOnEntity = (row: TResultRow) => {
        const ll: TLatLng = {
            lat: typeof row.gpsLat === "number" ? row.gpsLat : parseFloat(row.gpsLat ?? 0),
            lng: typeof row.gpsLong === "number" ? row.gpsLong : parseFloat(row.gpsLong ?? 0),
        };
        if (bounds) {
            if (!boundsXContains(bounds, ll.lat, ll.lng)) {
                setBounds({ ...bounds, centre: ll, zoom: FALLBACK_MAP_ZOOM });
                // setTargetCentre(ll);
            }
        }
    };

    const onClickAssetRow = (e: React.MouseEvent, row?: TResultRow) => {
        if (row) {
            focusOnEntity(row);
            setSelectedReportEntity({ type: "assets", uuid: row.uuid.toString() });
        }
        if (!reportsDisabled) {
            setSlideOverLocal("report");
        }
    };

    const onClickCompanyRow = (e: React.MouseEvent, row?: TResultRow) => {
        if (row) {
            focusOnEntity(row);
            setSelectedReportEntity({ type: "companies", uuid: row.uuid.toString() });
        }
        if (!reportsDisabled) {
            setSlideOverLocal("report");
        }
    };

    const onClickSiteRow = (e: React.MouseEvent, row?: TResultRow) => {
        if (row) {
            focusOnEntity(row);
            setSelectedReportEntity({ type: "sites", uuid: row.uuid.toString() });
        }
        if (!reportsDisabled) {
            setSlideOverLocal("report");
        }
    };

    const onClickSubSiteRow = (e: React.MouseEvent, row?: TResultRow) => {
        if (row) {
            focusOnEntity(row);;
            setSelectedReportEntity({ type: "sub-sites", uuid: row.uuid.toString() });
        }
        if (!reportsDisabled) {
            setSlideOverLocal("report");
        }
    };

    const onClickUserRow = (e: React.MouseEvent, row?: TResultRow) => {
        if (row) {
            setSelectedReportEntity({ type: "users", uuid: row.uuid.toString() });
        }
        if (!reportsDisabled) {
            setSlideOverLocal("report");
        }
    };

    const onClickDeviceRow = (e: React.MouseEvent, device?: IDevice) => {
        if (device) {
            setSelectedDevice(device);
        }
        if (!reportsDisabled) {
            setSlideOverLocal("deviceAdmin");
        }
    };

    const onClickProjectRow = (e: React.MouseEvent, project?: IBaseProject) => {
        if (project) {
            setSelectedProject(project);
        }
        if (!reportsDisabled) {
            setSlideOverLocal("projectAdmin");
        }
    };

    const onClickUserGroupRow = (userGroup?: IUserGroup) => {
        if (userGroup) {
            setSelectedUserGroup(userGroup);
        }
        if (!reportsDisabled) {
            setSlideOverLocal("userGroupsAdmin");
        }
    };

    const onClickSalesOrderRow = (e: React.MouseEvent, salesOrder?: ISalesOrder) => {
        if (salesOrder) {
            setSelectedSalesOrder(salesOrder);
        }
        if (!reportsDisabled) {
            setSlideOverLocal("salesOrderAdmin");
        }
    };

    const clickTableColumn = (column: IColumnHeader) => {
        if (sortColumn != null && sortColumn.title === column.title) {
            switch (sortColumn.direction) {
                case -1:
                    setSortColumn(undefined);
                    break;
                case 1:
                    setSortColumn({ ...sortColumn, direction: -1 });
                    break;
            }
        } else {
            setSortColumn({ title: column.title, direction: 1 });
        }
    };

    const quickFilterColours =
        resultFilterText.length === 0
            ? "bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600"
            : "bg-hvpd-malibu-500 border-hvpd-malibu-200/40 hover:bg-hvpd-malibu-600";
    const filterColours =
        extendedFilter.length === 0
            ? "bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600"
            : "bg-hvpd-malibu-500 border-hvpd-malibu-200/40 hover:bg-hvpd-malibu-600";

    const hasMapFilter = !["one-report", "users"].includes(selectedResultTable);

    const canShowMapPanel = () => {
        return mapView !== "map" || (mapIsLoaded && !mapError && !mapFailure);
    };

    const gridColumns = () => {
        if (!canShowMapPanel()) {
            return "1fr";
        }
        return `${splitPosition}px 1fr`;
        // return mapPanelExpanded || selectedResultTable === "one-report" ? "grid-cols-[5fr_7fr]" : "grid-cols-[7fr_5fr]";
    };
    //@ts-ignore
    window.gm_authFailure = (v) => {
        setMapFailure(true);
    };
    const baseProjectToProject = (baseProject: IBaseProject): IProject => {
        return { ...baseProject, active: true, users: [], assets: [], companies: [], userGroups: [], salesOrders: [], hvpdSONumber: '0', results: [] };
    }
    const disableSlideOverClose = () =>
        ['projectAdmin', 'userGroupsAdmin', 'salesOrderAdmin'].includes(slideOver) ||
        (slideOver === "report" && dashboardMode && selectedResultTable === "one-report");

    //const {isBackButtonClicked} = usePopState({alertDescription: 'Go back?', callback: () => {window.history.forward(); }, isPrompt: true });
    const getSlideOverPosition = () =>
        (paneRef.current?.getBoundingClientRect()?.width ?? 100) - splitPosition - 6;

    // <Grabber position={splitPosition} setPosition={setSplitPosition} maxPercentage={90} minPercentage={10} />

    return (
        <div
            ref={paneRef}
            className="grid-rows_[72px_0.9fr_40px;] h-screen;  height: 100vh;
        display: grid;
        grid-template-rows: 72px
        0.9fr 40px; grid w-screen"
        >
            <ToastList />
            <ErrorList />
            <div className="header background-black px-4">
                <div className="float-left">
                    <div className="py-2">
                        <img alt="Monitra logo" className="h-14 w-auto" src={process.env.PUBLIC_URL + "/monitra-logo-small.svg"} />
                    </div>
                </div>
                <MainNav setResultTable={localSetSelectedResultTable} resultType={selectedResultTable} />
                <div className="float-right py-2">
                    <UserStatus />
                </div>
            </div>

            <div className="body grid grid-rows-[1fr_40px]">
                <>
                    <div>
                        <Grabber position={splitPosition} setPosition={setSplitPosition} maxPercentage={90} minPercentage={10} />
                        <div className={"grid"} style={{ gridTemplateColumns: gridColumns() }}>
                            <div
                                className={`grid ${hasMapFilter ? "grid-rows-[4rem_1fr]" : "grid-rows-[2.2rem_1fr]"} h-[calc(100vh-112px)] overflow-hidden`}
                            >
                                <ControlPanel
                                    bumpRefresh={() => setRefresh(refresh + 1)}
                                    quickFilterColours={quickFilterColours}
                                    filterColours={filterColours}
                                />

                                {selectedResultTable === "results" && (
                                    <ResultsTable
                                        bounds={boundsNSEW2BoundsLatLong(bounds)}
                                        boundsFiltered={boundsFiltered ? mapView : ""}
                                        allowedAssets={Object.values(sldViewUUIDs).flat()}
                                        tableEndPoint={resultFilter}
                                        getReport={getReportRow}
                                        resultFilterText={tableFilterText}
                                        onClickTableColumn={clickTableColumn}
                                        sortColumn={sortColumn}
                                        setHighlightLatLng={setHighlightLatLngWithTimer}
                                        refresh={refresh}
                                    />
                                )}
                                {selectedResultTable === "companies" && (
                                    <CompanyTable
                                        bounds={boundsNSEW2BoundsLatLong(bounds)}
                                        boundsFiltered={boundsFiltered ? mapView : ""}
                                        allowedAssets={Object.values(sldViewUUIDs).flat()}
                                        tableEndPoint={resultFilter}
                                        onClickRow={onClickCompanyRow}
                                        resultFilterText={tableFilterText}
                                        onClickTableColumn={clickTableColumn}
                                        sortColumn={sortColumn}
                                        setHighlightLatLng={setHighlightLatLngWithTimer}
                                        setSlideOver={setSlideOverLocal}
                                        selectedEntity={selectedReportEntity}
                                        refresh={refresh}
                                    />
                                )}
                                {selectedResultTable === "assets" && (
                                    <AssetTable
                                        bounds={boundsNSEW2BoundsLatLong(bounds)}
                                        boundsFiltered={boundsFiltered ? mapView : ""}
                                        allowedAssets={Object.values(sldViewUUIDs).flat()}
                                        tableEndPoint={resultFilter}
                                        onClickRow={onClickAssetRow}
                                        resultFilterText={tableFilterText}
                                        onClickTableColumn={clickTableColumn}
                                        sortColumn={sortColumn}
                                        setHighlightLatLng={setHighlightLatLngWithTimer}
                                        setSlideOver={setSlideOverLocal}
                                        selectedEntity={selectedReportEntity}
                                        refresh={refresh}
                                    />
                                )}
                                {selectedResultTable === "sites" && (
                                    <SiteTable
                                        bounds={boundsNSEW2BoundsLatLong(bounds)}
                                        boundsFiltered={boundsFiltered ? mapView : ""}
                                        allowedAssets={Object.values(sldViewUUIDs).flat()}
                                        tableEndPoint={resultFilter}
                                        onClickRow={onClickSiteRow}
                                        resultFilterText={tableFilterText}
                                        onClickTableColumn={clickTableColumn}
                                        sortColumn={sortColumn}
                                        setHighlightLatLng={setHighlightLatLngWithTimer}
                                        setSlideOver={setSlideOverLocal}
                                        selectedEntity={selectedReportEntity}
                                        refresh={refresh}
                                    />
                                )}
                                {selectedResultTable === "sub-sites" && (
                                    <SubSiteTable
                                        bounds={boundsNSEW2BoundsLatLong(bounds)}
                                        boundsFiltered={boundsFiltered ? mapView : ""}
                                        allowedAssets={Object.values(sldViewUUIDs).flat()}
                                        tableEndPoint={resultFilter}
                                        onClickRow={onClickSubSiteRow}
                                        resultFilterText={tableFilterText}
                                        onClickTableColumn={clickTableColumn}
                                        sortColumn={sortColumn}
                                        setHighlightLatLng={setHighlightLatLngWithTimer}
                                        setSlideOver={setSlideOverLocal}
                                        selectedEntity={selectedReportEntity}
                                        refresh={refresh}
                                    />
                                )}
                                {selectedResultTable === "users" && (
                                    <UserTable
                                        bounds={boundsNSEW2BoundsLatLong(bounds)}
                                        boundsFiltered={boundsFiltered ? mapView : ""}
                                        allowedAssets={Object.values(sldViewUUIDs).flat()}
                                        tableEndPoint={resultFilter}
                                        onClickRow={onClickUserRow}
                                        resultFilterText={tableFilterText}
                                        onClickTableColumn={clickTableColumn}
                                        sortColumn={sortColumn}
                                        setSlideOver={setSlideOverLocal}
                                        selectedEntity={selectedReportEntity}
                                        refresh={refresh}
                                    />
                                )}
                                {selectedResultTable === "one-report" && (
                                    <OneReportTable
                                        bounds={boundsNSEW2BoundsLatLong(bounds)}
                                        boundsFiltered={boundsFiltered ? mapView : ""}
                                        getReport={getReportRow}
                                        tableEndPoint={resultFilter}
                                        resultFilterText={tableFilterText}
                                        onClickTableColumn={clickTableColumn}
                                        sortColumn={sortColumn}
                                        setHighlightLatLng={setHighlightLatLngWithTimer}
                                        selectedEntity={selectedReportEntity}
                                    />
                                )}
                                {selectedResultTable === "devices" && (
                                    <DeviceTable onClickTableColumn={clickTableColumn}
                                        onClickRow={onClickDeviceRow}
                                        resultFilterText={tableFilterText}
                                        sortColumn={sortColumn} />)
                                }
                                {selectedResultTable === "projects" && (
                                    <ProjectTable onClickTableColumn={clickTableColumn}
                                        onClickRow={onClickProjectRow}
                                        resultFilterText={tableFilterText}
                                        sortColumn={sortColumn} />)
                                }
                                {selectedResultTable === "salesOrders" && (
                                    <SalesOrderTable onClickTableColumn={clickTableColumn}
                                        onClickRow={onClickSalesOrderRow}
                                        resultFilterText={tableFilterText}
                                        sortColumn={sortColumn} />)
                                }
                                {selectedResultTable === "userGroups" && (
                                    <UserGroupTable onClickTableColumn={clickTableColumn}
                                        onClickRow={onClickUserGroupRow}
                                        resultFilterText={tableFilterText}
                                        sortColumn={sortColumn} />)
                                }

                                {/*
                                !['one-report', 'users'].includes(selectedResultTable) ? <div>
                                    <div className="filter-map form-check form-switch mx-2">
                                        <input defaultChecked={boundsFiltered} type="checkbox" role="switch" value={boundsFiltered ? 'true' : 'false'} disabled={selectedResultTable === 'users'} onChange={flipBoundsFilter}
                                            className="text-white disabled:text-hvpd-grey-700 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 focus:hvpd-grey-500 form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain focus:outline-none cursor-pointer shadow-sm" id="flexSwitchCheckDefault" />
                                        <label className="form-check-label inline-block text-gray-800" htmlFor="flexSwitchCheckDefault">Showing all {!boundsFiltered ? 'un' : ''}filtered {mapView === 'map' ? 'by map bounds' : 'by connection'}</label>
                                    </div>
                                </div> : null
                        */}
                            </div>
                            {canShowMapPanel() ? (
                                <div className="background-light-grey relative overflow-hidden">
                                    <MapNav
                                        onBoundsChanged={onBoundsChanged}
                                        bounds={bounds}
                                        onClickMarker={onClickMarker}
                                        getPinInfo={getPinInfo}
                                        highlightLatLng={highlightLatLng}
                                        assetUUIDs={Object.values(sldViewUUIDs).flat()}
                                        selectAssets={memoisedSetSelectedAssetsByUUI}
                                        mapView={mapView}
                                        toggleMap={toggleMapView}
                                        mapPanelExpanded={mapPanelExpanded}
                                        sldOnly={!!mapError || mapFailure}
                                        resultFilterText={tableFilterText}
                                        selectedResultTable={selectedResultTable}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <Footer url={url} />
                </>
            </div>
            <SlideOver
                active={slideOver !== ""}
                onClose={() => setSlideOverLocal("")}
                expanded={mapPanelExpanded || selectedResultTable === "one-report"}
                rightOffset={false}
                disableClose={disableSlideOverClose()}
                position={getSlideOverPosition()}
            >
                <>
                    {slideOver === "" ? <></> : null}

                    {slideOver === "filters" ? <FilterGroup /> : null}
                    {slideOver === "report" ? (
                        <>
                            {selectedResultTable === "one-report" && dashboardMode ? (
                                <Dashboard tableEndPoint={resultFilter} expanded={true} />
                            ) : (
                                <ReportGroup
                                    focusEntity={selectedReportEntity}
                                    clearFocus={() => setSelectedReportEntity(undefined)}
                                    resultEndpointName={(resultFilter?.name ?? "OnlinePD") as TTestType}
                                    resultRow={selectedResultRow}
                                    expanded={true}
                                />
                            )}
                        </>
                    ) : null}
                    {slideOver === 'deviceAdmin' && selectedDevice ?
                        <DeviceEdit device={selectedDevice} endEdit={() => dispatch(setSlideOver(''))} clearFocus={() => setSelectedDevice(undefined)} /> : null}
                    {slideOver === "projectAdmin" && selectedProject ?
                        <ProjectEdit project={baseProjectToProject(selectedProject)} endEdit={() => dispatch(setSlideOver(''))} clearFocus={() => setSelectedProject(undefined)} /> : null}
                    {slideOver === "salesOrderAdmin" ?
                        <SalesOrderEdit salesOrder={selectedSalesOrder} endEdit={() => dispatch(setSlideOver(''))} clearFocus={() => setSelectedSalesOrder(undefined)} /> : null}
                    {slideOver === "userGroupsAdmin" ?
                        <UserGroupEdit userGroup={selectedUserGroup} endEdit={() => dispatch(setSlideOver(''))} clearFocus={() => setSelectedProject(undefined)} /> : null}
                </>
            </SlideOver>

            <Modal />

        </div >
    );
};

/*                                    <>{selectedResultTable === 'one-report' ? <Dashboard tableEndPoint={resultFilter} /> :
 <ReportGroup focusEntity={selectedReportEntity} resultEndpointName={(resultFilter?.name ?? 'OnlinePD') as TTestType} resultRow={selectedResultRow} expanded={mapPanelExpanded} />}
</> : null}*/
