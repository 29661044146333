import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector as useSelector, useAppDispatch as useDispatch, useInterval } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import { removeError } from '../../store/mainSlice';

export interface IErrorListProps {

}

export const ErrorList: React.FunctionComponent<IErrorListProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { errorList } = useSelector(store => store.main);
    const [tick, setTick] = useState(0);
    useInterval(() => {
        const now = Date.now();
        errorList.filter(e => e.ttl < now).forEach(e => dispatch(removeError(e.id)));
        setTick(tick + 1);
    }, 500);


    const endError = (id: number) => dispatch(removeError(id));

    return (errorList.length > 0 ? <div className='fixed top-8 right-4 left-4 z-[2010]'>
        {errorList.map(error =>
            <div key={`error-${error.id}`} className='min-w-[80%] px-7 py-4 mx-2 mb-2 block bg-red-200 border-red-300 rounded-md'>{error.message}
                <button title={t('Dismiss error')} className='float-right text-red-700 hover:text-red-800 hover:border-red-900' onClick={() => endError(error.id)}>{Date.now() > error.ttl - 2500 ? <FontAwesomeIcon className="fa-spin" icon={solid('circle-notch')} /> : <FontAwesomeIcon icon={solid('xmark')} />}</button>
            </div>
        )}
    </div> : null);

}