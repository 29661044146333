import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../hooks';

import { ResultsTableHeader } from "../ResultsTableHeader";
import { ResultsTableRow } from "../ResultsTableRow";
import { getFilteredSortedResults } from "../utils";

import { IColumnHeader, TResultRow, ISortColumn, IDevice } from '../../../store/Interfaces';
import { getDevices } from "../../../api";
import { setDevices } from "../../../store/mainSlice";

const columnHeaders: IColumnHeader[] = [{
    title: 'Product code', type: 'String', propValue: 'productCode', propDisplay: 'productCode'
}, {
    title: 'Serial no', type: 'String', propValue: 'serialNumber', propDisplay: 'serialNumber'
}, {
    title: 'Production status', type: 'String', propValue: 'productionStatus', propDisplay: 'productionStatus'
}, {
    title: 'Latitude', type: 'String', propValue: 'latitude', propDisplay: 'latitude'
}, {
    title: 'Longitude', type: 'String', propValue: 'longitude', propDisplay: 'longitude'
}, {
    title: 'Test rig', type: 'Boolean', propValue: 'testRig', propDisplay: 'testRig'
}, {
    title: 'Comissioned', type: 'Boolean', propValue: 'commissioned', propDisplay: 'commissioned'
}, {
    title: 'Deployment status', type: 'String', propValue: 'deploymentStatus', propDisplay: 'deploymentStatus'
}, {
    title: 'Calibration date', type: 'Date', propValue: 'calibrationDate', propDisplay: 'calibrationDate'
}, {
    title: 'Calibration due date', type: 'Date', propValue: 'calibrationDueDate', propDisplay: 'calibrationDueDate'
}, {
    title: 'Calibration user name', type: 'String', propValue: 'calibrationUsername', propDisplay: 'calibrationUsername'
}, {
    title: 'IP address', type: 'String', propValue: 'ipAddress', propDisplay: 'ipAddress'
}, {
    title: 'Team viewer address', type: 'String', propValue: 'teamViewerAddress', propDisplay: 'teamViewerAddress'
}, {
    title: 'Team viewer password', type: 'String', propValue: 'teamViewerPassword', propDisplay: 'teamViewerPassword'
}, {
    title: 'Modem details', type: 'String', propValue: 'modemDetails', propDisplay: 'modemDetails'
}, {
    title: 'Notes', type: 'String', propValue: 'notes', propDisplay: 'notes'
}, {
    title: 'Sales order UUID', type: 'String', propValue: 'salesOrderUuid', propDisplay: 'salesOrderUuid'
}, {
    title: 'UUID', type: 'String', propValue: 'uuid', propDisplay: 'uuid'
}];

interface IDeviceTableProps {
    resultFilterText: string;
    onClickTableColumn: (colHeader: IColumnHeader) => void;
    sortColumn: ISortColumn | null;
    onClickRow: (e: React.MouseEvent, device?: IDevice) => void;
}

export const DeviceTable: React.FunctionComponent<IDeviceTableProps> = ({ onClickTableColumn, resultFilterText, onClickRow, sortColumn }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, devices } = useSelector(state => state.main);
    const tableHeader = useRef<HTMLTableRowElement>(null);
    const [expandedHeaders, setExpandedHeaders] = useState<Record<string, boolean>>({});
    const [ac, setAc] = useState<AbortController>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            localAc.abort();
        }
    }, []);

    useEffect(() => {
        const ac = new AbortController();
        const localGetDevices = async (ac: AbortController) => {
            setLoading(true);
            getDevices(token).then((res) => {
                // dispatch(setUserGroups(res));
                if (Array.isArray(res)) {
                    dispatch(setDevices(res));
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                console.error(err);
            });
        };
        localGetDevices(ac);
    }, [token, ac]);


    const flipExpandedHeader = (e: React.MouseEvent, title: string) => {
        e.stopPropagation();
        setExpandedHeaders({ ...expandedHeaders, [title]: !expandedHeaders[title] })
    }

    const deviceToDisplay = (device: IDevice): TResultRow => {
        return {
            productCode: device.productCode,
            serialNumber: device.serialNumber,
            productionStatus: device.productionStatus,
            latitude: device.latitude,
            longitude: device.longitude,
            testRig: device.testRig ? 'Yes' : 'No',
            commissioned: device.commissioned ? 'Yes' : 'No',
            deploymentStatus: device.deploymentStatus,
            calibrationDate: new Date(device.calibrationDate * 1000).toLocaleDateString(),
            calibrationDueDate: new Date(device.calibrationDueDate * 1000).toLocaleDateString(),
            calibrationUsername: device.calibrationUsername,
            ipAddress: device.ipAddress,
            teamViewerAddress: device.teamViewerAddress,
            teamViewerPassword: device.teamViewerPassword,
            modemDetails: device.modemDetails,
            notes: device.notes,
            salesOrderUuid: device.salesOrderUuid,
            uuid: device.uuid,
            gpsLat: device.latitude, gpsLong: device.longitude, resultUuid: '', siteUuid: '', assetUuid: '', companyUuid: '', tests: 0
        }
    }

    const displayDevices = getFilteredSortedResults(devices.map(deviceToDisplay), null, '', resultFilterText, [], sortColumn, columnHeaders);

    return (
        <>{loading ? <div className='container mt-20 text-center'><FontAwesomeIcon className='fa-spin fa-4x text-hvpd-red-400' icon={solid('spinner')} /></div > :
            (<div className='resultsTableClipper relative overflow-y-auto bg-hvpd-grey-50'>
                {displayDevices.length > 0 ? <table className='table-auto min-w-full border-slate-400 border'>
                    <thead className='bg-colour-c01dc2f border-b sticky top-[-1px]'>
                        <tr ref={tableHeader}>
                            {columnHeaders.map((colHeader, i) => (
                                <ResultsTableHeader key={colHeader.title} onClick={() => onClickTableColumn(colHeader)} expandedHeaders={expandedHeaders} isExpanded={expandedHeaders[colHeader.title]} colHeader={colHeader} sortColumn={sortColumn} flipExpandedHeader={flipExpandedHeader} />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayDevices.map((device, idx) => (
                            <ResultsTableRow key={idx} row={device} columnHeaders={columnHeaders}
                                handleContextMenu={(e: MouseEvent, row: TResultRow) => { }}
                                expandedHeaders={expandedHeaders}
                                onClickRow={(e) => onClickRow(e, devices.find(d => d.uuid === device.uuid) as IDevice)}
                                selected={false}
                            />
                        ))}
                    </tbody>
                </table> :
                    <div className='w-full flex mt-20'>
                        <div className='w-full flex mt-20'>
                            <div className='flex-grow text-center'><span>{t('No results to display')}</span>
                                <button onClick={onClickRow} className='ms-2 flex-grow h-7 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md'>{t('Show panel')}</button>
                            </div>
                        </div>
                    </div>}
            </div>)
        }</>);

}