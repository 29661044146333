import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from "react-i18next";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../hooks";
import { addToast, addError, setAssets, setTableDefinitionComplete } from "../../../store/mainSlice";
import { ICompany, ISite, ISubSite, IAsset } from "../../../store/Interfaces";
import { FormTemplate, IFormField, TEntity, FormContext } from "../FormTemplate";
import { createAsset, updateAsset, deleteAsset } from "../../../api";

import { Busy } from "../../Busy";
import { TemplateOperationsModal } from "./TemplateOperationsModal";
import { MultiAssetModal } from "./MultiAssetModal";
import { assetEditForm, mapEnumsToForm } from "../../../templates/formTemplates";

const TABLENAME = 'TableAsset';
/*
const motorClasses = ['Gas Turbine', 'Generator', 'Hydro Generator', 'Induction', 'Pump Storage', 'Steam Turbine', 'Synchonous', 'Unknown', 'Wind Turbine'];
const rotatingMachineFormFragment: IFormField =
    { type: 'Select', propValue: 'motorClass', propDisplay: 'Motor/Generator Class', options: motorClasses.map(m => ({ name: m, value: m })) };
*/
interface IAssetEditProps {
    assetUuid?: string;
    endEdit: () => void;
    clearFocus: () => void;
}

export const AssetEdit: React.FunctionComponent<IAssetEditProps> = ({ assetUuid, endEdit, clearFocus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companies, sites, assets, subSites, token, tableDefinitionsComplete } = useSelector(store => store.main);
    const [wipAsset, setWipAsset] = useState<IAsset>();
    const [assetTemplate, setAssetTemplate] = useState<IAsset>();
    const [showTemplateOperationsModal, setShowTemplateOperationsModal] = useState(false);
    const [formWithOptions, setFormWithOptions] = useState<Array<IFormField>>(assetEditForm);
    const [dependantFields, setDependantFields] = useState<Array<{ name: string, value: string }>>([]);
    const [busy, setBusy] = useState(false);
    const [entityChanged, setEntityChanged] = useState(false);
    const [showAssetSelectorModal, setShowAssetSelectorModal] = useState(false);
    // boilerplate for all form contexts
    const [wipEntity, setWipEntity] = useState<TEntity>({});
    const [confirmOverwrite, setConfirmOverwrite] = useState(false);
    const [confirmClear, setConfirmClear] = useState(false);
    const [confirmEndEdit, setConfirmEndEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        if (assetUuid && assets.length > 0) {
            const s1 = assets.find(s => s.uuid === assetUuid);
            if (s1) {
                setWipAsset(s1);
                const constrained: Array<{ name: string, value: string }> = [];
                if (s1.companyUuid) {
                    constrained.push({ name: 'companyUuid', value: s1.companyUuid });
                }
                if (s1.siteUuid) {
                    constrained.push({ name: 'siteUuid', value: s1.siteUuid });
                }
                if (s1.assetType) {
                    constrained.push({ name: 'assetType', value: s1.assetType });
                }
                setDependantFields(constrained);
            }
        }
    }, [assetUuid, assets])

    const companySort = (a: ICompany, b: ICompany) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }
    const siteSort = (a: ISite, b: ISite) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }
    const subSiteSort = (a: ISubSite, b: ISubSite) => {
        return (a?.name ?? '').localeCompare(b?.name ?? '');
    }

    const onFieldChange = (field: string, value: string) => {
        if (field === 'companyUuid' || field === 'siteUuid') {
            setDependantFields([...dependantFields.filter(df => df.name !== field), { name: field, value: value }]);
        }
        if (field === 'assetType') {
            setDependantFields([...dependantFields.filter(df => df.name !== field), { name: field, value: value }]);
            return [{ name: 'assetSubType', value: '' }];
        }
        if (field === 'companyUuid') {
            return [{ name: 'siteUuid', value: '' }, { name: 'subSiteUuid', value: '' }];
        }
        if (field === 'siteUuid') {
            return [{ name: 'subSiteUuid', value: '' }];
        }
        return [];
    }

    const copyAssetToTemplate = () => {
        if (assetTemplate) {
            setAssetTemplate(undefined);
            setShowTemplateOperationsModal(false);
        } else {
            setAssetTemplate(wipAsset);
        }
    }
    const beginTemplateOperation = () => {
        setShowTemplateOperationsModal(true);
    }
    const closeTemplateOperationsModal = () => {
        setShowTemplateOperationsModal(false);
    }

    useEffect(() => {
        if (sites && subSites && companies && tableDefinitionsComplete[TABLENAME]?.searchParams) {
            let availableSites = sites;
            let availableSubSites = subSites;
            //const at = tableDefinitionsComplete[TABLENAME].searchParams.find(sp => sp.propName === 'assetType');

            const ast = tableDefinitionsComplete[TABLENAME].searchParams.find(sp => sp.propName === 'assetSubType');
            let availableAssetSubTypes: Array<{ name: string, value: string }> = [];
            if (ast && ast.enumConstants) {
                availableAssetSubTypes = [...ast.enumConstants].sort().map(v => ({ name: v.replaceAll('_', ' '), value: v }));
            }

            let localFormWithOptions = mapEnumsToForm(assetEditForm, tableDefinitionsComplete[TABLENAME]?.searchParams)
                .map(field => {
                    if (field.propValue === 'supplyFrequency') {
                        return { ...field, options: (field?.options ?? []).map(option => ({ name: option.name.startsWith('E') ? option.name.substring(1) : option.name, value: option.value.toString() })) };
                    } else {
                        return field;
                    }
                });
            const dfCompany = dependantFields.find(df => df.name === 'companyUuid');
            const dfSite = dependantFields.find(df => df.name === 'siteUuid');
            const dfAssetType = dependantFields.find(df => df.name === 'assetType');
            if (dfCompany) {
                availableSites = sites.filter(s => s.companyUuid === dfCompany.value);
            }
            if (dfSite) {
                availableSubSites = subSites.filter(s => s.siteUuid === dfSite.value);
            }
            if (dfAssetType) {
                availableAssetSubTypes = availableAssetSubTypes.filter(ast => ast.name.startsWith(`${dfAssetType.value} `));
            }

            localFormWithOptions = localFormWithOptions.map(field => {
                switch (field.propValue) {
                    case 'companyUuid':
                        return { ...field, options: ([...companies] ?? []).sort(companySort).map(company => ({ name: company?.name ?? '', value: company.uuid })) };
                    case 'siteUuid':
                        return { ...field, options: ([...availableSites] ?? []).sort(siteSort).map(site => ({ name: site?.name ?? '', value: site.uuid })) };
                    case 'subSiteUuid':
                        return { ...field, options: ([...availableSubSites] ?? []).sort(subSiteSort).map(subSite => ({ name: subSite?.name ?? '', value: subSite.uuid })) };
                    case 'assetSubType':
                        return { ...field, options: ([...availableAssetSubTypes] ?? []).map(ast => ({ name: ast.name, value: ast.value })) };
                    default:
                        return field;
                }
            })
            setFormWithOptions(localFormWithOptions);
        }
    }, [companies, sites, subSites, dependantFields, tableDefinitionsComplete])

    const onSubmit = (entity: TEntity) => {
        setBusy(true);
        if (!entity?.uuid) {
            createAsset(token, entity as IAsset).then((asset) => {
                dispatch(addToast(t('Asset created')));
                setBusy(false);
                setWipAsset(asset);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setAssets([...assets, asset as IAsset]));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            })
        } else {
            setBusy(true);
            updateAsset(token, entity as IAsset).then((asset) => {
                dispatch(addToast(t('Asset updated')));
                setBusy(false);
                setWipAsset(asset);
                dispatch(setTableDefinitionComplete([TABLENAME, null]))
                dispatch(setAssets(assets.map(a => a.uuid === asset.uuid ? asset as IAsset : a)));
            }).catch((err) => {
                dispatch(addError(err.message));
                setBusy(false);
                console.error(err);
            });
        }
    }

    const onDelete = () => {
        setBusy(true);
        deleteAsset(token, wipAsset?.uuid as string).then(() => {
            dispatch(setAssets(assets.filter(a => a.uuid !== wipAsset?.uuid)));
            dispatch(setTableDefinitionComplete([TABLENAME, null]))
            dispatch(addToast(t('Asset deleted')));
            clearFocus();
            setWipAsset(undefined);
            setBusy(false);
        }).catch((err) => {
            setBusy(false);
            dispatch(addError(err.message));
            console.error(err);
        });
    }
    const setForm = (form: Array<IFormField>) => {
        setFormWithOptions(form);
    }

    return (
        <div className='m-2 overflow-y-auto h-full pb-4'>
            {showTemplateOperationsModal && assetTemplate ? <TemplateOperationsModal asset={assetTemplate} close={closeTemplateOperationsModal} /> : null}
            {showAssetSelectorModal && assetUuid ? <MultiAssetModal assetUuid={assetUuid} onClose={() => setShowAssetSelectorModal(false)} /> : null}
            <div className="relative z-30 bg-white w-full h-full">
                <div className='absolute top-0 right-0'>
                    <button className=" text-white me-2 disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md" onClick={() => setShowAssetSelectorModal(true)}>{t('Select multiple assets')}</button>
                    {assetTemplate ? <button onClick={beginTemplateOperation} className="mr-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md">{t('Create assets')}<FontAwesomeIcon className='ps-1' icon={solid('clipboard')} /></button> : null}
                    <button className=" text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md" onClick={copyAssetToTemplate}>{assetTemplate ? t('Clear template') : t('Copy as template')}</button></div>
                {busy ? <Busy /> :
                    formWithOptions ?
                        <FormContext.Provider value={{ wipEntity, setWipEntity, confirmOverwrite, setConfirmOverwrite, confirmEndEdit, setConfirmEndEdit, confirmDelete, setConfirmDelete, confirmClear, setConfirmClear }}>
                            <FormTemplate entityName='Asset' form={formWithOptions} setForm={setForm} entity={wipAsset as TEntity} babysitFieldChange={onFieldChange} entityChanged={(changed) => setEntityChanged(changed)} onSubmit={onSubmit} onDelete={onDelete} onEndEdit={endEdit} />
                        </FormContext.Provider> : null}
            </div>
            {entityChanged ? <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div> : false}
        </div >)
}