import React, { useCallback, useMemo, useReducer, useEffect, useState, useRef, memo } from "react";
import { ReportGroupContext } from './ReportGroupContext';

import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../hooks';
import { deepEqual } from '../../utils';
import { getResult, getAssetResults, getEntityDocuments, getEntityImageList, getObservations } from "../../api";
import { Tabs, TTab } from '../Tabs';

//import { cacheFile, getFileFromCache } from '../../utils';

import {
    setReportTab,
    setRecommendations as setDispatchRecommendations,
    setObservations as setDispatchObservations
} from '../../store/mainSlice';

import {
    ILinkedEntity, ILinkedObservationEntity,
    IAsset, TResultRow, IObservation, IResultHistory, TReportEntityGroup, TResultType,
    TImageDescriptorList, TEntityType, TDocumentDescriptor, TTabId, TTabName, TTestType,
    TResultObservations
} from '../../store/Interfaces';

import {
    Asset, AssetDetails, CompanyDetails, Images, Trends,
    Recommendations, Report, RunningConditions, SiteDetails,
    Storage,
    SubSiteDetails, UserDetails, TImageListAsset
} from '../';
import { AssetEdit, CompanyEdit, SiteEdit, SubSiteEdit, ProjectEdit, UserEdit } from "../Admin";

export interface IReportGroupProps {
    focusEntity: TReportEntityGroup | undefined;
    clearFocus: () => void;
    resultEndpointName: TTestType;
    resultRow: TResultRow | undefined;
    expanded: boolean;
}

const TABS: TTab[] = [{ id: 'summary', name: 'Summary' }, { id: 'report', name: 'Report' }, { id: 'trends', name: 'Trends' }, { id: 'images', name: 'Images' }, { id: 'details', name: 'Asset' }, { id: 'test', name: 'Test' }].map(t => ({ id: t.id as TTabId, name: t.name as TTabName, loading: false, disabled: false }));
const USER_TABS: TTab[] = [{ id: 'details', name: 'User' }].map(t => ({ id: t.id as TTabId, name: t.name as TTabName, loading: false, disabled: false }));
const ASSET_TABS: TTab[] = [{ id: 'details', name: 'Asset' }, { id: 'summary', name: 'Summary' }, { id: 'report', name: 'Report' }, { id: 'images', name: 'Images' }, { id: 'storage', name: 'Storage' }/*, { id: 'edit', name: 'Edit' }*/].map(t => ({ id: t.id as TTabId, name: t.name as TTabName, loading: false, disabled: false }));
const COMPANY_TABS: TTab[] = [{ id: 'details', name: 'Company' }, { id: 'summary', name: 'Summary' }, { id: 'report', name: 'Report' }, { id: 'images', name: 'Images' }, { id: 'storage', name: 'Storage' },/* { id: 'edit', name: 'Edit' }*/].map(t => ({ id: t.id as TTabId, name: t.name as TTabName, loading: false, disabled: false }));
const SITE_TABS: TTab[] = [{ id: 'details', name: 'Site' }, { id: 'summary', name: 'Summary' }, { id: 'report', name: 'Report' }, { id: 'images', name: 'Images' }, { id: 'storage', name: 'Storage' },/* { id: 'edit', name: 'Edit' }*/].map(t => ({ id: t.id as TTabId, name: t.name as TTabName, loading: false, disabled: false }));
const SUBSITE_TABS: TTab[] = [{ id: 'details', name: 'Sub site' }, { id: 'summary', name: 'Summary' }, { id: 'report', name: 'Report' }, { id: 'images', name: 'Images' }, { id: 'storage', name: 'Storage' },/* { id: 'edit', name: 'Edit' }*/].map(t => ({ id: t.id as TTabId, name: t.name as TTabName, loading: false, disabled: false }));

export const feToEt: Record<TResultType, TEntityType> = {
    'results': 'Asset',
    'assets': 'Asset',
    'sites': 'Site',
    'sub-sites': 'SubSite',
    'companies': 'Company',
    'users': 'Asset',
    'one-report': 'Asset'
}

const entityMap: Record<TResultType, string> = { assets: 'Asset', results: 'Result', sites: 'Site', 'sub-sites': 'Subsite', companies: 'Company', users: '', 'one-report': '' }
interface IReportGroupState {
    imageList: TImageListAsset;
    imageBlobs: Record<string, string>;
    refreshImageList: number;
    resultHistory: { uuid: string, history: Array<IResultHistory> | Array<TDocumentDescriptor> };
    resultHistoryIndex: number;
    reportFocusEntity: TReportEntityGroup | undefined;
    loading: { observations: boolean, images: boolean, reports: boolean, tabImages: boolean, tabTrends: boolean, tabReport: boolean };
    ac: AbortController;
}

interface IReportGroupAction {
    type: string;
    payload: any;
}

const initialState: IReportGroupState = {
    imageList: { asset: '', images: [] },
    imageBlobs: {},
    refreshImageList: 0,
    resultHistory: { uuid: '', history: [] },
    resultHistoryIndex: 0,
    reportFocusEntity: undefined,
    loading: { observations: false, images: false, reports: false, tabImages: false, tabTrends: false, tabReport: false },
    ac: new AbortController()
}

export const ReportGroupRaw: React.FunctionComponent<IReportGroupProps> = ({ focusEntity, clearFocus, resultEndpointName, resultRow, expanded }) => {
    const dispatch = useDispatch();
    const reportGroupRef = useRef<HTMLDivElement>(null);
    const { token, assets, subSites, companies, sites, selectedAssets, reportTab, recommendations, observations, editMode } = useSelector(state => state.main);
    const stickyTab = useRef<TTabId>();

    const [tabLists, setTabLists] = useState<Record<TResultType, TTab[]>>({
        results: TABS, assets: ASSET_TABS, users: USER_TABS, companies: COMPANY_TABS, sites: SITE_TABS, 'sub-sites': SUBSITE_TABS, 'one-report': TABS
    });
    useEffect(() => {
        if (editMode) {
            setTabLists({
                ...tabLists,
                assets: ASSET_TABS.concat([{ id: 'edit', name: 'Edit', loading: false, disabled: false }]),
                companies: COMPANY_TABS.concat([{ id: 'edit', name: 'Edit', loading: false, disabled: false }]),
                sites: SITE_TABS.concat([{ id: 'edit', name: 'Edit', loading: false, disabled: false }]),
                'sub-sites': SUBSITE_TABS.concat([{ id: 'edit', name: 'Edit', loading: false, disabled: false }]),
                'users': USER_TABS.concat([{ id: 'edit', name: 'Edit', loading: false, disabled: false }])
            })
        }
        else {
            setTabLists({
                results: TABS, assets: ASSET_TABS, users: USER_TABS, companies: COMPANY_TABS, sites: SITE_TABS, 'sub-sites': SUBSITE_TABS, 'one-report': TABS
            });
        }
    }, [editMode])

    const reducer = (state: IReportGroupState, action: IReportGroupAction): IReportGroupState => {
        switch (action.type) {
            case 'bumpRefreshImageList':
                return { ...state, refreshImageList: state.refreshImageList + 1 }
            case 'setImageList':
                return { ...state, imageList: action.payload };
            case 'addImageBlob':
                if (state.imageBlobs[action.payload.name] === action.payload.blob) {
                    return state;
                }
                return { ...state, imageBlobs: { ...state.imageBlobs, [action.payload.name]: action.payload.blob } };
            case 'setImageBlobs':
                return { ...state, imageBlobs: action.payload };
            case 'setResultHistory':
                if (state.resultHistory.uuid === action.payload.uuid && deepEqual(state.resultHistory.history, action.payload.history)) {
                    return state;
                }
                return { ...state, resultHistory: action.payload };
            case 'setResultHistoryIndex':
                return { ...state, resultHistoryIndex: action.payload };
            case 'setReportFocusEntity':
                return { ...state, reportFocusEntity: action.payload };
            case 'setLoadingObservations':
                return { ...state, loading: { ...state.loading, observations: action.payload } };
            case 'setLoadingImages':
                return { ...state, loading: { ...state.loading, images: action.payload } };
            case 'setLoadingReports':
                return { ...state, loading: { ...state.loading, reports: action.payload } };
            case 'setLoadingTabImages':
                return { ...state, loading: { ...state.loading, tabImages: action.payload } };
            case 'setLoadingTabTrends':
                return { ...state, loading: { ...state.loading, tabTrends: action.payload } };
            case 'setLoadingTabReport':
                return { ...state, loading: { ...state.loading, tabReport: action.payload } };
            case 'setAbortController':
                return { ...state, ac: action.payload };
            default:
                console.error('Unknown action type', action.type);
                return state;
        }
    }

    const [{ refreshImageList, imageList, resultHistory, resultHistoryIndex, reportFocusEntity,
        loading: { observations: loadingObservations, images: loadingImages, reports: loadingReports, tabImages: loadingTabImages, tabTrends: loadingTabTrends, tabReport: loadingTabReport },
        imageBlobs, ac }, localDispatch] = useReducer(reducer, initialState);

    const bumpRefreshImageList = () => localDispatch({ type: 'bumpRefreshImageList', payload: null });
    const setRecommendations = (payload: Array<string>) => dispatch(setDispatchRecommendations(payload));
    const setObservations = (payload: TResultObservations) => dispatch(setDispatchObservations(payload));
    const setImageList = (payload: TImageListAsset) => localDispatch({ type: 'setImageList', payload });
    const addImageBlob = (payload: { name: string, blob: string }) => localDispatch({ type: 'addImageBlob', payload });
    const setImageBlobs = (payload: Record<string, string>) => localDispatch({ type: 'setImageBlobs', payload });
    const setResultHistory = (payload: { uuid: string, history: Array<IResultHistory> | Array<TDocumentDescriptor> }) =>
        localDispatch({ type: 'setResultHistory', payload });
    const setResultHistoryIndex = useCallback((payload: number) => localDispatch({ type: 'setResultHistoryIndex', payload }), []);
    const setReportFocusEntity = (payload: TReportEntityGroup | undefined) => localDispatch({ type: 'setReportFocusEntity', payload });
    const setLoadingObservations = (payload: boolean) => localDispatch({ type: 'setLoadingObservations', payload });
    const setLoadingImages = (payload: boolean) => localDispatch({ type: 'setLoadingImages', payload });
    const setLoadingReports = (payload: boolean) => localDispatch({ type: 'setLoadingReports', payload });
    const setLoadingTabImages = (payload: boolean) => localDispatch({ type: 'setLoadingTabImages', payload });
    const setLoadingTabTrends = (payload: boolean) => localDispatch({ type: 'setLoadingTabTrends', payload });
    const setLoadingTabReport = useCallback((payload: boolean) => localDispatch({ type: 'setLoadingTabReport', payload }), []);
    const setAbortController = (payload: AbortController) => localDispatch({ type: 'setAbortController', payload });

    let asset: IAsset | undefined = undefined;
    if (selectedAssets.length > 0) {
        asset = assets.find(a => a.uuid === selectedAssets[0])
    }

    useEffect(() => {
        setObservations({ Asset: [], Result: [], Site: [], Subsite: [], Company: [] });
        setImageList({ asset: '', images: [] });
        setLoadingObservations(false);
        setLoadingImages(false);
        setLoadingReports(false);
        setResultHistory({ uuid: "", history: [] });
        setResultHistoryIndex(-1);
        const localAc = new AbortController();
        setAbortController(localAc);
        setLoadingTabImages(false);
        setLoadingTabReport(false);
        setLoadingTabTrends(false);
        setImageBlobs({});
        return () => {
            localAc.abort('Component unmounted');
        }
    }, []);

    const setCurrentTabSticky = (tab: TTabId) => {
        stickyTab.current = tab;
        dispatch(setReportTab(tab));
    }

    useEffect(() => {
        const getImages = async (ac: AbortController) => {
            setLoadingImages(true);
            try {
                //                const temp = getFileFromCache(`${focusEntity?.uuid ?? ''}_imageList`);
                let localImageList: TImageDescriptorList;
                /*                if (temp) {
                                    localImageList = JSON.parse(temp);
                                }
                                else { */
                localImageList = await getEntityImageList(token, feToEt[focusEntity?.type ?? 'assets'], focusEntity?.uuid ?? '', ac);
                if (localImageList.some(li => li.defaultItem)) {
                    localImageList.sort((a, b) => a.defaultItem ? -1 : b.defaultItem ? 1 : 0);
                }
                /*
                                    if (localImageList.length > 0) {
                                        cacheFile(`${focusEntity?.uuid}_imageList`, JSON.stringify(localImageList), 3600);
                                    } 
                                }*/
                setImageList({ asset: focusEntity?.uuid ?? '', images: localImageList });
                setLoadingImages(false);
            }
            catch (e) {
                setLoadingImages(false);
                if (e !== 'The user aborted a request.') {
                    console.error(e);
                } else {
                    console.log('Loading images : Aborted');
                }
            }
        }
        setImageList({ asset: '', images: [] });
        if (token && focusEntity && ac && !ac.signal.aborted && focusEntity.type !== 'users') {
            getImages(ac)
        }
    }, [token, ac, focusEntity, refreshImageList]);

    useEffect(() => {
        setRecommendations([]);
        setObservations({ Asset: [], Result: [], Site: [], Subsite: [], Company: [] });
    }, [resultRow]);

    useEffect(() => {
        if (token && focusEntity && focusEntity.type === 'results' && resultRow && resultRow.resultUuid && ac && ac.signal.aborted === false) {
            getResult(token, resultRow.resultUuid, ac).then((res) => {
                if (res instanceof Object && res.recommendationUUIDs !== undefined) {
                    setRecommendations(res.recommendationUUIDs);
                }
            }).catch((err) => {
                if (err !== 'The user aborted a request.') {
                    console.log(err);
                } else {
                    console.log('Getting recommendations: Aborted');
                }
            });
        }
    }, [token, focusEntity, resultRow]);

    useEffect(() => {
        const getLocalObservation = async (uuid: string, type: TResultType, ac: AbortController): Promise<IObservation[] | string> => {
            return getObservations(token, type, uuid, ac).then((res) => {
                return res;
            }).catch((err) => {
                if (err !== 'The user aborted a request.') {
                    console.error(err);
                } else {
                    console.log('Getting observations: Aborted');
                }
                return 'Error';
            });
        }
        if (token && ac && !ac.signal.aborted && focusEntity && ['results', 'companies', 'assets', 'sites', 'sub-sites'].includes(focusEntity.type)) {
            setLoadingObservations(true);
            const uuid = focusEntity.uuid;
            if (focusEntity.type === 'results') {
                // setObservations({ Asset: [], Result: [], Site: [], Subsite: [], Company: [] });
                const promises = {
                    Asset: getLocalObservation(uuid, 'assets', ac),
                    Result: getLocalObservation(resultRow?.resultUuid ?? '', 'results', ac),
                    Site: getLocalObservation(uuid, 'sites', ac),
                    Subsite: getLocalObservation(uuid, 'sub-sites', ac),
                    Company: getLocalObservation(uuid, 'companies', ac)
                };
                Promise.all(Object.values(promises)).then((res) => {
                    if (res instanceof Array) {
                        setObservations({
                            Asset: Array.isArray(res[0]) ? res[0] : [] as Array<IObservation>,
                            Result: Array.isArray(res[1]) ? res[1] : [] as Array<IObservation>,
                            Site: Array.isArray(res[2]) ? res[2] : [] as Array<IObservation>,
                            Subsite: Array.isArray(res[3]) ? res[3] : [] as Array<IObservation>,
                            Company: Array.isArray(res[4]) ? res[4] : [] as Array<IObservation>
                        });
                    }
                    setLoadingObservations(false);
                }).catch((e: Error) => {
                    setLoadingObservations(false);
                });
            }
            else {
                const localResultObservations = {
                    Asset: [], Result: [], Site: [], Subsite: [], Company: []
                }
                setObservations(localResultObservations);
                const res = getLocalObservation(uuid, focusEntity?.type ?? 'assets', ac);
                res.then((r) => {
                    setObservations({ ...localResultObservations, [entityMap[focusEntity.type]]: r });
                    setLoadingObservations(false);
                }).catch((e: Error) => {
                    console.error(e);
                    setLoadingObservations(false);
                });
            }
        }
    }, [token, focusEntity, resultRow, ac]);


    useEffect(() => {
        const localGetAssetResults = async () => {
            if (token && focusEntity && ac && !ac.signal.aborted) {
                setLoadingReports(true);
                if (focusEntity.type === 'results') {
                    setResultHistoryIndex(-1);
                    getAssetResults(token, focusEntity.uuid, resultEndpointName, feToEt[focusEntity.type], ac).then(results => {
                        // We've had a difficult to reproduce situation, which is basically the results from the API aren't in endTime order, and they're then sorted in a few places.
                        // And we ended up seeing the approve buttons being wrong because the resultHistoryIndex was from a sorted list and was then accessing an un-sorted list (or vice-versa)
                        // So just sorting them here to be sure...
                        results = results.sort((a, b) => b.endTime - a.endTime);
                        setReportFocusEntity(focusEntity);
                        setResultHistory({ uuid: focusEntity.uuid, history: results });
                        setLoadingReports(false);
                    }).catch(e => {
                        if (e !== 'The user aborted a request.') {
                            console.error(e);
                        } else {
                            console.log('Getting asset results: Aborted');
                        }
                        //setReportError('Results unavailable for this asset');
                        setLoadingReports(false);
                    });
                } else if (['assets', 'sites', 'sub-sites', 'companies'].includes(focusEntity.type)) {
                    setResultHistoryIndex(-1);
                    getEntityDocuments(token, focusEntity.uuid, feToEt[focusEntity.type]).then(results => {
                        setReportFocusEntity(focusEntity);
                        setResultHistory({ uuid: focusEntity.uuid, history: results });
                        setLoadingReports(false);
                    }).catch(e => {
                        console.error(e);
                        //setReportError('Results unavailable for this asset');
                        setLoadingReports(false);
                    })
                }
            }
        }
        console.log('Getting asset results');
        localGetAssetResults();
    }, [token, focusEntity, resultRow, resultEndpointName, ac]);

    const tabHasContent = useCallback((tab: TTab) => {
        if (tab.id === 'trends') {
            return (resultRow?.tests ?? 0) > 0;
        } else if (tab.id === 'summary') {
            return ((recommendations ?? []).length > 0) || (Object.values(observations).some(o => o.length > 0));
        } else if (tab.id === 'images') {
            return (imageList.images ?? []).length > 0;
        } else if (tab.id === 'report') {
            return (resultHistory.uuid === focusEntity?.uuid && resultHistory.history.length >= 0);
        }
        return true;
    }, [imageList.images, recommendations, focusEntity, resultHistory, resultRow, observations]);

    // really this should use a list of promises
    useEffect(() => {
        if (!loadingImages && !loadingObservations && !loadingReports && focusEntity) {
            // when everything is loaded decide on the current tab
            const tabs = tabLists[focusEntity.type]; //.filter(tabIsVisible);
            const visibleTabs = tabs; //.filter(tabIsVisible);
            if (tabs.length === 1) {
                dispatch(setReportTab(tabs[0].id));
            }
            else if (stickyTab.current && visibleTabs.some(tab => tab.id === stickyTab.current)) {
                dispatch(setReportTab(stickyTab.current));
            }
            else if (visibleTabs.some(tab => tab.id === 'summary')) {
                dispatch(setReportTab('summary'));
            }
        }
    }, [loadingImages, loadingObservations, loadingReports, focusEntity, dispatch])


    const tabLoading = (tab: TTab): TTab => {
        if (tab.id === 'trends') {
            return ({ ...tab, loading: loadingTabTrends, disabled: loadingTabTrends });
        } else if (tab.id === 'images') {
            return ({ ...tab, loading: loadingTabImages || loadingImages, disabled: loadingTabTrends || loadingImages });
        } else if (tab.id === 'report') {
            return ({ ...tab, loading: loadingTabReport, disabled: loadingTabReport });
        }
        return tab;
    }
    // const reportContainer = reportGroupRef.current; //document.getElementById('reportGroup');

    const tabList = (type: TResultType | undefined): Array<TTab> => {
        switch (focusEntity?.type) {
            case 'sub-sites':
                return tabLists['sub-sites'].map(tabLoading).map(t => ({ ...t, disabled: !tabHasContent(t) }));
            case 'sites':
                return tabLists.sites.map(tabLoading).map(t => ({ ...t, disabled: !tabHasContent(t) }));
            case 'assets':
                return tabLists.assets.map(tabLoading).map(t => ({ ...t, disabled: !tabHasContent(t) }));
            case 'companies':
                return tabLists.companies.map(tabLoading).map(t => ({ ...t, disabled: !tabHasContent(t) }));
            case 'users':
                return tabLists.users.map(tabLoading).map(t => ({ ...t, disabled: !tabHasContent(t) }));
            case 'results':
                return tabLists.results.map(tabLoading).map(t => ({ ...t, disabled: !tabHasContent(t) }));
            default:
                return [];
        }
    }

    const linkedEntities = useMemo((): ILinkedEntity[] => {
        if (resultRow && focusEntity?.type === 'results') {
            const subSiteEntities: ILinkedEntity[] = subSites.filter(ss => ss.siteUuid === resultRow.siteUuid).map(ss => ({ type: 'SubSite', name: ss.name, uuid: ss.uuid }));
            return [
                { type: 'Asset', uuid: resultRow.assetUuid, name: assets.find(a => a.uuid === resultRow.assetUuid)?.name ?? '' },
                { type: 'Company', uuid: resultRow.companyUuid, name: companies.find(c => c.uuid === resultRow.companyUuid)?.name ?? '' },
                { type: 'Site', uuid: resultRow.siteUuid, name: sites.find(s => s.uuid === resultRow.siteUuid)?.name ?? '' },
                ...subSiteEntities
            ]
        }
        return [];
    }, [resultRow, focusEntity, assets, companies, sites, subSites]);

    const linkedObservationEntities = useMemo((): ILinkedObservationEntity[] => {
        if (resultRow && focusEntity?.type === 'results') {
            const subSiteEntities: ILinkedObservationEntity[] = subSites.filter(ss => ss.siteUuid === resultRow.siteUuid).map(ss => ({ type: 'sub-sites', name: ss.name, uuid: ss.uuid }));
            return [
                { type: 'assets', uuid: resultRow.assetUuid, name: assets.find(a => a.uuid === resultRow.assetUuid)?.name ?? '' },
                { type: 'companies', uuid: resultRow.companyUuid, name: companies.find(c => c.uuid === resultRow.companyUuid)?.name ?? '' },
                { type: 'sites', uuid: resultRow.siteUuid, name: sites.find(s => s.uuid === resultRow.siteUuid)?.name ?? '' },
                ...subSiteEntities
            ]
        }
        return [];
    }, [resultRow, focusEntity, assets, companies, sites, subSites]);

    const setReportApproval = (approved: boolean) => setResultHistory({
        uuid: resultHistory.uuid, history: resultHistory.history.map((h, idx) => {
            if (idx === resultHistoryIndex) {
                return ({ ...h, reportApproved: approved }) as IResultHistory;
            } else {
                return h as IResultHistory;
            }
        })
    })
    const setResultApproval = (approved: boolean) => setResultHistory({
        uuid: resultHistory.uuid, history: resultHistory.history.map((h, idx) => {
            if (idx === resultHistoryIndex) {
                return ({ ...h, approved }) as IResultHistory;
            } else {
                return h as IResultHistory;
            }
        })
    })


    return (<ReportGroupContext.Provider value={{ imageListAsset: imageList, loadingImages, imageBlobs, refreshImageList: bumpRefreshImageList }}>
        <div className="grid grid-rows-[48px_1fr] h-full"><Tabs tabList={tabList(focusEntity?.type)} currentTab={reportTab} setCurrentTab={
            setCurrentTabSticky
        } />
            <div ref={reportGroupRef} className="mx-1 border-stone-100 bg-stone-50 outline-2 text-neutral-800 h-[calc(100vh_-_58px)] w-full overflow-hidden">
                {focusEntity?.type === 'sub-sites' ?
                    <>{reportTab === 'details' ? <SubSiteDetails uuid={focusEntity.uuid} detailsOnly={true} /> : null}
                        <Report container={reportGroupRef.current} visible={reportTab === 'report'} uuid={focusEntity.uuid} entityType='SubSite' resultEndpointName={resultEndpointName} resultHistory={resultHistory} resultHistoryIndex={resultHistoryIndex} setResultHistoryIndex={setResultHistoryIndex} loadingHistory={loadingReports} loading={loadingTabReport} setLoading={setLoadingTabReport} setReportApproval={setReportApproval} setResultApproval={setResultApproval} />
                        <Images visible={reportTab === 'images'} entityType='SubSite' loading={loadingTabImages} setLoading={setLoadingTabImages} addImageBlob={addImageBlob} refreshImageList={bumpRefreshImageList} />
                        {reportTab === 'summary' ? <Recommendations focusEntity={focusEntity} recommendationUUids={recommendations} observations={observations} loadingObservations={loadingObservations} setObservations={setObservations} /> : null}
                        {reportTab === 'storage' ? <Storage uuid={focusEntity.uuid} entityType='SubSite' /> : null}
                        {reportTab === 'edit' ? <SubSiteEdit subSiteUuid={focusEntity.uuid} endEdit={() => dispatch(setReportTab('details'))} clearFocus={clearFocus} /> : null}
                    </> : null
                }
                {
                    focusEntity?.type === 'sites' ? <>
                        {reportTab === 'details' ? <SiteDetails uuid={focusEntity.uuid} detailsOnly={true} /> : null}
                        <Report container={reportGroupRef.current} visible={reportTab === 'report'} uuid={focusEntity.uuid} entityType='Site' resultEndpointName={resultEndpointName} resultHistory={resultHistory} resultHistoryIndex={resultHistoryIndex} setResultHistoryIndex={setResultHistoryIndex} loadingHistory={loadingReports} loading={loadingTabReport} setLoading={setLoadingTabReport} setReportApproval={setReportApproval} setResultApproval={setResultApproval} />
                        <Images visible={reportTab === 'images'} entityType='Site' loading={loadingTabImages} setLoading={setLoadingTabImages} addImageBlob={addImageBlob} refreshImageList={bumpRefreshImageList} />
                        {reportTab === 'summary' ? <Recommendations focusEntity={focusEntity} recommendationUUids={recommendations} observations={observations} loadingObservations={loadingObservations} setObservations={setObservations} /> : null}
                        {reportTab === 'storage' ? <Storage uuid={focusEntity.uuid} entityType='Site' /> : null}
                        {reportTab === 'edit' ? <SiteEdit siteUuid={focusEntity.uuid} endEdit={() => dispatch(setReportTab('details'))} clearFocus={clearFocus} /> : null}
                    </> : null
                }
                {
                    focusEntity?.type === 'users' ? <>
                        {reportTab === 'details' ? <UserDetails uuid={focusEntity.uuid} /> : null}
                        {reportTab === 'edit' ? <UserEdit userUuid={focusEntity.uuid} endEdit={() => dispatch(setReportTab('details'))} clearFocus={clearFocus} /> : null}
                    </> : null
                }
                {
                    focusEntity?.type === 'companies' ? <>
                        {reportTab === 'details' ? <CompanyDetails uuid={focusEntity.uuid} detailsOnly={true} /> : null}
                        <Report container={reportGroupRef.current} visible={reportTab === 'report'} uuid={focusEntity.uuid} entityType='Company' resultEndpointName={resultEndpointName} resultHistory={resultHistory} resultHistoryIndex={resultHistoryIndex} setResultHistoryIndex={setResultHistoryIndex} loadingHistory={loadingReports} loading={loadingTabReport} setLoading={setLoadingTabReport} setReportApproval={setReportApproval} setResultApproval={setResultApproval} />
                        <Images visible={reportTab === 'images'} entityType='Company' loading={loadingTabImages} setLoading={setLoadingTabImages} addImageBlob={addImageBlob} refreshImageList={bumpRefreshImageList} />
                        {reportTab === 'summary' ? <Recommendations focusEntity={focusEntity} recommendationUUids={recommendations} observations={observations} loadingObservations={loadingObservations} setObservations={setObservations} /> : null}
                        {reportTab === 'storage' ? <Storage uuid={focusEntity.uuid} entityType='Company' /> : null}
                        {reportTab === 'edit' ? <CompanyEdit companyUuid={focusEntity.uuid} endEdit={() => dispatch(setReportTab('details'))} clearFocus={clearFocus} /> : null}
                    </> : null
                }
                {
                    focusEntity?.type === 'assets' ? <>
                        {reportTab === 'details' ? <AssetDetails uuid={focusEntity.uuid} detailsOnly={true} /> : null}
                        <Report container={reportGroupRef.current} visible={reportTab === 'report'} uuid={focusEntity.uuid} entityType='Asset' resultEndpointName={resultEndpointName} resultHistory={resultHistory} resultHistoryIndex={resultHistoryIndex} setResultHistoryIndex={setResultHistoryIndex} loadingHistory={loadingReports} loading={loadingTabReport} setLoading={setLoadingTabReport} setReportApproval={setReportApproval} setResultApproval={setResultApproval} />
                        <Images visible={reportTab === 'images'} entityType='Asset' loading={loadingTabImages} setLoading={setLoadingTabImages} addImageBlob={addImageBlob} refreshImageList={bumpRefreshImageList} />
                        {reportTab === 'summary' ? <Recommendations focusEntity={focusEntity} recommendationUUids={recommendations} observations={observations} loadingObservations={loadingObservations} setObservations={setObservations} /> : null}
                        {reportTab === 'storage' ? <Storage uuid={focusEntity.uuid} entityType='Asset' /> : null}
                        {reportTab === 'edit' ? <AssetEdit assetUuid={focusEntity.uuid} endEdit={() => dispatch(setReportTab('details'))} clearFocus={clearFocus} /> : null}
                    </> : null
                }
                {
                    focusEntity?.type === 'results' ? <>
                        {reportTab === 'summary' ? <Recommendations focusEntity={focusEntity} recommendationUUids={recommendations} observations={observations} loadingObservations={loadingObservations} setObservations={setObservations} linkedEntities={linkedObservationEntities} /> : null}
                        <Report container={reportGroupRef.current} visible={reportTab === 'report'} uuid={reportFocusEntity?.uuid ?? ''} entityType='Report' resultRow={resultRow} resultEndpointName={resultEndpointName} resultHistory={resultHistory} resultHistoryIndex={resultHistoryIndex} setResultHistoryIndex={setResultHistoryIndex} loadingHistory={loadingReports} loading={loadingTabReport} setLoading={setLoadingTabReport} linkedEntities={linkedEntities} setReportApproval={setReportApproval} setResultApproval={setResultApproval} />
                        <Trends visible={reportTab === 'trends'} asset={asset} resultEndpointName={resultEndpointName} resultHistory={resultHistory} setResultHistoryIndex={setResultHistoryIndex} loading={loadingTabTrends} setLoading={setLoadingTabTrends} expanded={expanded} />
                        <Images visible={reportTab === 'images'} entityType='Asset' loading={loadingTabImages} setLoading={setLoadingTabImages} addImageBlob={addImageBlob} linkedEntities={linkedEntities} refreshImageList={bumpRefreshImageList} />
                        {reportTab === 'details' ? asset !== undefined ? <Asset asset={asset} /> : null : null}
                        {
                            reportTab === 'test' ? <RunningConditions resultRow={resultRow} resultHistory={resultHistory as {
                                uuid: string;
                                history: Array<IResultHistory>
                            }} resultHistoryIndex={resultHistoryIndex} setResultHistoryIndex={setResultHistoryIndex} abortController={ac} /> : null
                        }
                    </> : null
                }
            </div>
        </div>
    </ReportGroupContext.Provider >);
}

export const ReportGroup = memo(ReportGroupRaw);