
import {
    IBaseProject, IProject
} from '../store/Interfaces';

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'

const { dispatch } = store;


export const getProject = async (token: string, uuid: string, abortController: AbortController): Promise<IBaseProject> => {
    const url = `${getApiEndpoint()}/Project/${uuid}`;
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },

            signal: abortController.signal,
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the project [${resp.status}]`));
            }
        }).then(json => {
            const project: IBaseProject = json;
            resolve(sanitise(project));
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject('Error fetching...');
        });
    })
    return p;
}

export const getProjects = async (token: string, abortController: AbortController): Promise<IBaseProject[]> => {
    const url = `${getApiEndpoint()}/Project`;
    const p = new Promise<IBaseProject[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },

            signal: abortController.signal,
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the project list [${resp.status}]`));
            }
        }).then(json => {
            const project: IBaseProject[] = json;
            resolve(sanitise(project));
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject('Error fetching...');
        });
    })
    return p;
}

export const createProject = async (token: string, project: IProject): Promise<IBaseProject> => {
    const url = `${getApiEndpoint()}/Project`;
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(sanitise({ ...project, uuid: undefined })),
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating the project [${resp.status}]`));
            }
        }).then(json => {
            const projectReturned: IBaseProject = json;
            resolve(sanitise(projectReturned));
        }).catch(e => {

            dispatch(addError(e.message));
            reject('Error creating');
        });
    })
    return p;
}

export const updateProject = async (token: string, project: IProject): Promise<IBaseProject> => {
    const url = `${getApiEndpoint()}/Project`;
    const p = new Promise<any>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(sanitise(project))
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating the project [${resp.status}]`));
            }
        }).then(json => {
            const projectReturned: IBaseProject = json;
            resolve(sanitise(projectReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error updating');
        });
    })
    return p;
}


export const deleteProject = async (token: string, uuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/Project/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Error deleting project [${resp.status}]`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error deleting.');
        });
    })
    return p;
}

export const searchProjects = async (token: string, uuids: string[], abortController: AbortController): Promise<IBaseProject[] | string> => {
    const url = `${getApiEndpoint()}/Project/search`;
    const p = new Promise<IBaseProject[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
            method: 'POST',
            body: JSON.stringify({
                propName: "uuidStr",
                matches: uuids
            })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else if (resp.status === 404) {
                reject('Not found');
            } else {
                throw (new Error(`Error getting the projects [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as IBaseProject[]);
        }).catch(e => {
            if (e.name !== 'AbortError') {
                dispatch(addError(e.message));
            }
            reject(e.name);
        });
    });
    return p;
}
