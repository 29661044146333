import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


export interface IColumnControlButtonProps {
    isExpanded: boolean;
    onClick: (e: React.MouseEvent) => void;
}


export const ColumnControlButton: React.FunctionComponent<IColumnControlButtonProps> = ({ isExpanded, onClick }) => {
    const { t } = useTranslation();
    const title = isExpanded ? t('Contract column') : t('Expand column');
    return <button title={title} className='p-1 opacity-[.15] hover:opacity-75 py-0 text-md rounded-md text-black border-hvpd-grey-400' onClick={onClick}>{isExpanded ? <FontAwesomeIcon icon={solid('compress-arrows-alt')} /> : <FontAwesomeIcon icon={solid('chevron-right')} />}</button>;
};
