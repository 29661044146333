import React from 'react';

import { createRoot } from 'react-dom/client';
//import 'tw-elements';
import './index.css';
import { store } from './store/store';
import { Provider } from 'react-redux'

import { pdfjs } from 'react-pdf'
import "pdfjs-dist/build/pdf.worker";

import App from './App';
import { getAuth0Config } from './api';

import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { CookiesProvider } from 'react-cookie';
import { Busy } from './Components';

import { Popover, Tooltip, Collapse, initTE } from "tw-elements";

pdfjs.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.mjs";

initTE({ Collapse, Popover, Tooltip });

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<div className='flex content-center justify-center mt-16'>Fetching authorisation details <span className='ms-1'><Busy size='xs' /></span></div>);
  getAuth0Config().then(config => {
    if (window.location.hash && window.location.hash.startsWith("#")) {
      // Keycloak callback uses #, auth0 lib expects '?'
      window.location.href = window.location.href.replace(`${window.location.origin}/#`, `${window.location.origin}/?`);
    }

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <CookiesProvider>
            <Auth0Provider
              domain={config.AUTH0_DOMAIN}
              clientId={config.AUTH0_CLIENT_ID}
              redirectUri={window.location.origin}
            >
              <App />
            </Auth0Provider>
          </CookiesProvider>
        </Provider>
      </React.StrictMode >
    )
  }).catch(e => {
    root.render(<div className='flex content-center justify-center mt-16'>
      <div className='font-bold text-xl text-hvpd-red-600'>Error fetching Auth0 configuration</div>
    </div>);
  });
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
