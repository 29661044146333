import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { useCookies } from 'react-cookie';
import { Map as GoogleMap, Pin, AdvancedMarker, MapCameraChangedEvent, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { faIndustryWindows, faArrowsToDot, faBuilding, faBuildings, faSquareQuestion, faUser, IconDefinition } from '@fortawesome/pro-duotone-svg-icons';

import { TLatLng, TBoundsX } from '../../Grid/Grid';
import { useAppSelector as useSelector, useAppDispatch as useDispatch, useTimeout } from '../../hooks';
import { setGeoPoints, addToast } from '../../store/mainSlice';
import { IGeoPoint, TTableOptions } from '../../store/Interfaces';
import { getGeoPoints } from '../../api';

import { getURLParamCookie, } from '../../utils';

const SHOW_INFO_WINDOW_TIMEOUT = 200;
const HIDE_INFO_WINDOW_TIMEOUT = 5000;
const MAP_ID = '78cfe743259de315';
export interface IMapProps {
    onClickMarker: (pin: IGeoPoint) => void;
    highlightLatLng?: TLatLng;
    getPinInfo: (pin: IGeoPoint) => { html: string, text: string };
    bounds: TBoundsX;
    onBoundsChanged: (bounds: TBoundsX) => void;
    toggleMap: () => void;
    resultFilterText: string;
    selectedResultTable: TTableOptions;
}

type TNamedMarker = { marker: google.maps.marker.AdvancedMarkerElement, geoPoint: IGeoPoint };

export const RawMap: React.FunctionComponent<IMapProps> = ({ onClickMarker, highlightLatLng, getPinInfo, bounds, onBoundsChanged, toggleMap, resultFilterText, selectedResultTable }) => {
    const { t } = useTranslation();
    const markerLib = useMapsLibrary('marker');
    const mapLib = useMapsLibrary('maps');
    const [cookies, setCookie] = useCookies(['urlParams', 'mapPos']);
    const dispatch = useDispatch();

    const { geoPoints, currentUser, companies, token } = useSelector(store => store.main);
    const [showWindowTimeOut, setShowWindowTimeOut] = useState<number | null>(null);
    const [hideWindowTimeOut, setHideWindowTimeOut] = useState<number | null>(null);
    /*
        const [infoWindow, setInfoWindow] = useState<{
            content?: JSX.Element, position?: TLatLng, anchor?: google.maps.Marker | google.maps.marker.AdvancedMarkerElement, shouldFocus?: boolean, visible: boolean
        }>({ visible: false }); */
    const [userIsDragging, setUserIsDragging] = useState(false);

    const [initialPositionSet, setInititialPositionSet] = useState(false);

    const geoPointTextcache = useRef<{ [key: string]: string }>({});
    // const [clusterer, setClusterer] = useState<MarkerClusterer | null>(null);
    const [markers, setMarkers] = useState<TNamedMarker[]>([]);
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
    const [infoWindowAnchor, setInfoWindowAnchor] = useState<google.maps.Marker | google.maps.marker.AdvancedMarkerElement | null>(null);
    const map = useMap(MAP_ID);

    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({ map });
    }, [map]);

    useEffect(() => {
        const localGetGeoPoints = async () => {
            try {
                const localGeoPoints = await getGeoPoints(token);
                if ((localGeoPoints.length !== geoPoints.length) || localGeoPoints.some((lg, idx) => geoPoints[idx].uuid !== lg.uuid)) {
                    const saneGeoPoints = localGeoPoints.filter(gp => ((gp.lat !== 0 && gp.lng !== 0 && gp.lat >= -90 && gp.lat <= 90 && gp.lng >= -180 && gp.lng <= 180)));

                    dispatch(setGeoPoints(saneGeoPoints));
                }
            }
            catch (e) {

            }
        }
        if ((geoPoints ?? []).length === 0 && token) {
            localGetGeoPoints();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoPoints, token]);



    useEffect(() => {
        if (mapLib && !infoWindow) {
            const { InfoWindow } = mapLib;
            const iw = new InfoWindow();
            setInfoWindow(iw);
        }
    }, [mapLib, infoWindow]);

    useEffect(() => {
        if (map && !initialPositionSet) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let zoom = urlParams.get('zoom');
            let centre = urlParams.get('centre');

            let cx = getURLParamCookie('centre', cookies, setCookie);
            if (cx) {
                centre = cx;
            }
            cx = getURLParamCookie('zoom', cookies, setCookie);
            if (cx) {
                zoom = cx;
            }

            if (centre != null || zoom != null) {
                const newBounds = { ...bounds };
                if (centre != null) {
                    const [lat, lng] = centre.split(',');
                    newBounds.centre = { lat: parseFloat(lat), lng: parseFloat(lng) };
                }
                if (zoom != null) {
                    newBounds.zoom = parseFloat(zoom);
                }

                onBoundsChanged(newBounds);
                setInititialPositionSet(true);
            }
            else {
                const c = cookies['mapPos'];
                if (c && c.bounds && c.bounds.centre && c.bounds.zoom) {
                    onBoundsChanged(c.bounds);
                    setInititialPositionSet(true);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, cookies, initialPositionSet, currentUser]);

    useEffect(() => {
        if (currentUser && companies.length > 0 && currentUser.companies && currentUser.companies.length > 0 && !initialPositionSet) {
            const matchedCompany = companies.find(company => currentUser.companies[0] === company.uuid);
            if (matchedCompany !== undefined) {
                onBoundsChanged({ ...bounds, centre: { lat: matchedCompany?.gpsLat ?? 0, lng: matchedCompany?.gpsLong ?? 0 } });
                setInititialPositionSet(true);
            }
        }
        else if (companies.length > 0 && !initialPositionSet) {
            setInititialPositionSet(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, companies, initialPositionSet]);

    const onHoverMarker = (e: google.maps.MapMouseEvent, pin: IGeoPoint, marker: google.maps.Marker | google.maps.marker.AdvancedMarkerElement) => {
        setShowWindowTimeOut(SHOW_INFO_WINDOW_TIMEOUT);
        setHideWindowTimeOut(null);
        if (infoWindow) {
            setInfoWindowAnchor(marker);
            infoWindow.open({ anchor: marker, map });
            infoWindow.setContent(getPinInfo(pin).html.toString());
            infoWindow.setPosition({ lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0 });
        }
        //  setInfoWindow({ content: getPinInfo(pin).html, position: { lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0 }, anchor: marker, shouldFocus: false, visible: false })
    }

    useTimeout(() => {
        if (infoWindow) {
            infoWindow.open({ map, anchor: infoWindowAnchor });
        }
        // setInfoWindow({ ...infoWindow, visible: true });
        setShowWindowTimeOut(null);
        setHideWindowTimeOut(HIDE_INFO_WINDOW_TIMEOUT);
    }, showWindowTimeOut);

    useTimeout(() => {
        if (infoWindow) {
            infoWindow.close();
        }
        //setInfoWindow({ ...infoWindow, visible: false });
        setHideWindowTimeOut(null);
    }, hideWindowTimeOut);

    const onBoundsChangedInternal = (event: MapCameraChangedEvent) => {
        if (initialPositionSet) {
            onBoundsChanged({ bounds: { ...event.detail.bounds }, centre: event.detail.center, zoom: event.detail.zoom });
        }
    };

    const onInfoWindowClose = () => {
        if (infoWindow) {
            infoWindow.close();
        }
        setHideWindowTimeOut(null);
        setShowWindowTimeOut(null);
    }

    const onCopyToClipboard = (message: string) => {
        dispatch(addToast(message));
    }

    const textFilter = React.useCallback((geoPoint: IGeoPoint) => {
        if (resultFilterText === '') {
            return true;
        }
        let text = geoPointTextcache.current[geoPoint.uuid];
        if (text === undefined || text === null || text === '') {
            text = getPinInfo(geoPoint).text;
            geoPointTextcache.current[geoPoint.uuid] = text.toLowerCase();
        }
        return text.indexOf(resultFilterText.toLowerCase()) !== -1;
    }, [resultFilterText, getPinInfo])


    const buildMarker = (pin: IGeoPoint) => {
        const div = document.createElement('div');
        const root = createRoot(div);
        root.render(<PinGlyph pin={pin} />)
        return div;
    }

    const tableViewFilter = (geoPoint: IGeoPoint) => {
        switch (selectedResultTable) {
            case 'sub-sites':
                return geoPoint.type === 'SubSite';
            case 'sites':
                return geoPoint.type === 'Site';
            case 'companies':
                return geoPoint.type === 'Company';
            case 'assets':
                return geoPoint.type === 'Asset';
            default:
                return true;
        }
    }

    useEffect(() => {
        if (markers.length > 0) {
            markers.forEach(({ marker }) => {
                marker.map = null;
                if (marker.content) {
                    (marker.content as HTMLElement).remove();
                }
            });
            setMarkers([]);
        }
    }, [selectedResultTable]);

    useEffect(() => {
        if (map && markerLib && markers.length === 0 && geoPoints.length > 0) {
            // Add some markers to the map.
            const markers = geoPoints.filter(textFilter).filter(tableViewFilter).map((pin) => {
                const position = { lat: pin.lat, lng: pin.lng };

                const pinGlyph = buildMarker(pin);

                const marker = new markerLib.AdvancedMarkerElement({
                    map,
                    position,
                    title: pin.name,
                    content: pinGlyph
                });
                marker.addListener('click', (e: google.maps.MouseEvent) => {
                    onHoverMarker(e, pin, marker);
                    onClickMarker(pin);

                });
                return { marker, geoPoint: pin };
            });
            if (markers.length > 0) {
                setMarkers(markers);
            }
        }
        return () => {
            markers.forEach(({ marker }) => {
                marker.map = null;
                if (marker.content) {
                    (marker.content as HTMLElement).remove();
                }
            });

        }
    }, [geoPoints, map, markerLib, markers, resultFilterText]);

    useEffect(() => {
        if (markers.length && map && clusterer) {
            if (clusterer) {
                clusterer.clearMarkers();
                clusterer.addMarkers(markers.map(m => m.marker));
            }
            return () => {
                clusterer.clearMarkers();
            }
        }

    }, [markers, clusterer, map]);

    useEffect(() => {
        let listener: google.maps.MapsEventListener | undefined = undefined;
        if (!listener) {
            listener = map?.addListener("rightclick", function (event: google.maps.MapMouseEvent) {
                if (event.latLng) {
                    const lat = event.latLng.lat();
                    const lng = event.latLng.lng();

                    if (navigator.clipboard) {
                        navigator.clipboard.writeText(lat + ',' + lng)
                    }
                    // populate yor box/field with lat, lng
                    onCopyToClipboard(t('Lat, Lng copied to clipboard'));
                }
            });
        }
        return () => {
            if (listener) {
                google.maps.event.removeListener(listener);
            }
        }
    }, [map]);

    return (<>
        <GoogleMap mapId={MAP_ID}
            id={MAP_ID}
            onDrag={() => setUserIsDragging(true)}
            onDragend={() => setUserIsDragging(false)}
            onCameraChanged={onBoundsChangedInternal}
            zoom={bounds.zoom}
            center={bounds.centre}
            streetViewControl={false}
            gestureHandling={'greedy'}
        >
            {highlightLatLng ? <AdvancedMarker position={highlightLatLng} zIndex={1000} ><Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} /></AdvancedMarker> : null}
            <SLDButtonHandler toggleMap={toggleMap} />
        </GoogleMap>
    </>);
}

export const Map = React.memo(RawMap);

interface ISLDButtonHandlerProps {
    toggleMap: () => void;
}

const SLDButtonHandler: React.FunctionComponent<ISLDButtonHandlerProps> = ({ toggleMap }) => {
    const map = useMap(MAP_ID);
    const { t } = useTranslation();
    useEffect(() => {
        if (map && map.controls[google.maps.ControlPosition.TOP_LEFT].getArray().length === 0) {
            const controlButton = document.createElement("button");
            controlButton.textContent = "SLD";
            controlButton.title = t("Click to change to SLD view");
            controlButton.type = "button";
            controlButton.setAttribute('class', 'sldButton');
            controlButton.addEventListener("click", () => toggleMap());
            const centerControlDiv = document.createElement("div");
            centerControlDiv.setAttribute('class', 'sldButtonWrapper');
            centerControlDiv.appendChild(controlButton);

            map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
        }

    }, [map, toggleMap]);
    return null;
}

const PinGlyph: React.FunctionComponent<{ pin: IGeoPoint }> = ({ pin }) => {
    const type2Icon: Record<string, IconDefinition> = { Company: faIndustryWindows, Site: faBuildings, Asset: faArrowsToDot, SubSite: faBuilding, User: faUser };
    const type2Colour: Record<string, string> = {
        Company: 'text-hvpd-red-600', Site: 'text-hvpd-jungle-green-900', Asset: 'text-hvpd-malibu-900', SubSite: 'text-hvpd-pickled-bluewood-500'
    };
    return (<div className=' text-hvpd-pickled-bluewood-800 flex text-lg p-1' >
        <span className="fa-stack fa-1x">
            <FontAwesomeIcon className={`${type2Colour[pin.type] ?? 'text-hvpd-grey-700'} fa-stack-2x`} icon={faLocationPin as IconDefinition} />
            <FontAwesomeIcon className='text-white fa-stack-1x fa-inverse' icon={type2Icon[pin.type] ?? faSquareQuestion} />
        </span>

    </div>);
}