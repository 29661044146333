import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportGroupContext } from '../ReportGroup/ReportGroupContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { ILinkedEntity, TEntityType, TImageDescriptorList, TImageDescriptor } from "../../store/Interfaces";
import { useAppSelector as useSelector, useAppDispatch as useDispatch, useTimeout } from '../../hooks';
import { Busy } from '../Busy';
import { getEntityImage } from '../../api';
import {
    addError,
    addToast,
} from "../../store/mainSlice";
import { ImageCount } from './ImageCount';
import { ImageInfo } from './ImageInfo';
import { ImageUpload } from './ImageUpload';

export type TImageListAsset = {
    asset: string, images: TImageDescriptorList
}

const LOAD_DELAY = 1000;
export interface IImagesProps {
    visible: boolean;
    entityType: TEntityType;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    linkedEntities?: ILinkedEntity[];
    refreshImageList: () => void;
    addImageBlob: (payload: { name: string, blob: string }) => void;
}

export const Images: React.FunctionComponent<IImagesProps> = (props) => {
    const { imageListAsset, loadingImages, imageBlobs } = useContext(ReportGroupContext);
    return <ImagesList {...props} imageListAsset={imageListAsset} loadingImages={loadingImages} imageBlobs={imageBlobs} />
}
export interface IImagesListProps {
    visible: boolean;
    imageListAsset: TImageListAsset;
    loadingImages: boolean;
    entityType: TEntityType;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    addImageBlob: (payload: { name: string, blob: string }) => void;
    imageBlobs: Record<string, string>;
    linkedEntities?: ILinkedEntity[];
    refreshImageList: () => void;
}

export const ImagesList: React.FunctionComponent<IImagesListProps> = ({ visible, entityType, loading, setLoading, linkedEntities, refreshImageList, imageListAsset, loadingImages, addImageBlob, imageBlobs }) => {
    const { t } = useTranslation();
    const { token } = useSelector(store => {
        return store.main;
    });
    const dispatch = useDispatch();

    const [ac, setAc] = useState<AbortController>();
    const [loadTimeout, setLoadTimeout] = useState<number | null>(null);

    const [linkedImageCount, setLinkedImageCount] = useState(0);
    const [showImageInfo, setShowImageInfo] = useState(false);
    const [imageDescriptor, setImageDescriptor] = useState<TImageDescriptor | null>(null);
    const [showImageUpload, setShowImageUpload] = useState(false);

    const activateImageInfo = (image: TImageDescriptor) => {
        setImageDescriptor(image);
        setShowImageInfo(true);
    }

    const { asset: assetUuid, images: imageList } = imageListAsset;
    /*
        useEffect(() => {
            console.log('ImagesListAsset: useEffect', imageListAsset);
        }, [imageListAsset]);
    */
    useEffect(() => {
        setLinkedImageCount(0);
        const localAc = new AbortController();
        setAc(localAc);
        return () => {
            try {
                localAc.abort();
            } catch (e) {
            }
        }
    }, []);

    useTimeout(() => {
        setLoadTimeout(null);
        setLoading(true);
        //let imageArray = Array(imageList.length).fill('');
        const imagePromises = imageList.map((image, index) => {
            if (imageBlobs[image.name] !== undefined) {
                return Promise.resolve();
            }
            const imageD = getEntityImage(token, entityType, assetUuid ?? '', `Image/${image.requestRelativeName}`, 30, ac as AbortController);
            imageD.then(imageBlob => {
                const url = URL.createObjectURL(imageBlob);
                //imageArray = imageArray.map((b, ix) => ix === index ? url : b);
                addImageBlob({ name: image.name, blob: url });
            }).catch(e => {
                console.error(e.message);
                //imageArray = imageArray.map((b, ix) => ix === index ? 'error' : b);
            })
            return imageD;
        });
        Promise.all(imagePromises).then(() => {
            setLoading(false);
        }).catch(e => {
            setLoading(false);
        })
    }, loadTimeout);

    useEffect(() => {
        setLoadTimeout(null);
    }, [assetUuid]);

    useEffect(() => {
        setLinkedImageCount(0);
    }, [imageListAsset]);

    useEffect(() => {
        if (token && ac && !loadingImages) {
            if (imageList.length > 0) {
                setLoadTimeout(LOAD_DELAY);
            } else {
                setLoadTimeout(null);
            }
        }
    }, [token, imageList, ac, loadingImages])

    const updateImageCount = (count: number) => {
        setLinkedImageCount(c => c + count);
    }

    const onError = (error: string) => {
        setShowImageUpload(false);
        dispatch(addError(error));
    }

    const onSuccessfulUpload = () => {
        setShowImageUpload(false);
        dispatch(addToast(t('Image upload successful')));
        refreshImageList();
    }

    const isBusy = loading || loadingImages;

    const getImages = (): Array<[string, string]> => {
        return imageList.reduce((acc: Array<[string, string]>, image) => {
            return [...acc, [image.name, imageBlobs[image.name] ?? '']] as Array<[string, string]>;
        }, []);
    }
    const images = getImages();

    return (visible ? (<div className="overflow-y-auto h-full">
        <div className='m-2 mb-12' >
            {isBusy ? <Busy /> :
                (images.length > 0) ? images.map(([name, image], idx) => (
                    <div className='m-2' key={name}>
                        {image === '' ? <div className='flex justify-center my-10'><FontAwesomeIcon className='fa-spin fa-2x text-hvpd-red-600' icon={solid('spinner')} /></div>
                            : image === 'error' ? <div className='flex justify-center my-10'><FontAwesomeIcon className='fa-2x text-hvpd-red-600' icon={solid('exclamation-triangle')} /></div>
                                : <img src={image} alt={imageList?.[idx]?.requestRelativeName ?? `pic_${idx} `} key={`image_${idx} `} />
                        }
                        {imageList?.[idx] ? <div><button className='hover:underline' onClick={() => activateImageInfo(imageList[idx])}><FontAwesomeIcon className='text-hvpd-pickled-bluewood-100 me-1' icon={faInfoCircle} />{t('Image information')}</button></div> : null}
                    </div>
                )) : null
            }
            {!isBusy && imageList !== undefined && imageList.length === 0 ? <div className='h-full bg-gray-100 mt-2 bg-opacity-75 px-8 py-4 rounded-lg break-all'>{t('No images available for this asset')}</div> : null}
            {
                !isBusy && (linkedEntities ?? []).length > 0 ?
                    <table className='table-auto w-full border-slate-400 border'>
                        <thead className='bg-colour-c01dc2f border-b'>
                            <tr>
                                <th colSpan={3} className='text-sm center font-medium text-white px-1 py-0 text-left'>
                                    <span className={`font-large font-bold mb-1 ${linkedImageCount > 0 ? 'text-white' : 'text-hvpd-grey-100'}`}>{linkedImageCount > 0 ? t('Related images') : t('No related images')}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(loading === false ? (linkedEntities ?? []).map(entity => (
                                <ImageCount key={entity.uuid} uuid={entity.uuid} token={token} assetType={entity.type} assetName={entity.name} setImageCount={updateImageCount} imageBlobs={imageBlobs} addImageBlob={addImageBlob} />
                            )) : null)}
                        </tbody>
                    </table> : null
            }
        </div>
        <div className='absolute bottom-0 left-0 w-full'>
            <button className='float-right mr-2 mb-2 text-white disabled:text-hvpd-grey-400 bg-hvpd-pickled-bluewood-500 border-hvpd-pickled-bluewood-200/40 hover:bg-hvpd-pickled-bluewood-600 px-2 py-1 border-solid border-1 text-sm font-medium rounded-md' onClick={() => setShowImageUpload(true)}>{t('Upload image')}</button>
        </div>
        <ImageUpload showModal={showImageUpload} onClose={() => setShowImageUpload(false)} assetUuid={assetUuid} entityType={entityType} onError={onError} onSuccessfulUpload={onSuccessfulUpload} />
        <ImageInfo show={showImageInfo} onClose={() => setShowImageInfo(false)} info={imageDescriptor} uuid={assetUuid} entityType={entityType} />
    </div >) : null);
}