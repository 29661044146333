import React from 'react';
import { useTranslation } from 'react-i18next';
import { IResultHistory } from '../../store/Interfaces';

interface IReportHistorySelectProps {
    resultHistory: Array<IResultHistory>,
    historySorter: (history: IResultHistory[]) => IResultHistory[],
    resultHistoryIndex: number,
    selectHistoryPage: React.ChangeEventHandler<HTMLSelectElement>
}

const TimeToString = (time: number): string => {
    const d = new Date(parseInt(time + '000'));
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
}

export const ReportHistorySelect: React.FC<IReportHistorySelectProps> = ({ resultHistory, historySorter, resultHistoryIndex, selectHistoryPage }) => {
    const { t } = useTranslation();
    const getEndDate = (report: IResultHistory) => {
        // defend against bad data
        if (undefined === report.endTime) {
            return '';
        }
        if (report.endTime) {
            return TimeToString((report as IResultHistory).endTime);
        } else {
            return t('No start time');
        }
    }
    const sortedResultHistory = historySorter(resultHistory);
    return <select value={resultHistoryIndex !== -1 ? sortedResultHistory[resultHistoryIndex]?.uuid : ''} className='border-solid text-sm rounded-sm p-1 focus:outline-1 border-slate-200 border-1 w-[60%] mr-1' onChange={selectHistoryPage}>{sortedResultHistory.map(a => <option key={a.uuid} value={a.uuid}>{getEndDate(a)}</option>)}</select>;
}
