import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'universal-cookie';

import { useAppSelector as useSelector, useAppDispatch as useDispatch, useOnClickOutside } from '../../hooks';
import { addToast, setBuildInfo, setDashboardMode, setEditMode } from "../../store/mainSlice";
import { getVersion } from '../../api';

import packageJson from '../../../package.json';
export interface IFooterProps {
    url: string;
}

export const Footer: React.FunctionComponent<IFooterProps> = ({ url }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { logout } = useAuth0();
    const menuRef = useRef<HTMLUListElement>(null);
    const [cookies, setCookies] = useState<Cookies>();
    const [menuOpen, setMenuOpen] = useState(false);
    const { buildInfo, token, dashboardMode, editMode } = useSelector(store => store.main);

    useEffect(() => {
        setCookies(new Cookies());
    }, []);

    useOnClickOutside(menuRef, () => {
        setMenuOpen(false);
    });
    const invertDashboardMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (dashboardMode) {
            dispatch(addToast(t('Dashboard mode off')));
        }
        else {
            dispatch(addToast(t('Dashboard mode on')));
        }
        dispatch(setDashboardMode(!dashboardMode));
        setMenuOpen(false);
    }
    const invertEditMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (editMode) {
            dispatch(addToast(t('Edit mode off')));
        }
        else {
            dispatch(addToast(t('Edit mode on')));
        }
        dispatch(setEditMode(!editMode));
        setMenuOpen(false);
    }

    const toggleMenu = (e: React.MouseEvent) => {
        if (e.shiftKey) {
            setMenuOpen(!menuOpen);
        }
    }

    const setApiMode = (api: 'dev' | 'beta' | 'live' | 'staging' | 'demo') => {
        setMenuOpen(false);
        if (cookies) {
            cookies.set('api', api, { path: '/' })
            dispatch(addToast(t(`Api mode ${api}`)));
            logout({ returnTo: window.location.origin });
        }
    }

    useEffect(() => {
        const localGetBuildInfo = async () => {
            try {
                const localBuildInfo = await getVersion(token);
                dispatch(setBuildInfo(localBuildInfo));
            }
            catch (e) {

            }
        };
        if (token && !buildInfo.apiVersion) {
            localGetBuildInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    let buildInfoString = ''
    if (buildInfo.buildDateTime) {
        buildInfoString = `V: ${packageJson.version ?? ''} -- ${new Date(buildInfo.buildDateTime.substr(0, 19)).toDateString()} ${buildInfo.atlasVersion}`
    }
    const api = cookies?.get('api') ?? 'beta';
    //const buildInfoStr = (buildInfo?.atlasVersion && buildInfo?.buildDateTime) ? `r${buildInfo.atlasVersion} (${buildInfo.date.replaceAll('.', ' ')})` : ``;

    return (<div className="footer background-black truncate"><span className=" text-white text-sm"><button onClick={toggleMenu}>©</button> {t(`y Monitra Ltd. All rights reserved`, { year: '2024' })}<span>{process.env.NODE_ENV === 'production' ? t('(production build)') : t('(dev build)')} {buildInfoString}</span></span>
        <CopyToClipboard text={url}
            onCopy={() => dispatch(addToast(t('Url copied to clibpoard')))}>
            <span className='text-xs ml-1 text-gray-400 hover:text-gray-300 hover:text-sm hover:absolute truncate' title={t('Click to copy to clipboard')}>{url}</span>
        </CopyToClipboard>
        {
            menuOpen ? <ul role="menu" ref={menuRef} data-popover="menu" data-popover-placement="bottom"
                className="fixed z-10 bottom-[20px] min-w-[180px] overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none">
                <li role="menuitem" >
                    <button className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white disabled:text-hvpd-grey-400' onClick={invertDashboardMode}>{dashboardMode ? t('Dashboard mode off') : t('Dashboard mode on')}</button>
                </li>
                <li role="menuitem" >
                    <button className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white disabled:text-hvpd-grey-400' onClick={invertEditMode}>{editMode ? t('Edit mode off') : t('Edit mode on')}</button>
                </li>
                <li className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-2 py-2 text-sm' role="menuitem">
                    <hr />
                    <span>The following options will force a logout</span>
                    <hr />
                </li>
                <li role="menuitem">
                    <button className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white disabled:text-hvpd-grey-400' disabled={api === 'dev'} onClick={() => setApiMode('dev')}>{t('Api mode dev')}</button>
                </li>
                <li role="menuitem">
                    <button className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white disabled:text-hvpd-grey-400' disabled={api === 'beta'} onClick={() => setApiMode('beta')}>{t('Api mode beta')}</button>
                </li>
                <li role="menuitem">
                    <button className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white disabled:text-hvpd-grey-400' disabled={api === 'live'} onClick={() => setApiMode('live')}>{t('Api mode live')}</button>
                </li>
                <li role="menuitem">
                    <button className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white disabled:text-hvpd-grey-400' disabled={api === 'staging'} onClick={() => setApiMode('staging')}>{t('Api mode staging')}</button>
                </li>
                <li role="menuitem">
                    <button className='text-gray-700 bg-hvpd-grey-50 w-full text-left block px-4 py-2 text-sm hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white disabled:text-hvpd-grey-400' disabled={api === 'demo'} onClick={() => setApiMode('demo')}>{t('Api mode demo')}</button>
                </li>
            </ul> : null
        }
    </div >)

}