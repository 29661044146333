

import { getApiEndpoint, sanitise, fetchWithAuthRedirect } from './api';

import { store } from '../store/store';
import { addError } from '../store/mainSlice'
import { ISalesOrder } from '../store/Interfaces';

const { dispatch } = store;

export const getSalesOrders = async (token: string): Promise<Array<ISalesOrder> | string> => {
    const url = `${getApiEndpoint()}/SalesOrder`;
    const p = new Promise<ISalesOrder[]>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            cache: "no-cache",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error getting the sales order list [${resp.status}]`));
            }
        }).then(json => {
            resolve(sanitise(json) as Array<ISalesOrder>);
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error fetching...');
        });
    })
    return p;
}


export const createSalesOrder = async (token: string, salesOrder: ISalesOrder): Promise<ISalesOrder> => {
    const url = `${getApiEndpoint()}/SalesOrder`;
    const p = new Promise<ISalesOrder>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...salesOrder, uuid: undefined })
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error creating sales order [${resp.status}]`));
            }
        }).then(json => {
            const salesOrderReturned: ISalesOrder = json as ISalesOrder;
            resolve(sanitise(salesOrderReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error creating');
        });
    })
    return p;
}

export const updateSalesOrder = async (token: string, salesOrder: ISalesOrder): Promise<ISalesOrder> => {
    const url = `${getApiEndpoint()}/SalesOrder`;
    const p = new Promise<ISalesOrder>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(salesOrder)
        }).then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw (new Error(`Error updating sales order [${resp.status}]`));
            }
        }).then(json => {
            const salesOrderReturned: ISalesOrder = json as ISalesOrder;
            resolve(sanitise(salesOrderReturned));
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error updating');
        });
    })
    return p;
}


export const deleteSalesOrder = async (token: string, uuid: string): Promise<boolean> => {
    const url = `${getApiEndpoint()}/SalesOrder/${uuid}`;
    const p = new Promise<boolean>((resolve, reject) => {
        fetchWithAuthRedirect(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(resp => {
            if (resp.ok) {
                resolve(true);
            } else {
                throw (new Error(`Error deleting sales order [${resp.status}]`));
            }
        }).catch(e => {
            dispatch(addError(e.message));
            reject('Error deleting.');
        });
    })
    return p;
}